import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserLogout } from "../../store/login/loginService";
import { variables } from "../../form/var";
import "./bar.css";
import { Types } from "../../store/types";
import { PopUp } from "../../popup/popup";

export default function Topbar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { admin } = useSelector((state) => state.admin);
  let location = useLocation();
  const [active, setActive] = useState("");
  const [menu, setMenu] = useState(false);
  const [toggleDetails, setToggleDetails] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(false);
  const [toggleServices, setToggleServices] = useState(false);
  const [windowSize, setWindowSize] = useState(true);
  const loginData = useSelector((state) => state.login);
  const isLoggedIn = localStorage.getItem('mat.token');
  const [hasPopUp, openPopUp] = useState();

  const ref = useRef(null);
  const { onClickOutside } = props;

  const refSearch = useRef(null);
  const { onClickOutsi } = props;

  const refServices = useRef(null);
  const { onClickOutsid } = props;

  const outClickCloseRef = useRef(null);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth >= 800);
  }

  const handleClickOut = (event) => {
    if (outClickCloseRef.current && !outClickCloseRef.current.contains(event.target)) {
      setMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOut, true);
    return () => {
      document.removeEventListener("click", handleClickOut, true);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutsi, true);
    return () => {
      document.removeEventListener("click", handleClickOutsi, true);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutsid, true);
    return () => {
      document.removeEventListener("click", handleClickOutsid, true);
    };
  }, []);

  useEffect(() => {
    if (
      location.pathname === "/register/step1" ||
      location.pathname === "/register/step2" ||
      location.pathname === "/register/step3"
    ) {
      setActive("register");
    } else {
      setActive(location.pathname);
    }
  }, [location]);


  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClickOutside && onClickOutside();
      setToggleDetails(false);
    }
  };

  const handleClickOutsid = (event) => {
    if (refServices.current && !refServices.current.contains(event.target)) {
      onClickOutsid && onClickOutsid();
      setToggleServices(false);
    }
  };

  const handleClickOutsi = (event) => {
    if (refSearch.current && !refSearch.current.contains(event.target)) {
      onClickOutsi && onClickOutsi();
      setToggleSearch(false);
    }
  };

  const logout = () => {
    dispatch(UserLogout());
    openPopUp(!hasPopUp)
    // navigate("/login");
  };

  const actionlogout = () => {
    openPopUp(!hasPopUp)
    // openPassword(true)
  }

  const actionLogin = () => {
    if (
      location.pathname === "/register/step2" ||
      location.pathname === "/register/step3"
    ) {
      dispatch({
        type: Types.ALERT_INFO,
        payload: { message: "Go Down And Skip", duration: 1000 },
      });
    } else {
      navigate("/login")
    }

  }

  const menuClick = () => {
    setMenu(!menu);
  };

  const detailsHandle = () => {
    setToggleServices(false);
    setToggleSearch(false);
    setToggleDetails(!toggleDetails);
  };

  const actionSearch = () => {
    if (windowSize) {
      setToggleDetails(false);
      setToggleServices(false);
      setToggleSearch(!toggleSearch);
    }
  };

  const serivicesHandle = () => {
    if (
      location.pathname === "/register/step2" ||
      location.pathname === "/register/step3"
    ) {
      dispatch({
        type: Types.ALERT_INFO,
        payload: { message: "Go Down And Skip", duration: 1000 },
      });
    } else if (windowSize) {
      setToggleDetails(false);
      setToggleSearch(false);
      setToggleServices(!toggleServices);
    }

  };

  const goToHome = () => {
    if (
      location.pathname === "/register/step2" ||
      location.pathname === "/register/step3"
    ) {
      dispatch({
        type: Types.ALERT_INFO,
        payload: { message: "Go Down And Skip", duration: 1000 },
      });
    } else {
      navigate("/home");
    }
  };

  const contactNavigate = () => {
    if (
      location.pathname === "/register/step2" ||
      location.pathname === "/register/step3"
    ) {
      dispatch({
        type: Types.ALERT_INFO,
        payload: { message: "Go Down And Skip", duration: 1000 },
      });
    } else {
      navigate("/contect");
    }
    setToggleDetails(false);
  };

  const ServicesNavigate = () => {
    if (
      location.pathname === "/register/step2" ||
      location.pathname === "/register/step3"
    ) {
      dispatch({
        type: Types.ALERT_INFO,
        payload: { message: "Go Down And Skip", duration: 1000 },
      });
    } else {
      navigate("/services");
    }
  };

  return (
    <div>
      {
        hasPopUp ? <PopUp title="LOG OUT" actionClose={actionlogout} hasPopUp={hasPopUp}>
          <div className="modal-body">
            <div style={{ 'color': 'black', 'font-size': '16px' }}> Are you sure, You want to logout? </div>
          </div>
          <div className="modal-footer">
            <button type="button" onClick={actionlogout} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary" onClick={logout}> Exit </button>
          </div>
        </PopUp> : <></>
      }
      <div className="navbarI ">
        <img
          onClick={goToHome}
          className="navi-pic"
          src="/img/kmslogo.png"
          alt="KMS"
        />
        <div className="heade">
          <span onClick={menuClick} ref={outClickCloseRef} className="menu-bar">
            {!menu ? (
              <i className="fa fa-bars bar-x" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-times bar-x" aria-hidden="true"></i>
            )}
          </span>
          <span
            onClick={() => setMenu(false)}
            className={`menu-prope ${menu ? "m-d-inline-prop" : "m-d-none"}`}
          >
            {isLoggedIn ? (
              <div
                style={{
                  borderBottom: active === "/dashboard" && "2px solid white",
                }}
                onClick={goToHome}
                className="navi-txt mbl-active-bdr"
              >
                {variables.MyHomepage}
              </div>
            ) : (
              <div
                style={{
                  borderBottom: active === "/home" && "2px solid white",
                }}
                onClick={goToHome}
                className="navi-txt mbl-active-bdr"
              >
                {variables.home}
              </div>
            )}
            {admin !== 'ADMIN' ? isLoggedIn && (
              <div ref={ref} className="navi-txt" onClick={detailsHandle}>
                <span className="mb-d-none">
                  {variables.details}
                  <span
                    className={
                      toggleDetails
                        ? "fa-solid fa-chevron-up"
                        : "fa-solid fa-chevron-down"
                    }
                  ></span>{" "}
                </span>
                <div
                  className={
                    toggleDetails ? "menu-drop details-m-drop" : "display-n"
                  }
                >
                  <div className="navi-ment-txt">{variables.profile}</div>
                  <div className="navi-ment-txt">{variables.PremiumAccounts}</div>
                  <div className="navi-ment-txt">{variables.options}</div>
                  {admin === 'ADMIN' ? <div className="navi-ment-txt" onClick={() => navigate("/deletingRequest")}>{variables.deletingRequest}</div> : <></>}

                </div>
              </div>
            ) : <div ref={ref} className="navi-txt" onClick={() => { navigate("/passwordReset") }}>
              {"Request"}
            </div>}
            <div ref={refServices} className="navi-txt" onClick={serivicesHandle}>
              <span className="mb-d-none">
                {variables.Services}
                <span
                  className={
                    toggleServices
                      ? "fa-solid fa-chevron-up"
                      : "fa-solid fa-chevron-down"
                  }
                ></span>{" "}
              </span>
              <div
                className={
                  toggleServices ? "menu-drop services-m-drop" : "display-n"
                }
              >
                <div className="navi-ment-txt">{variables.profile}</div>
                <div className="navi-ment-txt">{variables.PremiumAccounts}</div>
                <div className="navi-ment-txt" >{variables.options}</div>
                {admin === 'ADMIN' ? <div className="navi-ment-txt" onClick={() => navigate("/deletingRequest")}>{variables.deletingRequest}</div> : <></>}
              </div >
            </div >

            {/* {admin !== 'ADMIN' ? <div ref={refServices} className="navi-txt" onClick={serivicesHandle}>
              <span className="mb-d-none">
                {variables.  }
                <span
                  className={
                    toggleServices
                      ? "fa-solid fa-chevron-up"
                      : "fa-solid fa-chevron-down"
                  }
                ></span>{" "}
              </span>
              <div
                className={
                  toggleServices ? "menu-drop services-m-drop" : "display-n"
                }
              >
                <div
                  style={{
                    borderBottom: active === "/contect" && "2px solid white",
                  }}
                  onClick={contactNavigate}
                  className="navi-ment-txt"
                >
                  {variables.ForCommunication}
                </div>

                <div
                  style={{
                    borderBottom: active === "/aboutUs" && "2px solid white",
                  }}
                  className="navi-ment-txt"
                  onClick={() => navigate("/aboutUs")}
                >
                  {variables.AboutUs}
                </div>
                <div
                  style={{
                    borderBottom: active === "/services" && "2px solid white",
                  }}
                  onClick={ServicesNavigate}
                  className="navi-ment-txt"
                >
                  {variables.Services}
                </div>
                <div
                  style={{
                    borderBottom: active === "/photos" && "2px solid white",
                  }}
                  className="navi-ment-txt"
                  onClick={() => navigate("/photos")}
                >
                  {variables.Photographs}
                </div>
                <div
                  style={{
                    borderBottom:
                      active === "/privacyPolicy" && "2px solid white",
                  }}
                  className="navi-ment-txt"
                  onClick={() => navigate("/privacyPolicy")}
                >
                  {variables.PrivacyPolicy}
                </div>
              </div>
            </div> : <></>} */}
            {admin !== 'ADMIN' ? isLoggedIn ? (
              <div ref={refSearch} className="navi-txt" onClick={actionSearch}>
                <span className="mb-d-none">
                  {variables.searching}
                </span>
              </div>
            ) : (
              <div
                onClick={() => {
                  navigate("/register/step1");
                }}
                className="navi-txt mbl-active-bdr"
                style={{
                  borderBottom: active === "register" && "2px solid white",
                }}
              >
                {variables.toRegister}
              </div>
            ) : <></>}
            {isLoggedIn ? <div onClick={actionlogout} className="navi-txt mbl-active-bdr">
              {variables.logOut}
            </div> :
              <div
                onClick={() => actionLogin()}
                style={{
                  borderBottom: active === "/login" && "2px solid white",
                }}
                className="navi-txt mbl-active-bdr"
              >
                {variables.login}
              </div>}
          </span>
        </div>
      </div >
      {
        hasPopUp ? <PopUp title="LOG OUT" actionClose={actionlogout} hasPopUp={hasPopUp}>
          < div className="modal-body" >
            <div style={{ 'color': 'black', 'font-size': '16px' }}> Are you sure, You want to logout? </div>
          </div >
          <div className="modal-footer">
            <button type="button" onClick={actionlogout} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary" onClick={logout}> Logout </button>
          </div>
        </PopUp > : <></>
      }
    </div >

  );
}