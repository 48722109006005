import React from "react";
import Select from "react-select";
import { Required } from "../components/utilities/required";

export function Selector({
  name = "name",
  valueArray = [],
  label = "",
  hasUpdate = true,
  onchange,
  optionsData = [],
  isMulti = false,
  placeholder ="",
  error = null,
  required

  // ஒன்றை தேர்ந்தெடுக்கவும்
}) {
  let options = []; 
  for (let i = 0; i < optionsData.length; i++) {
    options.push({
      label: optionsData[i].label,
      value: optionsData[i].value,
      name,
    });
  }
  const defaultValue = () => {
    let returnData = []
      const arrayData  = typeof valueArray !== 'object' ? valueArray?.split(',') : valueArray
      arrayData?.map((i) => {
        returnData.push(options[i-1])
        })
     return returnData
  }
  return (
    <div className={"form-group mr-2"}>
      {label === "" ? <></> : <label>{label}</label>}
      {required ? <Required /> : <></>}
      {error ? <span className='text-danger floatRight'>{error}</span> : null}
   <Select
         defaultValue={defaultValue}
        closeMenuOnSelect={!isMulti}
        options={options}
        onChange={onchange}
        isMulti={isMulti}
        placeholder={placeholder}
      /> 
    </div>
  );
}

