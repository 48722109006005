import { Types } from "../types";

let initialState = {
  loading: false,
  userDataAll: [],
  admin: "",
};

export const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.ADMIN_LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      }
    case Types.ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        admin: action.payload,
        loading: true
      };
    case Types.ADMIN_USERDATA_ALL:
      return {
        ...state,
        userDataAll: action.payload,
        loading: false
      };
    case Types.ADMIN_LOGIN_ERROR:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
};
