import axios from 'axios';
import { updatePassword, updatePasswordForAdmin } from '../API';
import { Types } from '../types';

export const UpdatePassword = (id, password, isAdmin = false) => {
  const userData = {
    password: password,
    auth_id: id
  }
  const adminData = {
    password: password,
  }
  const data = isAdmin ? adminData : userData
  return (dispatch) => {
    dispatch({ type: Types.PASSWORD_CHANGE_REQUEST });
    return axios
      .post(isAdmin ? `${updatePasswordForAdmin}/${id}` : updatePassword, data)
      .then((response) => {
        dispatch({ type: Types.PASSWORD_CHANGE_SUCCESS });
        dispatch({ type: Types.ALERT_SUCCESS, payload: { message: response?.data?.message } })
        return response
      })
      .catch((err) => {
        dispatch({ type: Types.PASSWORD_CHANGE_ERROR });
        dispatch({ type: Types.ALERT_ERROR, payload: { message: err?.response?.data?.message } })
        return err
      });
  };
}