export const validateEmail = (email) => {
    return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};

export const Roles = ["CUSTOMER_ADMIN", "CUSTOMER_STAFF"];

export const validateURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

export const RoleOptions = [
    { key: 'CUSTOMER_ADMIN', name: 'ADMIN' },
    { key: 'CUSTOMER_STAFF', name: 'STAFF' },
    // { key: 'SUPER_ADMIN', name: 'SUPER ADMIN' },
];

export const imageUrlSort = (url) => {
    if (url) {
        return url.split('/').slice(2).join('/')
    } else {
        return ""
    }

}

export const AdminRoles = ["CUSTOMER_ADMIN"];