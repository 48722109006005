// export const baseProfileUrl = 'http://91.107.202.255:2200/api/';
export const baseProfileUrl = 'https://matrimony3.yweb.in/api/';// export const listCustomers = 'https://0t8p0yae66.execute-api.us-west-2.amazonaws.com/dev/listCustomer';

export const loginUrl = `${baseProfileUrl}authenticate`;
export const registerUrl = `${baseProfileUrl}createUser`;
export const updateUser = `${baseProfileUrl}createUser`;
export const userProfileUrl = `${baseProfileUrl}getAllUsers`;
export const getProfileUrl = `${baseProfileUrl}getProfile`;
export const getMyProfileUrl = `${baseProfileUrl}getMyProfile`;
export const getProfileUrlforAdmin = `${baseProfileUrl}getProfileForAdmin`;
export const updatePassword = `${baseProfileUrl}updatePassword`;
export const getAllUsers = `${baseProfileUrl}getAllUsers`;
export const updateMarried = `${baseProfileUrl}updateMarriedAdmin`;
export const updateStatus = `${baseProfileUrl}updateStatusAdmin`;
export const updatePayUser = `${baseProfileUrl}payUser`;
export const updatePasswordForAdmin = `${baseProfileUrl}updatePasswordForAdmin`;
export const interestAcceptReject = `${baseProfileUrl}interestAcceptReject`;
export const accessPremium = `${baseProfileUrl}accessPremium`;
export const deleteRequest = `${baseProfileUrl}deleteRequest`;
export const getDeleteRequest = `${baseProfileUrl}getDeleteRequests`;
export const acceptDelete = `${baseProfileUrl}acceptDelete`;
export const rejectDelete = `${baseProfileUrl}rejectDelete`;
export const imgUploadFromAdmin = `${baseProfileUrl}uploadAdmin`;
export const imgUploadFromUser = `${baseProfileUrl}upload`;
export const updateVerifiedAdmin = `${baseProfileUrl}updateVerifiedAdmin`;
export const updateUserAdmin = `${baseProfileUrl}updateUserAdmin`;
export const passwordResetRequest = `${baseProfileUrl}passwordResetRequest`;
export const getPasswordResetRequests = `${baseProfileUrl}getPasswordResetRequests`;
export const getAcceptPasswordReset = `${baseProfileUrl}acceptPasswordReset`;
export const paymentHistory = `${baseProfileUrl}paymentHistory`;
export const payMyUser = `${baseProfileUrl}payMyUser`;


export const imgUrl = `https://matrimony.yweb.in/images/`
export const imgUrlFirst = `https://matrimony.yweb.in/images/image1/`
