import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserPayment } from '../../store/payment/paymentService';
import { payment } from '../../form/var';


export function PaymentForm({ amount = 10, count, userID }) {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    useEffect(() => {
        const options = {
            key: payment.key, // Replace with your actual API key
            amount: amount * 100,// Use the amount received from the backend
            currency: "INR", // Use the currency received from the backend
            name: 'Kongu Matrimonial Services',
            description: 'Payment for Purchase',
            image: payment.image,
            order_id: "", // Use the order ID received from the backend
            handler: function (response) {
                dispatch(UserPayment(amount, count, response.razorpay_payment_id, userID))
                navigate(-2, { replace: true })

            },
            modal: {
                ondismiss: () => {
                    navigate(-2, { replace: true })
                }
            },
            prefill: {
                name: payment.ownerName,
                email: payment.ownerMail
            },
        };

        const razorpay = new window.Razorpay(options);
        razorpay.open();
    }, []);
};
export default PaymentForm;
