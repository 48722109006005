
import { Types } from "../types"

let initialState = {
    userStatusLoading: false,
    marriedStatusLoading: false,
    userPayLoading: false,
    userVerifiedLoading: false,
    paymentHistoryLoading: false,
    userVerified: {},
    userStatus: {},
    marriedStatus: {},
    payDetails: {},
    paymentHistory: {}
}

export const adminChangeReducer = (state = initialState, action) => {
    switch (action.type) {

        case Types.USER_STATUS_REQUEST:
            return {
                ...state,
                userStatusLoading: true
            }
        case Types.USER_STATUS_SUCCESS:
            return {
                ...state,
                userStatusLoading: false,
                userStatus: action.payload,
                marriedStatus: {},
                userVerified: {},
                payDetails: {},
                paymentHistory: {}
            }
        case Types.USER_STATUS_ERROR:
            return {
                ...state,
                userStatusLoading: false
            }
        case Types.USER_VERIFIED_REQUEST:
            return {
                ...state,
                userVerifiedLoading: true
            }
        case Types.USER_VERIFIED_SUCCESS:
            return {
                ...state,
                userVerifiedLoading: false,
                userVerified: action.payload,
                marriedStatus: {},
                payDetails: {},
                userStatus: {},
                paymentHistory: {}
            }
        case Types.USER_VERIFIED_ERROR:
            return {
                ...state,
                userVerifiedLoading: false
            }
        case Types.USER_MARRIED_STATUS_REQUEST:
            return {
                ...state,
                marriedStatusLoading: true
            }
        case Types.USER_MARRIED_STATUS_SUCCESS:
            return {
                ...state,
                marriedStatusLoading: false,
                marriedStatus: action.payload,
                userVerified: {},
                userStatus: {},
                payDetails: {},
                paymentHistory: {}
            }
        case Types.USER_MARRIED_STATUS_ERROR:
            return {
                ...state,
                marriedStatusLoading: false
            }
        case Types.USER_PAY_REQUEST:
            return {
                ...state,
                userPayLoading: true
            }
        case Types.USER_PAY_SUCCESS:
            return {
                ...state,
                userPayLoading: false,
                payDetails: action.payload,
                userVerified: {},
                userStatus: {},
                marriedStatus: {},
                paymentHistory: {}
            }
        case Types.USER_PAY_ERROR:
            return {
                ...state,
                userPayLoading: false
            }
        case Types.VIEW_PAYMENT_HISTORY_REQUEST:
            return {
                ...state,
                paymentHistoryLoading: true
            }
        case Types.VIEW_PAYMENT_HISTORY_SUCCESS:
            return {
                ...state,
                paymentHistoryLoading: false,
                paymentHistory: action.payload,
                userVerified: {},
                userStatus: {},
                marriedStatus: {},
                payDetails: {},
            }
        case Types.VIEW_PAYMENT_HISTORY_ERROR:
            return {
                ...state,
                paymentHistoryLoading: false
            }

        default:
            return state;
    }

}