import { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { getAge, getDetails, Height, Kulam, Natchatram, Weight } from "../utilities/Constants";
import { imgUrl } from "../../store/API";
import { useDispatch } from "react-redux";
import { GetResentAddedData } from "../../store/dashboard/dashboardService";
import { Skeleton } from "../utilities/skeleton";
import styles from './resentAddedProfilesComponent.module.css'
import { AiOutlineUser, AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { BiGitPullRequest } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { SendIntresetToServer } from "../../store/sendIntrest/sendIntrestService";
import { imageUrlSort } from "../utilities/functions";

export default function ResentAddedProfile() {
  const dispatch = useDispatch();

  let { userProfile, hasResentAddedProfile, resentAddedProfile } = useSelector((state) => state.profile);
  let { interestList, hasLoading } = useSelector((state) => state.requestProfile);
  let { isLoggedIn } = useSelector((state) => state.login);
  const navigate = useNavigate()
  const [user, getUserDetails] = useState({ resentData: [], resentRequestId: [], clickedBtn: "", hasResentAddedProfile: true });

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(GetResentAddedData());
    }
  }, [isLoggedIn]);

  useEffect(() => {
    let id = []
    if (interestList) {
      interestList.forEach((val) => {
        id.push(val.auth_id)
      })
      getUserDetails({ ...user, resentRequestId: id });
    }
  }, [interestList])

  useEffect(() => {
    getUserDetails({ ...user, resentData: resentAddedProfile, hasResentAddedProfile: hasResentAddedProfile })
  }, [resentAddedProfile, hasResentAddedProfile]);

  const actionViewProfile = (id) => {
    console.log(id)
    if (id) {
      navigate(`/viewProfile/${id}`);
    } else {
      console.log("id missing", id)
    }
  }
  const actionSendInterest = (e, id) => {
    if (id && e.target.id === "Sent Interest") {
      getUserDetails({ ...user, clickedBtn: id });
      dispatch(SendIntresetToServer(id))
    }

  }

  return (
    <Fragment>
      <div>
        <h5 className="mt-2 ml-2 head" style={{ paddingTop: '20px' }}>Recent Added Profile</h5>
        {user.hasResentAddedProfile ?
          <div className={styles.container}>
            {user?.resentData?.map((i, index) => {
              return (
                <div className={`${styles.mb_5}`} key={i.key}>
                  <div className={`${styles.border} ${styles.row}`}>
                    {Number(i.verified) === 0 ? <></> : <div id={index} className={styles.my_badge}><div style={{
                      paddingTop: '6px', fontSize: '12px'
                    }}>Verified</div></div>}
                    <div className={`${styles.col_md_auto} ${styles.col_4}`}>
                      <div className={`${styles.text_center} ${styles.text_md_left} ${styles.pt_3} ${styles.pt_md_0}`}>
                        <img src={!i.images[0].targetName ? i.gender === 1 ? "/img/boy.png" : "/img/girl.png" : imgUrl + imageUrlSort(i?.images[0]?.path)} className={` img_fit ${styles.img_fit} ${styles.mw_100} ${styles.size_150px} ${styles.size_md_250px} ${styles.rounded_circle} ${styles.md_rounded_0}`} alt=""></img>
                      </div>

                    </div>
                    <div className={` ${styles.col_8} ${styles.position_static} ${styles.d_flex} ${styles.align_items_center}`}>
                      {/* <span style={{paddingTop: '6px', paddingRight: '10px'}} className={`${styles.absolute_top_right} ${styles.px_4} ${styles.py_3}`}>
              <button className={`btn-clr ml-2 mb-2 `}
              onClick={actionEditProfile}>complete your Profile</button>
            </span> */}
                      <div className={`${styles.px_md_4} ${styles.p_3} ${styles.flex_grow_1}`}>
                        <h2 className={`${styles.h6} ${styles.fw_600} ${styles.fs_18} ${styles.text_truncate} ${styles.mb_1}`}>{i.name}</h2>
                        <div className={`${styles.mb_2} ${styles.fs_12}`}>
                          <span className={styles.opacity_60}>Member ID: </span>
                          <span className={`${styles.ml_4} ${styles.text_primary}`}>{i.user_id}</span>
                        </div>
                        <table className={`${styles.w_100} ${styles.opacity_70} ${styles.mb_2} ${styles.fs_12}`}>
                          <tbody>
                            <tr>
                              {i.kulam === "0" || i.kulam === "" || i.kulam === "kulam" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                                <span>குலம்:</span></td>
                                <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}> {getDetails(Kulam, i.kulam)}</td></>}
                              {i.birthyear === "" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                                <span>பிறந்த நாள்:</span></td>
                                <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}> <i className="fa fa-birthday-cake fa-1x"></i>{" "}
                                  {getAge(i.birthyear + "-" + i.birthmonth + "-" + i.birthdate)} {"வருடம்"}</td></>}
                            </tr>
                            <tr>
                              {i.height === "0" || i.height === "" || i.height === "height" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                                <span>உயரம்:</span></td>
                                <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>   <i className="fa fa-ruler-vertical fa-1x"></i>   {getDetails(Height, i.height)}</td></>}
                              {i.weight === "0" || i.weight === "" || i.weight === "weight" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                                <span>எடை:</span></td>
                                <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>  <i className="fa fa-weight fa-1x"></i> {getDetails(Weight, i.weight)}</td></>}
                            </tr>
                            <tr>
                              {i.natchatram === "0" || i.natchatram === "" || i.natchatram === "natchatram" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                                <span>நட்சத்திரம்:</span></td>
                                <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>  <i className="fa fa-star fa-1x"></i>{" "}
                                  {getDetails(Natchatram, i.natchatram)}</td></>}
                              {i.education === "education" || i.weight === "" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                                <span>படிப்பு:</span></td>
                                <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>   <i class="fa fa-book fa-1x"></i> {i.education}</td></>}
                            </tr>
                            <tr>
                              <td className={`${styles.py_1} ${styles.w_25}`}>
                                <span style={{ color: userProfile.dosam === i.dosam ? 'green' : 'red' }}>தோஷ பொருத்தம்:</span></td>
                              <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`} style={{ color: userProfile.dosam === i.dosam ? 'green' : 'red' }}>
                                {userProfile.dosam === i.dosam ? "உண்டு" : "இல்லை"}</td>
                              {/* <td className={`${styles.py_1} ${styles.w_25}`}>
                            <span>நட்சத்திர பொருத்தம்:</span></td>
                          <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>(
                            {getStarValue(userProfile.gender, userProfile.natchatram, i.natchatram)}/12)<StarRating value={getStarValue(userProfile.gender, userProfile.natchatram, i.natchatram)} /></td> */}
                            </tr>
                          </tbody>
                        </table>
                        <div className={`${styles.row} ${styles.gutters_5}`}>
                          <div className={styles.col} id={i.auth_id} onClick={() => actionViewProfile(i.auth_id)}  >
                            <div style={{ textAlign: 'center' }} name="View Profile" className={`${styles.text_reset} ${styles.c_pointer}`}>
                              <AiOutlineUser name="View Profile" id={i.auth_id} class={`fa-thin fa-user ${styles.fs_20} ${styles.text_primary}`} />
                              <span id={i.auth_id} className={`${styles.d_block} ${styles.fs_10} ${styles.opacity_60}`}>Full Profile</span>
                            </div>
                          </div>
                          <div className={styles.col}>
                            <div style={{ textAlign: 'center' }} name={i.accepted === 0 ? "Requested" : i.accepted === 1 ? "Accepted" : "Sent Intrest"} id={i.auth_id} onClick={actionSendInterest} className={`${styles.text_reset} ${styles.c_pointer}`}>
                              {user.clickedBtn == i.auth_id && hasLoading ? <div class="bg-transparent" type="button" disabled>
                                <span class="spinner-grow spinner-grow-sm bg-danger border-0" role="status" aria-hidden="true"></span>
                              </div> :
                                i.accepted === 0 || user.resentRequestId.includes(i.auth_id) ?
                                  <BiGitPullRequest id="Request" className={`fa-thin fa-user ${styles.fs_20} ${styles.text_primary}`} />
                                  :
                                  i.accepted === 1 ?
                                    <AiFillHeart id="Accepted" className={`fa-thin fa-user ${styles.fs_20} ${styles.text_primary}`} />
                                    :
                                    <AiOutlineHeart id="Sent Interest" onClick={(e) => actionSendInterest(e, i.auth_id)} className={`fa-thin fa-user ${styles.fs_20} ${styles.text_primary}`} />}
                              <span className={`${styles.d_block} ${styles.fs_10} ${styles.opacity_60}`}>{i.accepted === 0 ? "Requested" : i.accepted === 1 ? "Accepted" : "Sent Intrest"}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div> : <Skeleton />}
      </div>
    </Fragment>

  );
}
