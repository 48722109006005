import React from "react";
import { variables } from "../../form/var";

export default function Contect() {
  return (
    <div className="contect">
      <div className="contect-wrap row">
        <h5>{variables.contect}</h5>
        <div className="col-md-6">
          <h5>கொங்கு திருமண தகவல் மையம்</h5>
          <p className="p-pre-line">{variables.address}</p>
        </div>{" "}
        <div className="col-md-6 p-pre-line">{variables.add_ph}</div>
        <div>
          <p>{variables.place}</p>
          <div className="text-center">map here</div>
        </div>
      </div>
    </div>
  );
}
