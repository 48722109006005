import React from "react";
import StarRatingComponent from "react-star-rating-component-new";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons";
export default function StarRating(rating) {

  return (
    <div className="star-rating">
      <StarRatingComponent
        editing={false}
        renderStarIcon={() => (
          <FontAwesomeIcon
            icon={faStar}
            style={{ color: "rgb(253, 186, 73)" }}
          />
        )}
        renderStarIconHalf={() => (
          <FontAwesomeIcon
            icon={faStarHalfAlt}
            style={{ color: "rgb(253, 186, 73)" }}
          />
        )}
        renderEmptyStarIcon={() => (
          <FontAwesomeIcon
            icon={faStarEmpty}
            style={{ color: "rgb(253, 186, 73)" }}
          />
        )}
        starCount={12}
        value={rating.value}
      />
    </div>
  );
}
