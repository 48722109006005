import React from "react";
import "./viewProfile.css";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
   Bloodgroup,
   Color,
   Districts,
   Gender,
   Height,
   House,
   Kulam,
   Month,
   Natchatram,
   Numbers,
   Planets,
   Raasi,
   Register,
   Thosam,
   Weight,
   getDetails,
   Days,
   getAge,
   getStarValue
} from "../../../components/utilities/Constants";
import { variables } from "../../../form/var";
import { EditComp } from "../../../components/editComp/editReuseComp";
import { Btn } from "../../../buttons/button";
import StarRating from "../../../components/layout/starRating";
import { SkeletonPage } from "../../../components/utilities/skeletonPage";
import { imgUrl } from "../../../store/API";
import { GetUserProfile } from "../../../store/viewProfile/viewProfileService";
import { viewMobileNumber } from "../../../store/mobileNumber/viewMobileNumberService";
import { decode } from "hex-encode-decode";
import { SkeletonMobileNumber } from "../../../components/utilities/skeletonMobileNumber";
import { PopUp } from "../../../popup/popup";
import { Types } from "../../../store/types";
import { imageUrlSort } from "../../../components/utilities/functions";
import PaymentForm from "../../paymentGateway/paymentPage";


export default function ViewProfile() {

   const { viewProfile, isLoading } = useSelector((state) => state.viewProfile)
   const { requestLoading } = useSelector((state) => state.accessNumber)
   const { userProfile } = useSelector((state) => state.profile)
   const { pathname } = useLocation()
   const [from, getFromData] = useState()
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const [hasPop, setPopUp] = useState(false)
   const [hasPayment, setPayment] = useState(false)
   const [selectedAmount, setselectedAmount] = useState(0)

   useEffect(() => {
      let searchID = pathname.split("/").pop()
      let searchData = pathname.split("/").slice(1).shift()
      getFromData(searchData)
      // if (Number(searchID) !== viewProfile.auth_id) {
      dispatch(GetUserProfile(searchID))
      // }
   }, [pathname])

   useEffect(() => {
      window.scrollTo(0, 0);
   }, [viewProfile]);

   const starValue = getStarValue(userProfile.gender, userProfile.natchatram, viewProfile.natchatram)
   const infoData = [{
      title: variables.kulam,
      displayValue: getDetails(Kulam, viewProfile.kulam),
      isEdit: false
   },
   {
      title: variables.age,
      displayValue: getAge(viewProfile.birthyear + "-" + viewProfile.birthmonth + "-" + viewProfile.birthdate),
      //icon: "fa fa-home",
   },
   {
      title: variables.lakkanam,
      displayValue: getDetails(Raasi, viewProfile.laknam),
      //icon: "fa fa-home",

   },
   {
      title: variables.rashi,
      displayValue: getDetails(Raasi, viewProfile.raasi),
      //icon: "fa fa-home",
   },
   ]

   var basicData = [
      {
         title: variables.registrar,
         displayValue: getDetails(Register, viewProfile.registerer),
         //icon: "fa fa-pen",
      },
      {
         title: variables.dop,
         displayValue: viewProfile.birthdate + "-" + viewProfile.birthmonth + "-" + viewProfile.birthyear,
         //icon: "fa fa-home",
      },
      {
         title: variables.bloodGroup,
         displayValue: getDetails(Bloodgroup, viewProfile.bloodgroup),
         //icon: "fa fa-home",

      },
      {
         title: variables.gender,
         displayValue: getDetails(Gender, viewProfile.gender),
         //icon: "fa fa-home",
      },
      {
         title: variables.weight,
         displayValue: getDetails(Weight, viewProfile.weight),
         //icon: "fa fa-home",
      },
      {
         title: variables.height,
         displayValue: getDetails(Height, viewProfile.height),
         //icon: "fa fa-home",
      },
      {
         title: variables.color,
         displayValue: getDetails(Color, viewProfile.color),
         //icon: "fa fa-home",
      },
      {
         title: variables.dist,
         displayValue: getDetails(Districts[1], viewProfile.district),
         //icon: "fa fa-home",
      },
      // {
      //    title: variables.mobileNumber,
      //    displayValue: viewProfile.mobile,
      //    //icon: "fa fa-home",
      // },
   ];
   var eduData = [
      {
         title: variables.education,
         displayValue: viewProfile.education,
      },
      {
         title: variables.work,
         displayValue: viewProfile.job,
         //icon: "fa fa-home",
      },
      {
         title: variables.income,
         displayValue: viewProfile.income,
         //icon: "fa fa-home",
      },
      {
         title: variables.workingPlace,
         displayValue: viewProfile.joblocation,
      },
   ];
   var expectationData = [{
      title: "எதிர்பார்க்கும் வரன் பற்றி",
      value: viewProfile.aboutexpect,
      name: "aboutexpect",
      hasInput: true,
      type: "textArea"
   }]
   var familyData = [
      {
         title: variables.fatherName,
         displayValue: viewProfile.father,
         //icon: "fa fa-home",
      },
      {
         title: variables.fatherWork,
         displayValue: viewProfile.fatherjob,
         //icon: "fa fa-home",
      },
      {
         title: variables.motherName,
         displayValue: viewProfile.mother,
         //icon: "fa fa-home",
      },
      {
         title: variables.motherWork,
         displayValue: viewProfile.motherjob,
         //icon: "fa fa-home",
      },
      {
         title: variables.siblings,
         displayValue: viewProfile.siblings,
         //icon: "fa fa-home",
      },
      {
         title: variables.kulam,
         displayValue: getDetails(Kulam, viewProfile.kulam),
         //icon: "fa fa-home",

      },
      {
         title: variables.kuladeyvam,
         displayValue: viewProfile.god,
         //icon: "fa fa-home",
      },
      {
         title: variables.templePlace,
         displayValue: viewProfile.godtemple,
         //icon: "fa fa-home",
      },
      {
         title: variables.familyMothlyIncome,
         displayValue: viewProfile.familyincome,
         //icon: "fa fa-home",
      },
      {
         title: variables.livingHouse,
         displayValue: getDetails(House, viewProfile.housetype),
         //icon: "fa fa-home",

      },
      {
         title: variables.propertyValue,
         displayValue: viewProfile.asset,
         //icon: "fa fa-home",
      },
      {
         title: variables.propertyDetails,
         displayValue: viewProfile.aboutasset,
         //icon: "fa fa-home",
      },
   ];
   var jathagamData = [
      {
         title: variables.dhosam,
         displayValue: getDetails(Thosam, viewProfile.dosam),
         hasInput: false,
         //icon: "fa fa-home",

      },
      {
         title: variables.lakkanam,
         displayValue: getDetails(Raasi, viewProfile.laknam),
         //icon: "fa fa-home",
      },
      {
         title: variables.rashi,
         displayValue: getDetails(Raasi, viewProfile.raasi),
         //icon: "fa fa-home",
      },
      {
         title: variables.nachathiram,
         displayValue: getDetails(Natchatram, viewProfile.natchatram),
         //icon: "fa fa-home",
      },
      {
         title: variables.dhishaErupu,
         displayValue: getDetails(Planets, viewProfile.disa),
         //icon: "fa fa-home",
      },
      {
         title: variables.dhishaVarudam,
         displayValue: getDetails(Numbers, viewProfile.disayear),
         //icon: "fa fa-home",
      },
      {
         title: variables.dhishaMadham,
         displayValue: getDetails(Month, viewProfile.disamonth),
         //icon: "fa fa-home",

      },
      {
         title: variables.dhishaNaal,
         displayValue: getDetails(Days, viewProfile.disadate),
         //icon: "fa fa-home",
      },
      {
         title: variables.birthTime,
         displayValue: viewProfile.birthtime,
      },
      {
         title: variables.birthPlace,
         displayValue: viewProfile.birthlocation,
         //icon: "fa fa-home",
      },
   ];

   const actionPrint = (e) => {
      navigate(`/print/${e.target.id}`);
   }
   const actionAccessMobileNumber = () => {
      if (viewProfile.auth_id) {
         dispatch(viewMobileNumber(viewProfile.auth_id))
      }
      actionPopUp()
   }

   const actionPopUp = () => {
      setPopUp(!hasPop)
   }
   const actionClosePayment = () => {
      setPayment(false)
      setPopUp(!hasPop)
      setselectedAmount(0)
   }

   const actionSelecPayment = () => {
      setPayment(true)
      setTimeout(() => {
         actionClosePayment()
      }, 1000)

   }

   const actionDecodeMobileNumber = (number) => {
      if (number) {
         let decodeNumber = decode(number)
         let removeZero = decodeNumber.substring(1, decodeNumber.length - 1)
         decodeNumber = decode(removeZero)
         return decodeNumber
      } else {
         return "number not added"
      }
   }
   return (
      <div>
         {hasPayment ? <PaymentForm amount={selectedAmount} count={10} userID={viewProfile.auth_id} /> : <></>}
         {hasPop ?
            viewProfile.noacc - viewProfile.premiumcount <= 0 ?
               <PopUp title="Payment" actionClose={actionPopUp} hasPopUp={actionPopUp}>
                  <div className="modal-body">
                     <div>
                        <p style={{ 'color': 'black', 'font-size': '16px' }}> You Have No Access please Add Amount Or  </p>
                        <input className={`m-2 ${selectedAmount === 200 ? "bg-success" : ""}`} type="button" value={"200"} onClick={() => { setselectedAmount(200) }} />
                        <input className={`m-2 ${selectedAmount === 500 ? "bg-success" : ""}`} type="button" value={"500"} onClick={() => { setselectedAmount(500) }} />
                        <input className={`m-2 ${selectedAmount === 1000 ? "bg-success" : ""}`} type="button" value={"1000"} onClick={() => { setselectedAmount(1000) }} />
                     </div>
                  </div>
                  <div className="modal-footer">
                     <p>Selected Amount : ₹  {selectedAmount}</p>
                     <button type="button" onClick={actionClosePayment} className="btn btn-secondary" data-bs-dismiss="modal">No</button>
                     <button type="button" disabled={selectedAmount === 0} className="btn btn-primary" onClick={actionSelecPayment}> Yes </button>
                  </div>
               </PopUp> :
               <PopUp title="Mobile Number" actionClose={actionPopUp} hasPopUp={actionPopUp}>
                  <div className="modal-body">
                     <div style={{ 'color': 'black', 'font-size': '16px' }}> Are You Sure To View The Mobile Number ? </div>
                  </div>
                  <div className="modal-footer">
                     <button type="button" onClick={actionPopUp} className="btn btn-secondary" data-bs-dismiss="modal">No</button>
                     <button type="button" className="btn btn-primary" onClick={actionAccessMobileNumber}> Yes </button>
                  </div>
               </PopUp> : <></>}
         {isLoading ? <SkeletonPage /> :
            viewProfile.name === null ? <div>no data </div> :
               <div className="profile-main-css ">
                  <div className="top-btns w-75 row">
                     {/* <div className="d-flex intrest-div-css ml-2">
               <div className="intrest-inside">
                  <i className="fa-solid fa-heart fa-xs" />
               </div>
               <button className="intrest-btn ">&nbsp; Sent Intrest</button>
            </div> */}
                     {/* <div className="col-md-2" >
                        <Btn name={viewProfile?.accepted === 0 && from === "inrestedProfile" ? "Accept" : viewProfile.accepted === 1 ? "Accpted" : viewProfile.accepted === 0 ? "Requested" : "Sent Intrest"} iconName="fa-regular fa-heart fa-xs" />
                     </div> */}
                     <div className="print-inside col-md-2">
                        <Btn id={viewProfile.auth_id} action={actionPrint} name="Print" iconName="fa fa-print fa-xs" />
                     </div>
                  </div>
                  <div>
                     <div className="profile-dtl d-flex">
                        <p>{viewProfile?.name}</p>
                        <p className="profile-id">{`KMS ${viewProfile?.unique_id}`}</p>
                     </div>
                     {viewProfile.images ?
                        <img src={!viewProfile.images[0]?.targetName ? viewProfile.gender === 1 ? "/img/boy.png" : "/img/girl.png" : imgUrl + viewProfile?.images[0]?.targetName} className=" w-100 img-data " alt=""></img>
                        : <></>}
                  </div>
                  <div className=" mobile-number-div ml-1 mr-1 row mt-3">
                     {requestLoading ? <SkeletonMobileNumber /> :
                        viewProfile.cmobile && viewProfile.isPremiumAccessed ?
                           <div className="d-flex">
                              <h4 className="mb-4 mt-4 p-1 fw-bold">{variables.mobNumberAdded}</h4>
                              <p className="ml-3 mb-4 mt-4 w-75 p-2 fw-bold">{actionDecodeMobileNumber(viewProfile.cmobile)}</p>
                           </div> :
                           <div className=" container d-flex w-100">
                              <div className=" mobile-number d-grid col-sm-9">
                                 <p className="mobile-para-english">{variables.getMobNumberE}</p>
                                 <p className="mobile-para-tamil">{variables.getMobNumber}</p>
                              </div>
                              <div className="  col-sm-3 align-self-center">
                                 <button className="btn btn-primary " onClick={actionPopUp}><span className="text"> {`View Mobile Number (${viewProfile.noacc - viewProfile.premiumcount ?? 0
                                    })`} </span></button>
                              </div>
                           </div>
                     }
                  </div>
                  <div className="basic-dtl-div mt-3">
                     <EditComp isHideEditBtn={true} data={infoData} colLength={"col-md-3"} />
                  </div>
                  <div className="row basic-dtl-div mt-3 w-100 main-div">
                     <p className="head-title">Matches</p>
                     <div className="col-sm-4 d-grid data-div">
                        <div> {
                           userProfile.images?.length ?
                              <img src={!userProfile.images[0]?.targetName ? userProfile.gender === 1 ? "/img/boy.png" : "/img/girl.png" : imgUrl + imageUrlSort(userProfile?.images[0]?.path)} className=" w-100 img-data " alt=""></img>
                              : <></>
                        }
                        </div>
                        <div>
                           <p className="title-para"> {userProfile.name} {"-"} {getAge(userProfile.birthyear + "-" + userProfile.birthmonth + "-" + userProfile.birthdate)}{"Y"}</p>
                           <p className="title-para">{getDetails(Natchatram, userProfile.nachathiram)} </p>
                           <p className="title-para">{getDetails(Kulam, userProfile.kulam)} </p>
                           <p className="title-para">{userProfile.education} </p>
                        </div>
                     </div>
                     <div className="col-sm-4 d-grid data-div">
                        <div className="match-data">
                           <p className={userProfile.dosam === viewProfile.dosam ? " title-para text-success" : " title-para text-danger "}>{"தோஷ பொருத்தம் - "}{userProfile.dosam === viewProfile.dosam ? "உண்டு" : "இல்லை"} </p>
                           <p className="data-para">{getDetails(Thosam, userProfile.dosam)} {" - "} {getDetails(Thosam, viewProfile.dosam)} </p>
                        </div>
                        <div className="match-data" >
                           <p className="title-para">{variables.starMatch}</p>
                           <p className="data-para">{getDetails(Natchatram, userProfile.natchatram)} {" - "} {getDetails(Natchatram, viewProfile.natchatram)} </p>
                           <p className="d-flex star-data"> <StarRating value={starValue} /> &nbsp; {starValue}{"/12"} </p>
                        </div>
                        <div className="match-data">
                           <p className="title-para">{variables.height}</p>
                           <p className="data-para">{getDetails(Height, userProfile.height)}{"-"}{getDetails(Height, viewProfile.height)}</p>
                        </div>
                        <div className="match-data">
                           <p className="title-para">{variables.weight}</p>
                           <p className="data-para">{getDetails(Weight, userProfile.weight)}{"-"} {getDetails(Weight, viewProfile.weight)}</p>
                        </div>
                        <div>
                           <p className="title-para">{variables.dop}</p>
                           <p className="data-para">{userProfile.birthdate + "-" + userProfile.birthmonth + "-" + userProfile.birthyear}{"-"} {viewProfile.birthdate + "-" + viewProfile.birthmonth + "-" + viewProfile.birthyear}</p>
                        </div>
                     </div>
                     <div className="col-sm-4 d-grid data-div">
                        <div>
                           {viewProfile.images ?
                              <img src={!viewProfile.images[0]?.targetName ? viewProfile.gender === 1 ? "/img/boy.png" : "/img/girl.png" : imgUrl + imageUrlSort(viewProfile?.images[0]?.path)} className=" w-100 img-data " alt=""></img>
                              : <></>}
                        </div>
                        <div >
                           <p className="title-para">{viewProfile.name} {"-"} {getAge(viewProfile.birthyear + "-" + viewProfile.birthmonth + "-" + viewProfile.birthdate)}{"Y"} </p>
                           <p className="title-para"> {getDetails(Natchatram, viewProfile.nachathiram)} </p>
                           <p className="title-para">{getDetails(Kulam, viewProfile.kulam)}  </p>
                           <p className="title-para">{viewProfile.education}</p>
                        </div>
                     </div>
                  </div>

                  <div className="basic-dtl-div mt-3">
                     <EditComp
                        headLine={"அடிப்படை விவரங்கள்"}
                        data={basicData}
                        hasTable={false}
                        isHideEditBtn={true}
                     />
                  </div>
                  <div className="basic-dtl-div mt-3">
                     <EditComp
                        headLine={"கல்வி & வேலை பற்றிய விபரம்"}
                        data={eduData}
                        hasTable={false}
                        isHideEditBtn={true}
                        colLength={"col-sm-3"}
                     />
                  </div>
                  <div className="basic-dtl-div mt-3">
                     <EditComp
                        headLine={"குடும்பம் பற்றிய விபரம்"}
                        data={familyData}
                        hasTable={false}
                        isHideEditBtn={true}
                     />
                  </div>
                  <div className="basic-dtl-div mt-3">
                     <EditComp
                        headLine={"ஜாதகம் பற்றிய விபரம்"}
                        data={jathagamData}
                        hasTable={false}
                        isHideEditBtn={true}
                     />
                  </div>
                  <div className="basic-dtl-div mt-3 ">
                     <EditComp
                        headLine={"எதிர்பார்க்கும் வரன் பற்றிய விபரம்"}
                        hasTable={false}
                        data={expectationData}
                        isHideEditBtn={true}
                        colLength={"col-sm-12"}
                     />
                  </div>
                  <div className="basic-dtl-div mt-3">
                     <EditComp
                        headLine={"ராசி & நவாம்சம்"}
                        hasTable={true}
                        userData={viewProfile}
                        isHideEditBtn={true}
                     />

                  </div>
               </div>}</div>



   )
}