import { Types } from "../types";

let initialState = {
    oneLoading: false,
    oneComplete: false,
    twoLoading:false,
    twoComplete:false,
    register: {}
}

export const registerReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.REGISTER_ONE_REQUEST:
            return {
                ...state,
                oneLoading: true,
                oneComplete: false,
            }
        case Types.REGISTER_ONE_SUCCESS:
            return {
                ...state,
                oneLoading: false,
                oneComplete: true,
                register: action.payload
            }
        case Types.REGISTER_ONE_ERROR:
            return {
                ...state,
                oneLoading: false,
                oneComplete: false,
            }
               case Types.REGISTER_TWO_DONE:  
               return{
                ...state,
                twoLoading: false,
                    twoComplete: true,
                    register: action.payload
               } 
            
                case Types.REGISTER_TWO_THREE_REQUEST:
                    return {
                        ...state,
                        twoLoading: true,
                        twoComplete: false,
                    }
                case Types.REGISTER_TWO_THREE_SUCCESS:
                    return {
                        ...state,
                        twoLoading: false,
                        twoComplete: true,
                        register: {}
                    }
                case Types.REGISTER_TWO_THREE_ERROR:
                    return {
                        ...state,
                        twoLoading: false,
                        twoComplete: false,
                    }
        case Types.REGISTER_DONE_SUCCESS:
            return {
                ...state,
                oneComplete: false,
                twoComplete:false,
                register: {}
            }

        default:
            return state;
    }
}