export function TextArea({ lable, placeholder, name, value, onchage }) {
  return (
    <div className="form-row form-group col-md-12">
      <label>{lable}</label>
      <textarea
        className="textAreas p-1"
        rows="3"
        placeholder={placeholder + "..."}
        name={name}
        value={value}
        onChange={onchage}
        required
      ></textarea>
    </div>
  );
}
