import { Types } from "../types";

export const ClearAlert = () => {
    return (dispatch) => {
        dispatch({ type: Types.ALERT_CLEAR });
    }
};

export const Navigation = (menu) => {
    return (dispatch) => {
        dispatch({ type: Types.NAVIGATION_LABEL, payload: menu });
    }
};

export const GotoPage = (pageNumber) => {
    return (dispatch) => {
        dispatch({ type: Types.MATCH_ALL_PROFILE_PAGE_NUMBER, payload: pageNumber });
    }
}
export const AdminPagenumber = (pageNumber) => {
    return (dispatch) => {
        dispatch({ type: Types.ADMIN_PAGE_NUMBER, payload: pageNumber });
    }
}