import { Types } from "../types";

let initialState = {
    passwordRequrstLoading: false,
    requestList: [],
    requestListLoading: false,

}

export const passwordResetReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.USER_RESET_PASSWORD_REQUEST:
            return {
                ...state,
                passwordRequrstLoading: true
            }
        case Types.USER_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordRequrstLoading: false
            }
        case Types.USER_RESET_PASSWORD_ERROR:
            return {
                ...state,
                passwordRequrstLoading: false
            }
        case Types.ADMIN_GET_REQUEST_LIST_REQUEST:
            return {
                ...state,
                requestListLoading: true
            }
        case Types.ADMIN_GET_REQUEST_LIST_SUCCESS:
            return {
                ...state,
                requestListLoading: false,
                requestList: action.payload
            }
        case Types.ADMIN_GET_REQUEST_LIST_ERROR:
            return {
                ...state,
                requestListLoading: false
            }
        default:
            return state;
    }
}