import React, { useEffect, useState } from 'react'
import { Kulam, Color, Height, Weight, Bloodgroup, Natchatram, Raasi, Thosam, Jaathgam, getDetails, getAge } from '../utilities/Constants';
import { WhatsappShareButton } from 'react-share';
import html2canvas from 'html2canvas';
import { useSelector } from 'react-redux';
import "./print.css"
import { variables } from '../../form/var';
import { ImWhatsapp } from 'react-icons/im';
import { useLocation } from 'react-router-dom';

export function Print() {
    const { pathname } = useLocation()
    let { viewProfile } = useSelector((state) => state.viewProfile);
    let { userProfile } = useSelector((state) => state.profile);
    let { userData } = useSelector((state) => state.login);
    const [state, setState] = useState({ Logged: null, jaathagamphoto: null, loader: null, userlevel: null, userkattam: null, share: null, key: null })

    useEffect(() => {
        window.scrollTo(0, 0);
        let searchID = pathname.split("/").pop()
        if (userProfile?.auth_id === Number(searchID)) {
            setState({ ...state, ...userProfile })
        } else {
            setState({ ...state, ...viewProfile })
        }
    }, [pathname, viewProfile, userProfile])

    const calculate_age = (dob) => {
        var today = new Date();
        var birthDate = new Date(dob);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age + 1 + ' Y';
    }

    const handlePrint = () => {
        var printContents = document.getElementById('printable').innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
    }

    // const handleDownload = () => {
    //     const input = document.getElementById('printable');
    //     // document.ownerDocument.getElementById('printable').style.display = 'inline';
    //     // console.log(input.style.display = "block")
    //     // html2canvas(input, {
    //     //   width: 1120,
    //     //   height: 1500
    //     // }).then((canvas) => {
    //     //   this.saveAs(canvas.toDataURL(), state.name + '.png');
    //     // });
    // }
    const getJSONValue = (json, value) => {
        let filteredJSON = json.filter(
            function (json) {
                if (json.value === value) {
                    return json.text;
                }
                return null;
            }
        );
        return filteredJSON[0] ? filteredJSON[0].text : null;
    }
    const viewData = (stringData) => {
        let data = "";
        const arrayData = typeof stringData !== 'object' ? stringData?.split(',') : stringData

        arrayData?.map((sData, index) => {
            const num = Number(sData)
            data += sData !== 0 ? (index === 0 ? "" : " , ") + getDetails(Jaathgam, num) : ''
        })
        return data

    }
    const viewTable = (hasRaasai) => {
        var indents = []
        for (var i = 1; i <= 16; i++) {
            if (i === 6 || i === 7 || i === 10 || i === 11) {
                indents.push(<div key={i} className=" col-3 "> <p className={hasRaasai ? 'kattam-style-rasi' : "kattam-style-navamsam"}>{i === 10 ? hasRaasai ? variables.rashi : variables.navaamsam : ""} </p> </div>)
            } else {
                indents.push(
                    <div key={i} className={`jathagam-view col-3 d-flex border border-1 border-dark`}>
                        <p className="text-kattam">{
                            i === 1 ? hasRaasai ? viewData(state.r12) : viewData(state.n12) :
                                i === 2 ? hasRaasai ? viewData(state.r1) : viewData(state.n1) :
                                    i === 3 ? hasRaasai ? viewData(state.r2) : viewData(state.n2) :
                                        i === 4 ? hasRaasai ? viewData(state.r3) : viewData(state.n3) :
                                            i === 5 ? hasRaasai ? viewData(state.r11) : viewData(state.n11) :
                                                i === 8 ? hasRaasai ? viewData(state.r4) : viewData(state.n4) :
                                                    i === 9 ? hasRaasai ? viewData(state.r10) : viewData(state.n10) :
                                                        i === 12 ? hasRaasai ? viewData(state.r5) : viewData(state.n5) :
                                                            i === 13 ? hasRaasai ? viewData(state.r9) : viewData(state.n9) :
                                                                i === 14 ? hasRaasai ? viewData(state.r8) : viewData(state.n8) :
                                                                    i === 15 ? hasRaasai ? viewData(state.r7) : viewData(state.n7) :
                                                                        i === 16 ? hasRaasai ? viewData(state.r6) : viewData(state.n6) : {}
                        }</p>
                    </div>
                )
            }
        }
        return indents;
    }

    return (
        <div>
            <div>
                {/* <button onClick={handleShare} color='teal' style={{ float: 'right' }}>Download</button> */}
                {userData.role === 'ADMIN' ?
                    <>
                        <WhatsappShareButton
                            style={{ float: 'right', marginTop: "5px" }}
                            url={`https://www.kongumatrimonialservice.in/view/unauth/${state.key}`}
                            title={`பெயர்: ${state.name}, வயது: ${state.birthday ? calculate_age(state.birthday) : ''}, குலம்: ${state.kulam ? getJSONValue(Kulam, state.kulam) : ''}, நட்சத்திரம்: ${state.natchatram ? getJSONValue(Natchatram, state.natchatram) : ''}, மேலும் விபரங்களுக்கு: `}>
                            <button className='whatsapp-btn' style={{ float: 'right' }}> <ImWhatsapp className=' mr-2 mb-1' />  Share to Whatsapp</button>
                        </WhatsappShareButton>
                    </> : null}
                <div>
                    <button className=" mt-1 pr-4 pl-4 printBtn" onClick={handlePrint}>Print</button>
                </div>
                <div id='printable' className=' mt-5 mb-5 border border-dark text-dark jathagam-align w-75 '>
                    <div className='border-dark mb-3 '>
                        <div>
                            <img className='w-100' src='/img/printlogo.jpg' alt='img/printlogo.jpg' />
                        </div>
                        <div className='printUnderlineDash'></div>
                        <div>
                            <h5 className='text-center'>ஜாதக விவரம்</h5>
                            <p className='printMantra printUnderline'>ஜெனனீ ஜென்ம செளக்யானாம், வ்த்தனீ  குல சம்பதாம்<br></br>பதவி பூர்வ புணர்யனாம், லிக்யதே ஜென்ம பத்ரிகா</p>
                        </div>
                        <div className='printUnderline'>
                            <div className='container'>
                                <div className="row">
                                    <div className="col-sm-5 col-md-6 ">
                                        <div className=' border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.name}</p>
                                            <p className='fw-bold'>{state.name}</p>
                                        </div>
                                        <div className=' border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.age}</p>
                                            <p className='fw-bold'>{getAge(state.birthyear + "-" + state.birthmonth + "-" + state.birthdate)}</p>
                                        </div>
                                        <div className=' border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.fatherName}</p>
                                            <p className='fw-bold'>{state.father}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.motherName}</p>
                                            <p className='fw-bold'>{state.mother}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.dop}</p>
                                            <p className='fw-bold'>{state.birthdate + "-" + state.birthmonth + "-" + state.birthyear}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.birthPlace}</p>
                                            <p className='fw-bold'>{state.birthlocation === "" ? "-" : state.birthlocation}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.fatherWork}</p>
                                            <p className='fw-bold'>{state.fatherjob}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.motherWork}</p>
                                            <p className='fw-bold'>{state.motherjob}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.education}</p>
                                            <p className='fw-bold'>{state.education}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.work}</p>
                                            <p className='fw-bold'>{state.job}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.income}</p>
                                            <p className='fw-bold'>{state.income === "" ? "-" : state.income}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.workingPlace}</p>
                                            <p className='fw-bold'>{state.joblocation === "" ? "-" : state.joblocation}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.propertyDetails}</p>
                                            <p className='fw-bold'>{state.asset}</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-5 col-md-6">
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.regNo}</p>
                                            <p className='fw-bold'>{"KMS " + state.unique_id}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.kulam}</p>
                                            <p className='fw-bold'>{getDetails(Kulam, state.kulam)}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.kuladeyvam}</p>
                                            <p className='fw-bold'>{state.god}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.temple}</p>
                                            <p className='fw-bold'>{state.godtemple}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.color}</p>
                                            <p className='fw-bold'>{getDetails(Color, state.color)}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.height}</p>
                                            <p className='fw-bold'>{getDetails(Height, state.height)}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.weight}</p>
                                            <p className='fw-bold'>{getDetails(Weight, state.weight)}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.bloodGroup}</p>
                                            <p className='fw-bold'>{getDetails(Bloodgroup, state.bloodgroup)}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.nachathiram}</p>
                                            <p className='fw-bold'>{getDetails(Natchatram, state.natchatram)}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.lakkanam}</p>
                                            <p className='fw-bold'>{state.laknam}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.rashi}</p>
                                            <p className='fw-bold'>{getDetails(Raasi, state.raasi)}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.dhosam}</p>
                                            <p className='fw-bold'>{getDetails(Thosam, state.dosam)}</p>
                                        </div>
                                        <div className='border-bottom printMargin w-100 justify-content-between d-flex'>
                                            <p>{variables.siblings}</p>
                                            <p className='fw-bold'>{state.siblings}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-space-around'>
                                <p className=' mt-n1 ml-3 pr-4'>{variables.expectationDitails}</p>
                                <p className='fw-bold lh-1 '>{state.aboutexpect}</p>
                            </div>
                        </div>
                        <div>
                            <h5 className='text-center mt-n3'>
                                ஜெனன காலம் வந்த சந்திரன் மஹாதிசை இருப்பு {state.thisayear === "" ? state.thisayear : '-'} வருஷம் {state.thisamonth ? state.thisamonth : '-'} மாதம் {state.thisaday ? state.thisaday : '-'} நாள்
                            </h5>
                            <h4 className='mb-2 mt-n1 fw-bold text-center'>
                                {variables.rasiNachathiram}
                            </h4>
                            <div className='container'>
                                <div className="row justify-content-around d-flex m-2s">
                                    <div className=' m-1 col-sm-5 col-md-5 '>
                                        <div className="row ">{viewTable(true)}</div>
                                    </div>
                                    <div className=' m-1 col-sm-5 col-md-5'>
                                        <div className="row ">{viewTable(false)}</div>
                                    </div>
                                </div>

                            </div>
                            <h5 className=' mt-1 mb-1 text-center'>இரு மனம் இணைந்து திருமணம் நிறைவேற இனிய வாழ்த்துக்கள்</h5>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
                <div>
                </div>
            </div>
        </div>
    )
}

