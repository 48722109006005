import { Types } from "../types";

let initialState = {
    hasLoading: false,
    interestList: [],
}
let requestIDs = []
export const interestPeopleReducer = (state = initialState, action) => {

    switch (action.type) {
        case Types.INTEREST_REQUEST:
            return {
                hasLoading: true
            }
        case Types.INTEREST_SUCCESS:
            requestIDs.push(action.payload)
            return {
                ...state,
                interestList: requestIDs,
                hasLoading: false
            }
        case Types.INTEREST_ERROR:
            return {
                hasLoading: false
            }
        default:
            return state;
    }

}