import { applyMiddleware } from 'redux';
import RootReducer from './rootReducer';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import { UserLogout } from './login/loginService';
import jwtDecode from 'jwt-decode';

let middleware = [];

const persistConfig = {
    key: 'matrimony',
    storage,
}

const checkTokenExpirationMiddleware = (store) => (next) => (action) => {
    const token = localStorage.getItem("mat.token");
    if (token && jwtDecode(token).exp < (Date.now() / 1000)) {
        store.dispatch(UserLogout());
        next(action);
    }
    next(action);
};

const persistedReducer = persistReducer(persistConfig, RootReducer)

// if (process.env.NODE_ENV === 'development') {
middleware = [...middleware, logger, thunk, checkTokenExpirationMiddleware];
// } else {
//     middleware = [...middleware, thunk];
// }
const store = configureStore({ reducer: persistedReducer, middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middleware) });

export default store;
