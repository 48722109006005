import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAge, getDetails, Natchatram, Raasi } from "../utilities/Constants";
import { imgUrl } from "../../store/API";
import { GetViewedProfile } from "../../store/dashboard/dashboardService";
import { Skeleton } from "../utilities/skeleton";
import styles from './viewedProfieComponent.module.css'
import { HiEye } from "react-icons/hi";
import { imageUrlSort } from "../utilities/functions";

export default function ViewedProfile() {
  const navigate = useNavigate()
  const [user, getViewed] = useState([]);
  let { hasViewedProfile, viewedProfile } = useSelector((state) => state.profile);
  let { isLoggedIn } = useSelector((state) => state.login);
  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoggedIn) {
      setTimeout(() => {
        dispatch(GetViewedProfile())
      }, 1000)
    }
  }, [isLoggedIn]);

  useEffect(() => {
    getViewed(viewedProfile)
  }, [viewedProfile, hasViewedProfile])

  const actionViewProfile = (id) => {
    if (id) {
      navigate(`/viewProfile/${id}`);
    } else {
      console.log("id missing", id)
    }
  }
  return (
    <div >
      <h2 className="title-style center_card head"> Viewed Profile</h2>
      {hasViewedProfile ?
        user.length ?
          <div >
            {user?.map((i) => {
              return (
                <div>
                  <div key={i.key} className={`${styles.mb_5}`}>
                    <div className={`${styles.border} ${styles.row}`}>
                      {Number(i.verified) === 0 ? <></> : <div className={styles.my_badge}><div style={{
                        paddingTop: '6px', fontSize: '12px'
                      }}>Verified</div></div>}
                      <div className={`${styles.col_md_auto} ${styles.col_4}`}>
                        <div className={`${styles.text_center} ${styles.text_md_left} ${styles.pt_3} ${styles.pt_md_0}`}>
                          <img src={!i.images[0].path ? i.gender === 1 ? "/img/boy.png" : "/img/girl.png" : imgUrl + imageUrlSort(i?.images[0]?.path)} className={`${styles.img_fit} ${styles.mw_100} ${styles.size_150px} ${styles.size_md_250px} ${styles.rounded_circle} ${styles.md_rounded_0}`} alt=""></img>
                        </div>
                      </div>
                      <div className={` ${styles.col_8} ${styles.position_static} ${styles.d_flex} ${styles.align_items_center}`}>
                        <div className={`${styles.px_md_4} ${styles.p_3} ${styles.flex_grow_1}`}>
                          <h2 className={`${styles.h6} ${styles.fw_600} ${styles.fs_18} ${styles.text_truncate} ${styles.mb_1}`}>{i.name}</h2>
                          <table className={`${styles.w_100} ${styles.opacity_70} ${styles.mb_2} ${styles.fs_12}`}>
                            <tbody>
                              <tr>
                                {i.birthyear === "" ? <></> : <> <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}> <i className="fa fa-birthday-cake fa-1x"></i>{" "}
                                  {getAge(i.birthyear + "-" + i.birthmonth + "-" + i.birthdate)} {"வருடம்"}</td></>}</tr>
                              <tr>
                                {i.natchatram === "0" || i.natchatram === "" || i.natchatram === "natchatram" ? <></> : <><td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>  <i className="fa fa-star fa-1x"></i>{" "}
                                  {getDetails(Natchatram, i.natchatram)}</td></>}
                              </tr>
                              <tr>
                                {i.raasi === "0" || i.raasi === "" ? <></> : <> <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>   <i className="fa fa-ruler-vertical fa-1x"></i>   {getDetails(Raasi, i.raasi)}</td></>}
                              </tr>
                            </tbody>
                          </table>
                          <div className={styles.col}>
                            <div id={i.auth_id} className={`${styles.text_reset} ${styles.c_pointer} ${styles.val_grid} `}>
                              <div id={i.auth_id}  >
                                <HiEye size={25} onClick={() => actionViewProfile(i.auth_id)} id={i.auth_id} className={styles.View}  ></HiEye>
                                <span id={i.auth_id} className={`${styles.d_block} ${styles.fs_10} ${styles.opacity_60} ${styles.View}`}>View</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div> : <div className={`${styles.mb_5}`}>
            <div className={`${styles.border} ${styles.row}`}> <h4 style={{ 'padding': '40px' }}>Viewed Profile List Empty</h4>  </div> </div>

        : <Skeleton />
      }
    </div >

  );
}
