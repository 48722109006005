import axios from 'axios';
import { imgUploadFromUser, imgUploadFromAdmin } from '../API';
import { GetUserData } from '../dashboard/dashboardService';
import { Types } from '../types';
const imageFileType = ["image/png", "image/jpeg", "image/jpg", "image/apng", "image/avif", "image/gif", "image/webp"]

export const UplodeAndEditImageArray = (files = [], auth_id, admin = "USER") => {
  return (dispatch) => {
    dispatch({ type: Types.IMAGE_UPLODE_REQUEST });
    Promise.all(files.map((file, index) => {
      const formData = new FormData();
      console.log("file type", file.type)
      formData.append('File', file)
      return imageFileType.includes(file.type) ? axios.post(`${admin === "USER" ? imgUploadFromUser : `${imgUploadFromAdmin}/${auth_id}`}/${index + 1}`, formData) : ""
    })).then((res) => {
      dispatch({ type: Types.IMAGE_UPLODE_SUCCESS });
      dispatch(GetUserData(auth_id, admin));
      dispatch({ type: Types.ALERT_SUCCESS, payload: { message: "Image Updated" } })
      dispatch({ type: Types.ALERT_SUCCESS, payload: { message: res.data.message } })
    }).catch((err) => {
      dispatch({ type: Types.IMAGE_UPLODE_ERROR });
      dispatch({ type: Types.ALERT_ERROR, payload: { message: "Image Not Upload Try Again" } })
    })
  };
}