export const Types = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    USER_LOGOUT: 'USER_LOGOUT',
    ADMIN_LOGIN_SUCCESS: 'ADMIN_LOGIN_SUCCESS',

    USER_RESET_PASSWORD_REQUEST: "USER_RESET_PASSWORD_REQUEST",
    USER_RESET_PASSWORD_SUCCESS: "USER_RESET_PASSWORD_SUCCESS",
    USER_RESET_PASSWORD_ERROR: "USER_RESET_PASSWORD_ERROR",

    ADMIN_GET_REQUEST_LIST_REQUEST: "ADMIN_GET_REQUEST_LIST_REQUEST",
    ADMIN_GET_REQUEST_LIST_SUCCESS: "ADMIN_GET_REQUEST_LIST_SUCCESS",
    ADMIN_GET_REQUEST_LIST_ERROR: "ADMIN_GET_REQUEST_LIST_ERROR",

    ADMIN_ACCEPT_RESET_PASSWORD_REQUEST: "ADMIN_ACCEPT_RESET_PASSWORD_REQUEST",
    ADMIN_ACCEPT_RESET_PASSWORD_SUCCESS: "ADMIN_ACCEPT_RESET_PASSWORD_SUCCESS",
    ADMIN_ACCEPT_RESET_PASSWORD_ERROR: "ADMIN_ACCEPT_RESET_PASSWORD_ERROR",

    ADMIN_USERDATA: 'ADMIN_USERDATA',
    ADMIN_LOGIN_REQUEST: 'ADMIN_LOGIN_REQUEST',
    ADMIN_LOGIN_ERROR: 'ADMIN_LOGIN_ERROR',

    ADMIN_USERDATA_ALL: 'ADMIN_USERDATA_ALL',
    ADMIN_VIEW_PROFILE_REQUEST: "ADMIN_VIEW_PROFILE_REQUEST",
    ADMIN_VIEW_PROFILE_SUCCESS: "ADMIN_VIEW_PROFILE_SUCCESS",
    ADMIN_VIEW_PROFILE_ERROR: "ADMIN_VIEW_PROFILE_ERROR",

    ALERT_ERROR: 'ALERT_ERROR',
    ALERT_SUCCESS: 'ALERT_SUCCESS',
    ALERT_WARNING: 'ALERT_WARNING',
    ALERT_INFO: 'ALERT_INFO',
    ALERT_DEFAULT: 'ALERT_DEFAULT',
    ALERT_CLEAR: 'ALERT_CLEAR',

    MATCH_ALL_PROFILE_PAGE_NUMBER: "MATCH_ALL_PROFILE_PAGE_NUMBER",
    ADMIN_PAGE_NUMBER: "ADMIN_PAGE_NUMBER",

    PASSWORD_CHANGE_REQUEST: "PASSWORD_CHANGE_REQUEST",
    PASSWORD_CHANGE_SUCCESS: "PASSWORD_CHANGE_SUCCESS",
    PASSWORD_CHANGE_ERROR: "PASSWORD_CHANGE_ERROR",


    DASHBOARD_USERDATA: 'DASHBOARD_USERDATA',
    DASHBOARD_USERDATA_REQUEST: 'DASHBOARD_USERDATA_REQUEST',
    DASHBOARD_USERDATA_ERROR: 'DASHBOARD_USERDATA_ERROR',

    DASHBOARD_MATCHDATA: 'DASHBOARD_MATCHDATA',
    DASHBOARD_MATCHDATA_REQUESR: 'DASHBOARD_MATCHDATA_REQUEST',
    DASHBOARD_MATCHDATA_ERROR: 'DASHBOARD_MATCHDATA_ERROR',

    DASHBOARD_MATCH_ALL_DATA: 'DASHBOARD_MATCH_ALL_DATA',
    DASHBOARD_MATCH_ALL_DATA_ERROR: 'DASHBOARD_MATCH_ALL_DATA_ERROR',
    DASHBOARD_MATCH_ALL_DATA_REQUESR: 'DASHBOARD_MATCH_ALL_DATA_REQUESR',

    DASHBOARD_INTERESTEDDATA: 'DASHBOARD_INTERESTEDDATA',
    DASHBOARD_INTERESTEDDATA_ERROR: 'DASHBOARD_INTERESTEDDATA_ERROR',
    DASHBOARD_INTERESTEDDATA_REQUESR: 'DASHBOARD_INTERESTEDDATA_REQUESR',

    DASHBOARD_INTERESTEDDATA_ALL: 'DASHBOARD_INTERESTEDDATA_ALL',
    DASHBOARD_INTERESTEDDATA_ALL_ERROR: 'DASHBOARD_INTERESTEDDATA_ALL_ERROR',
    DASHBOARD_INTERESTEDDATA_ALL_REQUESR: 'DASHBOARD_INTERESTEDDATA_ALL_REQUESR',

    DASHBOARD_REQUESTEDDATA: 'DASHBOARD_REQUESTEDDATA',
    DASHBOARD_REQUESTEDDATA_ERROR: 'DASHBOARD_REQUESTEDDATA_ERROR',
    DASHBOARD_REQUESTEDDATA_REQUESR: 'DASHBOARD_REQUESTEDDATA_REQUESR',

    DASHBOARD_REQUESTEDDATA_ALL: 'DASHBOARD_REQUESTEDDATA_ALL',
    DASHBOARD_REQUESTEDDATA_ALL_ERROR: 'DASHBOARD_REQUESTEDDATA_ALL_ERROR',
    DASHBOARD_REQUESTEDDATA_ALL_REQUESR: 'DASHBOARD_REQUESTEDDATA_ALL_REQUESR',

    DASHBOARD_RESENTADDED: 'DASHBOARD_RESENTADDED',
    DASHBOARD_RESENTADDED_ERROR: 'DASHBOARD_RESENTADDED_ERROR',
    DASHBOARD_RESENTADDED_REQUESR: 'DASHBOARD_RESENTADDED_REQUESR',

    DASHBOARD_VIEWED: 'DASHBOARD_VIEWED',
    DASHBOARD_VIEWED_ERROR: 'DASHBOARD_VIEWED_ERROR',
    DASHBOARD_VIEWED_REQUESR: 'DASHBOARD_VIEWED_REQUESR',

    INTEREST_REQUEST: "INTEREST_REQUEST",
    INTEREST_SUCCESS: "INTEREST_SUCCESS",
    INTEREST_ERROR: "INTEREST_ERROR",


    ACTION_REQUEST_PROFILE_REQUEST: "ACTION_REQUEST_PROFILE_REQUEST",
    ACTION_REQUEST_PROFILE_SUCCESS: "ACTION_REQUEST_PROFILE_SUCCESS",
    ACTION_REQUEST_PROFILE_ERROR: "ACTION_REQUEST_PROFILE_ERROR",

    VIEW_PAYMENT_HISTORY_REQUEST: "VIEW_PAYMENT_HISTORY_REQUEST",
    VIEW_PAYMENT_HISTORY_SUCCESS: "VIEW_PAYMENT_HISTORY_SUCCESS",
    VIEW_PAYMENT_HISTORY_ERROR: "VIEW_PAYMENT_HISTORY_ERROR",

    MOBILE_NUMBER_REQUEST: "ACTION_MOBILE_NUMBER_REQUEST",
    MOBILE_NUMBER_SUCCESS: "ACTION_MOBILE_NUMBER_SUCCESS",
    MOBILE_NUMBER__ERROR: "ACTION_MOBILE_NUMBER__ERROR",

    ACCESS_ACCOUNT_REQUEST: "ACCESS_ACCOUNT_REQUEST",
    ACCESS_ACCOUNT_SUCCESS: "ACCESS_ACCOUNT_SUCCESS",
    ACCESS_ACCOUNT_ERROR: "ACCESS_ACCOUNT_ERROR",

    NAVIGATION_LABEL: 'NAVIGATION_LABEL',


    REGISTER_ONE_REQUEST: 'REGISTER_ONE_REQUEST',
    REGISTER_ONE_SUCCESS: 'REGISTER_ONE_SUCCESS',
    REGISTER_ONE_ERROR: 'REGISTER_ONE_ERROR',

    REGISTER_TWO_DONE: 'REGISTER_TWO_DONE',

    REGISTER_TWO_THREE_REQUEST: 'REGISTER_TWO_THREE_REQUEST',
    REGISTER_TWO_THREE_SKIP: "REGISTER_THREE_SKIP",
    REGISTER_TWO_THREE_SUCCESS: 'REGISTER_TWO_THREE_SUCCESS',
    REGISTER_TWO_THREE_ERROR: 'REGISTER_TWO_THREE_ERROR',


    REGISTER_DONE_SUCCESS: 'REGISTER_DONE_SUCCESS',

    USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
    USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    USER_UPDATE_ERROR: 'USER_UPDATE_ERROR',

    VIEW_PROFILE_REQUEST: 'VIEW_PROFILE_REQUEST',
    VIEW_PROFILE_SUCCESS: 'VIEW_PROFILE_SUCCESS',
    VIEW_PROFILE_ERROR: 'VIEW_PROFILE_ERROR',

    IMAGE_UPLODE_REQUEST: "IMAGE_UPLODE_REQUEST",
    IMAGE_UPLODE_ERROR: "IMAGE_UPLODE_ERROR",
    IMAGE_UPLODE_SUCCESS: "IMAGE_UPLODE_SUCCESS",
    IMAGE_UPLODE_FINAL: "IMAGE_UPLODE_FINAL",

    USER_STATUS_REQUEST: "USER_STATUS_REQUEST",
    USER_STATUS_ERROR: "USER_STATUS_ERROR",
    USER_STATUS_SUCCESS: "USER_STATUS_SUCCESS",

    USER_MARRIED_STATUS_REQUEST: "USER_MARRIED_STATUS_REQUEST",
    USER_MARRIED_STATUS_ERROR: "USER_MARRIED_STATUS_ERROR",
    USER_MARRIED_STATUS_SUCCESS: "USER_MARRIED_STATUS_SUCCESS",

    USER_VERIFIED_REQUEST: "USER_VERIFIED_REQUEST",
    USER_VERIFIED_ERROR: "USER_VERIFIED_ERROR",
    USER_VERIFIED_SUCCESS: "USER_VERIFIED_SUCCESS",

    USER_PAY_REQUEST: "USER_PAY_REQUEST",
    USER_PAY_SUCCESS: "USER_PAY_SUCCESS",
    USER_PAY_ERROR: "USER_PAY_ERROR",

    DELETING_REQUEST: "DELETING_REQUEST",
    DELETING_REQUEST_SUCCESS: "DELETING_REQUEST_SUCCESS",
    DELETING_REQUESTY_ERROR: "DELETING_REQUEST_ERROR",

    GET_DELETING_REQUEST: "GET_DELETING_REQUEST",
    GET_DELETING_REQUEST_SUCCESS: "GET_DELETING_REQUEST_SUCCESS",
    GET_DELETING_REQUESTY_ERROR: "GET_DELETING_REQUESTY_ERROR",

    ACCEPT_DELETE_REQUEST: "ACCEPT_DELETE_REQUEST",
    ACCEPT_DELETE_REQUEST_SUCCESS: 'ACCEPT_DELETE_REQUEST_SUCCESS',
    ACCEPT_DELETE_REQUEST_ERROR: "ACCEPT_DELETE_REQUEST_ERROR",

    REJECT_DELETE_REQUEST: "REJECT_DELETE_REQUEST",
    REJECT_DELETE_REQUEST_SUCCESS: 'REJECT_DELETE_REQUEST_SUCCESS',
    REJECT_DELETE_REQUEST_ERROR: "REJECT_DELETE_REQUEST_ERROR",




}

