import React from "react";
import "./popup.css"

export function PopUp({ title, children }) {
  return (
    <div className="popup-box">
      <div className="modal fade show" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block' }} >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content condent-box">
            <div className="modal-header">
              <h6 className="modal-title" id="exampleModalLabel">{title}</h6>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>

  )
}