import axios from 'axios';
import { acceptDelete, deleteRequest, getDeleteRequest, rejectDelete } from '../API';
import { Types } from '../types';

export const AccountdeleteRequest = (deletingReasons) => {
    const data = {
        reason: deletingReasons,

    }
    return (dispatch) => {
        dispatch({ type: Types.DELETING_REQUEST });
        return axios
            .post(deleteRequest, data)
            .then((response) => {
                dispatch({ type: Types.DELETING_REQUEST_SUCCESS, payload: deletingReasons });
                // dispatch({ type: Types.ALERT_SUCCESS, payload: { message: response?.data?.message } })
                return response
            })
            .catch((err) => {
                dispatch({ type: Types.DELETING_REQUESTY_ERROR });
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err?.response?.data?.message } })
                return err
            });
    };
}

export const getDeleteRequests = () => {

    return (dispatch) => {
        dispatch({ type: Types.GET_DELETING_REQUEST });
        axios
            .get(getDeleteRequest)
            .then((response) => {
                dispatch({ type: Types.GET_DELETING_REQUEST_SUCCESS, payload: response.data });
            })
            .catch((err) => {
                dispatch({ type: Types.GET_DELETING_REQUESTY_ERROR });
                // dispatch({type: Types.ALERT_ERROR, payload:{ message: err.response?.data?.message }})
            });
    };
};
export const AcceptDeleteRequeste = (id) => {
    return (dispatch) => {
        dispatch({ type: Types.ACCEPT_DELETE_REQUEST });
        axios
            .get(`${acceptDelete}/${id}`)
            .then((response) => {
                dispatch({ type: Types.ACCEPT_DELETE_REQUEST_SUCCESS, payload: { id } });
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: response?.data?.message } })
            })
            .catch((err) => {
                dispatch({ type: Types.ACCEPT_DELETE_REQUEST_ERROR });
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.message } })
            });
    };
}
export const RejectDeleteRequeste = (id) => {
    console.log('id', id);
    return (dispatch) => {
        dispatch({ type: Types.REJECT_DELETE_REQUEST });
        axios
            .get(`${rejectDelete}/${id}`)
            .then((response) => {
                dispatch({ type: Types.REJECT_DELETE_REQUEST_SUCCESS, payload: { id } });
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: response?.data?.message } })
            })
            .catch((err) => {
                dispatch({ type: Types.REJECT_DELETE_REQUEST_ERROR });
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.message } })
            });
    };
}