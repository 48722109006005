import React from "react";
import { Selector } from "../../form/selector";
import { variables } from "../../form/var";

export default function WideSearch() {
  return (
    <div className="step1-parent-wrap">
      <div className="jumbotron seperat  margin-md">
        <p className="titl-step-1">{variables.ComprehensiveSearch}</p>
        <div className="row mt-4 p-1 ml-3 pb-3">
          <div className="col-md-6">
            <Selector
              label={variables.age_from}
              optionsData={"Register"}
              value={"register.registerer"}
              name={"registrar"}
              onchange={"selectUpdateFiled"}
            />
          </div>
          <div className="col-md-6">
            <Selector
              label={variables.age_to}
              optionsData={"Register"}
              value={"register.registerer"}
              name={"registrar"}
              onchange={"selectUpdateFiled"}
            />
          </div>
          <div className="">
            <Selector
              label={variables.dhosam}
              optionsData={"Register"}
              value={"register.registerer"}
              name={"registrar"}
              onchange={"selectUpdateFiled"}
            />
          </div>
          <div className="">
            <Selector
              label={variables.education}
              optionsData={"Register"}
              value={"register.registerer"}
              name={"registrar"}
              onchange={"selectUpdateFiled"}
            />
          </div>
          <div className="">
            <Selector
              label={variables.nachathiram}
              optionsData={"Register"}
              value={"register.registerer"}
              name={"registrar"}
              onchange={"selectUpdateFiled"}
            />
          </div>
          <div className="wide-s-btn"> Submit</div>
        </div>
      </div>
    </div>
  );
}
