import React, { Fragment, useEffect, useState } from "react";
import { DateSelector } from "../../form/dateSelect";
import { Input } from "../../form/input";
import { Required } from "../../components/utilities/required";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { UserPasswordReset } from "../../store/passwordReset/resetService";
import { useNavigate } from "react-router-dom";
import { variables } from "../../form/var";
import { AiFillEye } from 'react-icons/ai';
import { BiHide, BiShow } from "react-icons/bi";


export function ResetPassword() {

    const navigate = useNavigate()
    const { passwordRequrstLoading } = useSelector((state) => state.passwordReset);
    const [btnDisable, setDisable] = useState(false)
    const [userData, setUserData] = useState({
        mobile: "",
        birthdate: "",
        birthmonth: "",
        birthyear: "",
        password: ""
    })
    const [show, setShow] = useState(false);

    const dispatch = useDispatch()

    useEffect(() => {
        setDisable(!(userData.mobile !== ""
            && userData.birthdate !== ""
            && userData.birthmonth !== ""
            && userData.birthyear !== ""
            && userData.password !== ""))
    }, [userData])

    const actionInputUpdate = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value,
        });
    }

    const actionReset = () => {
        dispatch(UserPasswordReset(userData)).then((res) => {
            if (res.status === 200 || res.data.message === variables.passwordResetMessage) {
                setTimeout(() => {
                    navigate("/login")
                }, 1000);
            }
        })
    }
    return (
        <Fragment >
            <div className="background" >
                <div className="container">
                    <h4 className="text-center text-white ">
                        Reset Password
                    </h4>
                    <div className="reset-content">
                        <div className="reset-cont d-flex flex-column">
                            <Input
                                label={"Register Mobile Number"}
                                value={userData.mobile}
                                name={"mobile"}
                                onchange={actionInputUpdate}
                                required
                                maxLength={14}
                                minLength={8}
                                type="tel"
                                pattern="[0-9]+"
                            />
                        </div>
                        <div className="reset-cont d-flex flex-column mt-1">
                            <label className="ml-n1">
                                Date of Birth
                                <Required />
                            </label>
                            <DateSelector data={userData} action={actionInputUpdate} />
                        </div>
                        <div className=" reset-cont d-flex flex-column mt-1">
                            <Input
                                label={"New Password"}
                                type={show ? "text" : 'password'}
                                value={userData.password}
                                name={"password"}
                                onchange={actionInputUpdate}
                                required
                                maxLength={14}
                                minLength={8}
                            // type="tel"
                            // pattern="[0-9]+"
                            />
                            <span className="p-viewers">
                                {show ? <BiHide onClick={() => setShow(!show)} size={22} className="showicon" /> : <BiShow onClick={() => setShow(!show)} size={22} className="hideicon" />}
                            </span>
                        </div>
                        <div className=" reset-cont d-flex flex-column mt-1">
                            <button className="btn btn-primary" disabled={btnDisable ? btnDisable : passwordRequrstLoading} onClick={actionReset}>
                                {"Reset"} {passwordRequrstLoading ? <span class=" ml-4 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <></>}
                            </button>
                        </div>
                    </div>
                </div>
            </div >
        </Fragment >
        // <div className="card bg-dark text-white mx-auto" style={{ width: "50%", margin: "5%", height: "350px" }}>

        //     <div className=" m-3">
        //         <input />

        //         {/* <Input label={"Register Mobile Number"} name={"mobile"} value={userData.mobile} onchange={actionInputUpdate} /> */}
        //         <div className="d-flex">
        //             <Select
        //                 options={dobYear}
        //                 value={""}
        //                 name={"birthdate"}
        //                 onchange={actionInputUpdate} />
        //             <Select
        //                 options={dobMonths}
        //                 value={""}
        //                 name={"birthmonth"}
        //                 onchange={actionInputUpdate}
        //             />
        //             <Select
        //                 options={dobDays}
        //                 value={""}
        //                 name={"birthyear"}
        //                 onchange={actionInputUpdate}
        //             />

        //         </div>
        //         <input />
        //         {/* <Input label={"New password"} name={"password"} value={userData.password} onchange={actionInputUpdate} /> */}
        //     </div>


        // </div>
    )

}