import React from "react";
import Routers from "./Routes";

import Toast from "./components/toast";
import Topbar from "./components/layout/Topbar";
import axios from "axios";

function App() {
  axios.interceptors.request.use((request) => {
    let token = localStorage.getItem("mat.token");
    console.log('ttt', token)
    request.headers["Authorization"] = `${token ?? "123"}`;
    return request;
  });

  return (
    <div >
      <Toast />

      <Routers />
    </div>
  );
}

export default App;
