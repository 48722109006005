
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { EditComp } from "../../../components/editComp/editReuseComp";
import { variables } from "../../../form/var";
import {
  Bloodgroup,
  Color,
  Districts,
  dobDays,
  dobMonths,
  dobYear,
  Gender,
  Height,
  Hour,
  House,
  Kulam,
  Minute,
  Month,
  Natchatram,
  Numbers,
  Planets,
  Raasi,
  Register,
  Thosam,
  Time,
  Weight,
  getDetails,
  Days,
  getAge
} from "../../../components/utilities/Constants";
import { PopUp } from "../../../popup/popup";
import { UserUpdate } from "../../../store/userUpdate/userupdateService";
import { createRef } from "react";
import { UplodeAndEditImageArray } from "../../../store/imgUplode/imguplodeService";
import { Input } from "../../../form/input";
import { UpdatePassword } from "../../../store/updatePassword/passwordService";
import { imgUrl, imgUrlFirst } from "../../../store/API";
import { Types } from "../../../store/types";
import { Btn } from "../../../buttons/button";
import { updateMarriedStatus, updatePayDetails, updateUserStatus, updateUserVerified, viewPaymentHistory } from "../../../store/admin/adminChangeServices";
import { SkeletonPage } from "../../../components/utilities/skeletonPage";
import { AccountdeleteRequest } from "../../../store/deleteRequest/deleteRequestService";
import { imageUrlSort } from "../../../components/utilities/functions";
import { UserLogout } from "../../../store/login/loginService";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Skeleton } from "../../../components/utilities/skeleton";
import moment from 'moment'

export default function Profile() {
  const [userData, setUserData] = useState({});
  let { userProfile } = useSelector((state) => state.profile);
  let { viewProfile, isLoading } = useSelector((state) => state.viewProfile);
  const { admin, userDataAll } = useSelector((state) => state.admin);
  const { marriedStatus, payDetails, userStatus, paymentHistory, paymentHistoryLoading, userPayLoading, marriedStatusLoading, userStatusLoading, userVerifiedLoading, userVerified } = useSelector((state) => state.adminChange);
  const { hasUpdateLoading } = useSelector((state) => state.userUpdate)
  const { requestLoading } = useSelector((state) => state.imageUpload)

  const [hasPopUp, openPopUp] = useState(false);
  const [hasPassword, openPassword] = useState(false);
  const [hasLocalImg, selectedImgFromLocal] = useState(false);
  const [hasopenPay, openPayPopUp] = useState(false);
  const [img, setImage] = useState([]);
  const [selectedImg, setSelectImage] = useState([]);
  const [newPassword, updatePassword] = useState('');
  const [deletingReasons, updatedeletingReasons] = useState('');
  const [deletingRequest, setDeletingRequest] = useState(false);
  const [confirmation, setConfirmation] = useState({ password: false, payment: false, paymentClear: false, paymentHis: false, clicked: "" });
  const [payment, updatePayment] = useState({ count: "", amount: "" });
  const [response, setResponse] = useState('')
  const [timer, setTimer] = useState(false);

  const [dataAlart, setDataAlart] = useState(false)
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const reff = useRef([]);
  // const { id } = useParams();
  const navigate = useNavigate();
  let searchID;
  let { hasPasswordRequest } = useSelector((state) => state.password);
  console.log('timer', timer);
  if (img !== undefined) {
    reff.current = img?.map((_, i) => reff?.current[i] ?? createRef());
  }

  var basicData = [
    {
      title: variables.registrar,
      value: userData.registerer,
      displayValue: getDetails(Register, userData.registerer),
      //icon: "fa fa-pen",
      hasInput: false,
      name: "registerer",
      optionData: Register,
      isEdit: false
    },
    {
      title: variables.name,
      value: userData.name,
      // icon: "fa fa-user",
      hasInput: true,
      name: "name",
      isEdit: false
    },
    {
      title: variables.age,
      displayValue: getAge(userData.birthyear + "-" + userData.birthmonth + "-" + userData.birthdate),
      value: userData.birthyear,
      //icon: "fa fa-home",
      hasInput: false,
      name: "birthyear",
      isEdit: true
    },
    {
      title: variables.dop,
      displayValue: userData.birthdate + "-" + userData.birthmonth + "-" + userData.birthyear,
      //icon: "fa fa-home",
      hasInput: false,
      type: "select",
      editingData: { birthyear: userData.birthyear, birthmonth: userData.birthmonth, birthdate: userData.birthdate }
    },
    {
      title: variables.bloodGroup,
      displayValue: getDetails(Bloodgroup, userData.bloodgroup),
      value: userData.bloodgroup,
      //icon: "fa fa-home",
      hasInput: false,
      name: "bloodgroup",
      optionData: Bloodgroup
    },
    {
      title: variables.gender,
      displayValue: getDetails(Gender, userData.gender),
      value: userData.gender,
      //icon: "fa fa-home",
      hasInput: false,
      name: "gender",
      optionData: Gender,
      isEdit: true
    },
    {
      title: variables.weight,
      displayValue: getDetails(Weight, userData.weight),
      value: userData.weight,
      //icon: "fa fa-home",
      hasInput: false,
      name: "weight",
      optionData: Weight
    },
    {
      title: variables.height,
      displayValue: getDetails(Height, userData.height),
      value: userData.height,
      //icon: "fa fa-home",
      hasInput: false,
      name: "height",
      optionData: Height
    },
    {
      title: variables.color,
      displayValue: getDetails(Color, userData.color),
      value: userData.color,
      //icon: "fa fa-home",
      hasInput: false,
      name: "color",
      optionData: Color
    },
    {
      title: variables.dist,
      displayValue: getDetails(Object.values(Districts)[0], userData.district),
      //icon: "fa fa-home",
      hasInput: false,
      name: "district",
      optionData: Object.values(Districts)[0],
      value: userData.district
    },
    {
      title: variables.mobileNumber,
      value: userData.cmobile,
      //icon: "fa fa-home",
      hasInput: true,
      name: "cmobile"
    },
  ];
  var eduData = [
    {
      title: variables.education,
      value: userData.education,
      name: "education",
      hasInput: true,
      isEdit: false
    },
    {
      title: variables.work,
      value: userData.job,
      //icon: "fa fa-home",
      hasInput: true,
      name: "job"
    },
    {
      title: variables.income,
      value: userData.income,
      //icon: "fa fa-home",
      hasInput: true,
      name: "income"
    },
    {
      title: variables.workingPlace,
      value: userData.joblocation,
      //icon: "fa fa-home",
      hasInput: true,
      name: "joblocation"
    },
  ];
  var familyData = [
    {
      title: variables.fatherName,
      value: userData.father,
      //icon: "fa fa-home",
      hasInput: true,
      name: "father"
    },
    {
      title: variables.fatherWork,
      value: userData.fatherjob,
      //icon: "fa fa-home",
      hasInput: true,
      name: "fatherjob"
    },
    {
      title: variables.motherName,
      value: userData.mother,
      //icon: "fa fa-home",
      hasInput: true,
      name: "mother"
    },
    {
      title: variables.motherWork,
      value: userData.motherjob,
      //icon: "fa fa-home",
      hasInput: true,
      name: "motherjob"
    },
    {
      title: variables.siblings,
      value: userData.siblings,
      //icon: "fa fa-home",
      hasInput: true,
      name: "siblings"
    },
    {
      title: variables.kulam,
      displayValue: getDetails(Kulam, userData.kulam),
      value: userData.kulam,
      //icon: "fa fa-home",
      hasInput: false,
      name: "kulam",
      optionData: Kulam
    },
    {
      title: variables.kuladeyvam,
      value: userData.god,
      //icon: "fa fa-home",
      hasInput: true,
      name: "god"
    },
    {
      title: variables.templePlace,
      value: userData.godtemple,
      //icon: "fa fa-home",
      hasInput: true,
      name: "godtemple"
    },
    {
      title: variables.familyMothlyIncome,
      value: userData.familyincome,
      //icon: "fa fa-home",
      hasInput: true,
      name: "familyincome"
    },
    {
      title: variables.livingHouse,
      displayValue: getDetails(House, userData.housetype),
      value: userData.housetype,
      //icon: "fa fa-home",
      hasInput: false,
      name: "housetype",
      optionData: House
    },
    {
      title: variables.propertyValue,
      value: userData.asset,
      //icon: "fa fa-home",
      hasInput: true,
      name: "asset"
    },
    {
      title: variables.propertyDetails,
      value: userData.aboutasset,
      //icon: "fa fa-home",
      hasInput: true,
      name: "aboutasset"
    },
  ];
  var expectationData = [{
    title: "எதிர்பார்க்கும் வரன் பற்றி",
    value: userData.aboutexpect,
    name: "aboutexpect",
    hasInput: true,
    type: "textArea"
  }]
  var jathagamData = [
    {
      title: variables.dhosam,
      value: userData.dosam,
      displayValue: getDetails(Thosam, userData.dosam),
      hasInput: false,
      //icon: "fa fa-home",
      name: "dosam",
      optionData: Thosam
    },
    {
      title: variables.lakkanam,
      value: userData.laknam,
      displayValue: getDetails(Raasi, userData.laknam),
      //icon: "fa fa-home",
      name: "laknam",
      optionData: Raasi
    },
    {
      title: variables.rashi,
      value: userData.raasi,
      displayValue: getDetails(Raasi, userData.raasi),
      name: "raasi",
      optionData: Raasi
      //icon: "fa fa-home",
    },
    {
      title: variables.nachathiram,
      value: userData.natchatram,
      displayValue: getDetails(Natchatram, userData.natchatram),
      //icon: "fa fa-home",
      name: "natchatram",
      optionData: Natchatram
    },
    {
      title: variables.dhishaErupu,
      displayValue: getDetails(Planets, userData.disa),
      value: userData.disa,
      //icon: "fa fa-home",
      name: "disa",
      optionData: Planets
    },
    {
      title: variables.dhishaVarudam,
      displayValue: getDetails(Numbers, userData.disayear),
      value: userData.disayear,
      //icon: "fa fa-home",
      name: "disayear",
      optionData: Numbers
    },
    {
      title: variables.dhishaMadham,
      displayValue: getDetails(Month, userData.disamonth),
      value: userData.disamonth,
      //icon: "fa fa-home",
      name: "disamonth",
      optionData: Month
    },
    {
      title: variables.dhishaNaal,
      displayValue: getDetails(Days, userData.disadate),
      value: userData.disadate,
      //icon: "fa fa-home",
      name: "disadate",
      optionData: Days

    },
    {
      title: variables.birthPlace,
      value: userData.birthlocation,
      //icon: "fa fa-home",
      name: "birthlocation",
      hasInput: true
    },
    {
      title: variables.birthTime,
      displayValue: userData.birthhour + ":" + userData.birthminute + "  " + getDetails(Time, userData.birthtime),
      value: userData.birthhour,
      //icon: "fa fa-home",
      name: "birthtime",
      type: "select",
      editingData: { opOne: Hour, opTwo: Minute, opThree: Time, valueOne: userData.birthhour, nameOne: "birthhour", valueTwo: userData.birthminute, nameTwo: "birthminute", valueThree: userData.birthtime, nameThree: "birthtime" }
    },
  ];
  // useEffect(() => {
  //   // const mypath = pathname;
  //   // searchData= mypath.split("/").slice(1).shift()
  //   if (admin === "ADMIN") {
  //     //  searchID = mypath.split("/").pop()  
  //     //   setLoading(false)
  //     dispatch(GetUserProfile(id))
  //   }
  // }, [id]);

  useEffect(() => {
    if (admin === "ADMIN") {
      console.log("searchID", searchID)
      if (searchID === undefined) {
        setUserData(viewProfile);
      }
    } else {
      setUserData(userProfile);
    }
    dispatch({ type: Types.IMAGE_UPLODE_FINAL });

  }, [viewProfile, userProfile])

  useEffect(() => {
    if (admin === "ADMIN") {
      let updateData = [...userDataAll]
      // if (marriedStatus) {
      //   let payUpdate = {}
      // }
      const index = userDataAll.findIndex((i) => i.auth_id === userData.auth_id)
      if (index >= 0) {
        updateData[index] = { ...updateData[index], ...userData, ...marriedStatus, ...payDetails, ...userStatus, ...userVerified }
        dispatch({ type: Types.VIEW_PROFILE_SUCCESS, payload: updateData[index] });
      }
      dispatch({ type: Types.ADMIN_USERDATA, payload: updateData })
    }
  }, [marriedStatus, payDetails, userStatus, userVerified])


  useEffect(() => {
    imageAlign(userData)
  }, [userData])

  const imageAlign = (data) => {
    if (data?.images?.length && data?.images[0]?.image !== null) {
      const img = [...data?.images].sort((a, b) => a.image - b.image)
      setImage(img)
    } else {
      setImage([])
    }
  }

  const actionInputUpdate = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  }

  const actionOnChangePassword = (e) => {
    updatePassword(e.target.value)
  }
  const actiondeletingReasons = (e) => {
    updatedeletingReasons(e.target.value)
  }
  const actionSelecterUpdate = (e) => {
    let name;
    let indexArray = []
    e?.map((data) => {
      name = data.name;
      indexArray.push(data.value)
    });
    setUserData({
      ...userData,
      [name]: indexArray
    });
  };

  const actionUpdate = () => {
    //sent to server 
    if (admin === "ADMIN") {
      if (viewProfile !== userData) {
        dispatch(UserUpdate(userData, true, userData.auth_id)).then(() => {
          setUserData(userData);
        });
      }
    } else {
      if (userProfile !== userData) {
        dispatch(UserUpdate(userData)).then(resp => {
          setUserData(userData);
        });
      }
    }
  }

  const actionEdit = () => {
    actionClose()
  }

  const actionClose = () => {
    setDataAlart(false)
    openPopUp(!hasPopUp)
    openPassword(false)
    openPayPopUp(false)
    selectedImgFromLocal(false)
    actionCancelUplode()
    updatePassword('')
    setConfirmation({ confirmation });
    imageAlign(userData)
  }

  const actionEditPassword = () => {
    openPopUp(!hasPopUp)
    openPassword(true)
  }


  const actionSelectImage = (e) => {

    const [file] = e.target.files;
    const size = file.size;
    let data = [...img].sort((a, b) => a.image - b.image);
    if (size <= 2097152) {
      setDataAlart(false);
    }
    else {
      setDataAlart(true);
    }
    if (data?.length === 0 || e.target.id === "add") {
      data.push(file)
    } else {
      data[e.target.id] = file
    }
    setImage(data);
    setSelectImage([""])
  }

  // const actionSelectImage = (e) => {
  //   let data = [];
  //   const [file] = e.target.files;
  //   if (img.length === 0) {
  //     data.push(file)
  //   } else {
  //     img?.map((img) => {
  //       data.push(img)
  //     })
  //     e.target.id !== "id" ? data.splice(e.target.id, 1, file) : file === undefined ? <></> : data.push(file)
  //   }
  //   setImage(data);
  //   setSelectImage([""])
  // }
  const actionOpenGalary = () => {
    inputRef.current.click()
    selectedImgFromLocal(true)
  }

  const actionUpdateImage = (index) => {
    reff.current[index]?.current.click()
    selectedImgFromLocal(true)
  }

  // const actionDeleteImg = (e) => {
  //   let data = [];
  //   selectedImg?.map((img) => {
  //     data.push(img)
  //   })
  //   data.splice(e.target.id, 1)
  //   selectedImgFromLocal(true)
  //   setImage(data);
  // }

  const actionUplodeImg = () => {
    console.log(img)
    if (selectedImg.length) {
      dispatch(UplodeAndEditImageArray(img, userData.auth_id, admin === "ADMIN" ? admin : "USER"))
      setSelectImage([])
    } else {
      dispatch({ type: Types.ALERT_INFO, payload: { message: " Please Update Your Image " } })
      setSelectImage([])
    }

  }

  const actionCancelUplode = () => {
    // setImage([])
  }

  const actionUpdatePassword = () => {
    if (newPassword.length >= 8) {
      dispatch(UpdatePassword(userData.auth_id, newPassword, admin === "ADMIN")).finally(() => {
        actionEdit()
      })
    } else {
      dispatch({ type: Types.ALERT_WARNING, payload: { message: "Please Enter 8 Characters" } })
      setConfirmation({ ...confirmation, password: !confirmation.password, });
    }

  }
  // const logout = () => {
  //   setTimeout(() => {
  //     // count is 0 here
  //   }, 100000);

  // }

  const actiondeletingRequest = () => {
    setDeletingRequest(!deletingRequest);

    setTimer(!timer)
    if (deletingReasons.length >= 8) {
      dispatch(AccountdeleteRequest(deletingReasons)).then((response) => setResponse(response.data.message))
      dispatch({ type: Types.ALERT_SUCCESS, payload: { message: "Your Accound Deleteing Request send to Admin" } })
      // logout()

    } else {
      dispatch({ type: Types.ALERT_WARNING, payload: { message: "Place Enter 8 Characters" } })
      setDeletingRequest(false)
    }

  }


  // const passwordConfirmation = () => {
  //   setPasswordConfirm(!passwordConfirm);
  // }
  // const passwordConfirmation = () => {
  //   setPasswordConfirm(!passwordConfirm);
  // }

  const actionConformation = (e) => {
    setConfirmation({ ...confirmation, [e.target.id]: !confirmation[e.target.id], clicked: e.target.id });
  }
  const actionopenPayPopUp = () => {
    openPopUp(!hasPopUp)
    openPayPopUp(true)
  }

  const actionPrint = (e) => {
    navigate(`/print/${e.target.id}`);
  }

  const actionChangeStatus = () => {
    dispatch(updateMarriedStatus(userData.auth_id, userData.married === 1 ? 0 : 1))
  }

  const actionStatusDisable = () => {
    dispatch(updateUserStatus(userData.auth_id, userData.status === 1 ? 0 : 1))
  }

  const actionUserVerified = () => {
    console.log(userData.verified)
    dispatch(updateUserVerified(userData.auth_id, Number(userData.verified) === 0 ? 1 : 0))
  }

  const actionViewPayHistory = () => {
    dispatch(viewPaymentHistory(userData.auth_id))
    setConfirmation({ ...confirmation, paymentHis: !confirmation.paymentHis })
  }

  const actionHistoryClose = () => {
    setConfirmation({ ...confirmation, paymentHis: !confirmation.paymentHis })
  }


  const actionAddpayment = () => {
    const updateData = { noacc: Number(userData.noacc) + Number(payment.count), amount: Number(userData.amount) + Number(payment.amount) }
    dispatch(updatePayDetails(payment, userData.auth_id, updateData)).finally(() => {
      updatePayment({ count: "", amount: "" })
      setConfirmation({ ...confirmation, payment: !confirmation.payment })
    })

  }
  const actionDeletePayment = () => {
    const updateData = { noacc: Number(userData.noacc) - Number(payment.count), amount: Number(userData.amount) - Number(payment.amount) }
    const paymentDelete = { count: -Number(payment.count), amount: -Number(payment.amount) }
    updatePayment({ count: "", amount: "" })
    dispatch(updatePayDetails(paymentDelete, userData.auth_id, updateData)).finally(() => {
      setConfirmation({ ...confirmation, paymentClear: !confirmation.paymentClear })
    })
  }
  const renderTime = ({ remainingTime }) => {


    if (remainingTime === 0) {
      setTimer(!timer)
      dispatch(UserLogout());
    }
    return (
      <div className="logtimer">
        <div className="logtext">Remaining</div>
        <div className="logvalue">{remainingTime}</div>
        <div className="logtext">seconds</div>
      </div>
    );
  }

  const actionUpdatePayDetails = (e) => {
    updatePayment({ ...payment, [e.target.id]: e.target.value })
  }
  const imagePath = (image) => {
    console.log(image)
    return image?.length ? image[0]?.name ? URL.createObjectURL(image[0]) :
      image[0]?.path === null ?
        userData.gender === 1 ?
          "/img/boy.png" :
          "/img/girl.png" :
        imgUrl + imageUrlSort(img[0]?.path)
      :
      userData.gender === 1 ? "/img/boy.png" :
        "/img/girl.png"
  }
  // console.log('userProfile', userProfile);
  // console.log(userPayLoading, confirmation.clicked)
  return (
    isLoading ? <div><SkeletonPage /></div> :
      userData?.auth_id === null ? <div>
        <p>no data added</p>
      </div> :
        <div>
          <div>
            {hasPopUp ?
              hasopenPay ? <PopUp title={confirmation.paymentHis ? " Payment History" : "Admin Options"} actionClose={actionClose} hasPopUp={hasPopUp}>
                {confirmation.payment ?
                  <div>  <div className="modal-body"><p>Are you sure you Add Amount .?</p></div>
                    <div className="modal-footer">
                      <button className="btn btn-primary" onClick={actionAddpayment} disabled={userPayLoading} >{"Yes"}
                        {userPayLoading && confirmation.clicked === "payment" ? <span class="spinner-border spinner-border-sm ml-3" role="status" aria-hidden="true" /> : <></>}
                      </button>
                      <button type="button" onClick={actionClose} disabled={userPayLoading} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div></div> :
                  confirmation.paymentClear ?
                    <><div className="modal-body"> <p>Are you sure you Delete Amount .?</p>
                      <div className="modal-footer">
                        <button className="btn btn-primary" disabled={userPayLoading} onClick={actionDeletePayment} > Yes
                          {userPayLoading && confirmation.clicked === "paymentClear" ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> : <></>}
                        </button>
                        <button type="button" disabled={userPayLoading} onClick={actionClose} className="btn btn-secondary" data-bs-dismiss="modal">Close
                        </button>
                      </div></div></> :
                    confirmation.paymentHis ?
                      paymentHistoryLoading ? <><Skeleton /><Skeleton /><Skeleton /></> :
                        <div className="modal-body">
                          <div className=" w-100 d-flex justify-content-center align-items-center">
                            <table className="border border-dark w-100 h-100">
                              <tr>
                                <th className=" border-bottom border-dark text-center p-2">Amount</th>
                                <th className="border-bottom border-dark text-center p-2">Count</th>
                                <th className="border-bottom border-dark text-center p-2 ">Date & Time</th>
                                <th className="border-bottom border-dark text-center p-2 "> Payment</th>

                              </tr>
                              {paymentHistory.map((val, key) => {
                                return (
                                  <tr className=" border-bottom" key={key}>
                                    <td className={`text-center p-2 ${val.amount > 0 ? "text-success fw-bold" : "text-danger fw-bolder"}`}>{val.amount}</td>
                                    <td className={`text-center p-2 ${val.noacc > 0 ? "text-success fw-bold" : "text-danger fw-bolder"}`}>{val.noacc}</td>
                                    <td className="text-center p-2">{moment(Number(val.date)).format("DD/MM/YYYY hh:mm A")}</td>
                                    <td className="text-center p-2">{(val.payment_no === "" ? "Cash" : "Online")}</td>
                                  </tr>
                                )
                              })}
                            </table>
                          </div>
                          <div className="modal-footer border-top border-dark ">
                            <div className="row border-bottom  align-middle">
                              <h5 className="fw-bold">{"Current Detials"}</h5>
                              <div className="col">
                                <p>{" Total Count : " + userData.noacc}</p>
                              </div>

                              <div className="col">
                                <p>{"Amount : Rs " + userData.amount}</p>
                              </div>
                            </div>
                            <button type="button" disabled={userPayLoading} onClick={actionHistoryClose} className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>
                          </div>
                        </div>
                      :
                      <div>
                        <div className="modal-body">
                          <div className="row border-bottom p-1 align-items-center">
                            <div className="col">
                              <h5>{"Registered Date"}</h5>
                              <p>{new Date(Number(userData.created)).toLocaleDateString()}</p>
                            </div>
                            <div className="col">
                              <h5>{"Registered Mobile"}</h5>
                              <p>{userData.mobile}</p>
                            </div>
                          </div>
                          <div className="row border-bottom p-1 align-middle ">
                            <div className="col-sm-8 d-flex fs-5">
                              {"Account Status"} : <h5 className={`m-1 ${userData.married === 1 ? "text-success" : ""}`}>{userData.married === 1 ? "Married" : "Single"}</h5>
                            </div>
                            <div className="col">
                              <button className={`btn btn-${userData.married !== 1 ? "success" : "primary"} w-100`} disabled={marriedStatusLoading} onClick={actionChangeStatus}>{userData.married !== 1 ? "Married" : "Single"}
                                {marriedStatusLoading ? <span class=" ml-3 spinner-border spinner-border-sm" role="status" aria-hidden="true" /> : <></>}
                              </button>
                            </div>
                          </div>
                          <div className="row border-bottom p-1 align-middle ">
                            <div className="col-sm-8 d-flex fs-5 ">
                              {"Account "} : <h5 className={`m-1 ${userData.status === 1 ? "text-success" : "text-danger"}`}>{userData.status === 1 ? "Enable" : "Disable"}</h5>
                            </div>
                            <div className="col">
                              <button className={`btn btn-${userData.status !== 1 ? "primary" : "danger"} w-100`} disabled={userStatusLoading} onClick={actionStatusDisable}>{userData.status !== 1 ? "Enable" : "Disable"}
                                {userStatusLoading ? <span class=" ml-3 spinner-border spinner-border-sm" role="status" aria-hidden="true" /> : <></>}
                              </button>
                            </div>
                          </div>
                          <div className="row border-bottom p-1 align-middle ">
                            <div className="d-flex justify-content-between align-items-baseline">
                              <h5> PAYMENT</h5>
                              <button className="btn btn-link " onClick={actionViewPayHistory}>history</button>
                            </div>

                            <div className="col-sm-6 p-1 ">
                              <input class="form-control " id="amount" min={0} value={payment.amount} onChange={actionUpdatePayDetails} type="number" placeholder="Amount" />
                            </div>
                            <div className="col-sm-6 p-1">
                              <input class="form-control" id="count" min={0} value={payment.count} onChange={actionUpdatePayDetails} type="number" placeholder="Count" />
                            </div>
                            <div className="col-sm-12 d-flex  justify-content-center mt-3 mb-1 ">
                              <div className="col-sm-4">
                                <button className="w-100 btn btn-primary" id="payment" disabled={!(payment.amount !== "" && payment.count !== "")} onClick={actionConformation} >{"Add + " + payment.amount}</button>
                              </div>
                              <div className="col-sm-4">
                                <button className="w-100 btn btn-danger" disabled={!(payment.amount !== "" && payment.count !== "")} id="paymentClear" onClick={actionConformation}>{"Delete - " + payment.amount}</button>
                              </div>
                            </div>
                          </div>
                          <div className="row border-bottom p-1 align-middle">
                            <h5>{"Premium Detials"}</h5>
                            <div className="col">
                              <p>{" Total Count :" + (userData.noacc - userData.premiumcount)}</p>
                            </div>

                            <div className="col">
                              <p>{"Amount: Rs " + userData.amount}</p>
                              {/* <h6>{"secret code"}</h6>
  <p>{userData.secret}</p> */}
                            </div>
                          </div>
                          {/* <Input label={"New password"} onchange = {actionOnChangePassword} value = {newPassword} /> */}
                        </div>
                        <div className="modal-footer">
                          <button type="button" onClick={actionClose} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                      </div>
                }

              </PopUp> :
                hasPassword ? <PopUp title={confirmation.password ? "Confirmation" : 'Change Password'} actionClose={actionClose} hasPopUp={hasPopUp}>
                  <div className="modal-body">
                    {confirmation.password ? <div>Are you sure you want to reset the password?</div> : <Input label={"New password"} onchange={actionOnChangePassword} value={newPassword} />}
                  </div>
                  <div className="modal-footer">
                    <button type="button" onClick={actionClose} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    {confirmation.password ?
                      <button type="button" className="btn btn-primary" onClick={actionUpdatePassword}>
                        {hasPasswordRequest ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> : <></>} Save
                      </button> :
                      <button type="button" className="btn btn-primary" id="password" onClick={actionConformation}> Change Password
                      </button>}
                  </div>
                </PopUp> :
                  <PopUp title='Update Image' actionClose={actionClose} hasPopUp={hasPopUp}>
                    <div className="modal-body h-75">
                      <div className=" row d-flex">
                        {img?.length === 0 ? <></> :
                          img?.map((image, index) => {
                            return (
                              <div key={index} className="d-grid col-sm-4">
                                <div>
                                  <div className={` ${img.size}` > 2097152 ? "border border-2 border-danger" : "border border-2 border-light rounded-3"}>
                                    <img key={index} className="img-fluid w-100 h-100"
                                      src={image?.image !== undefined ? imgUrl + `image${image?.image}/` + image.targetName : URL.createObjectURL(image)} alt="" />
                                  </div>
                                </div>
                                <div className="m-3 row align-items-end">
                                  <input type="file"
                                    id={index} name="avatar"
                                    className="input-cls"
                                    ref={reff.current[index]}
                                    onChange={actionSelectImage}
                                    size={"2mb"}
                                    accept="image/png, image/jpeg" />
                                  <button id={index} className="btn btn-primary" onClick={() => actionUpdateImage(index)}>Update</button>
                                </div>
                              </div>
                            )
                          })
                        }
                        {img?.length === 3 || img?.length >= 3 ? <></> : <div className="d-grid col-sm-4 img-select-view ">
                          <input type="file"
                            id="add" name="avatar"
                            className="input-cls"
                            ref={inputRef}
                            onChange={actionSelectImage}
                            accept="image/png, image/jpeg" />
                          <button id="add" className="img-add  btn btn-primary" onClick={actionOpenGalary}>Add</button>
                        </div>}
                        {dataAlart ? <div style={{
                          color: 'red'
                        }} >File size is greater than 2MB. Please upload file below 2MB.</div> : <></>}
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button type="button" onClick={actionClose} disabled={requestLoading} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-success" onClick={actionUplodeImg} disabled={requestLoading ? true : dataAlart ? true : false}>  {`Upload${requestLoading ? "ing" : ""}`}
                        {requestLoading ? <span class=" ml-4 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <></>}
                      </button>
                    </div>

                  </PopUp> : <></>
            }
          </div >
          <div className="main-css-profile">
            <div className="profile-name-div">
              <div className="print-inside col-md-2">
                <Btn id={userData.auth_id} action={actionPrint} name="Print" iconName="fa fa-print fa-xs" />
              </div>
              {admin === "ADMIN" ?
                <div className="col align-self-end">
                  <button className="c-password-btn m-1 " onClick={actionEditPassword}> Update Password</button>
                  <button className="c-password-btn m-1 " onClick={actionopenPayPopUp}> Update Pay </button>
                </div>
                :
                <button className="c-password-btn" onClick={actionEditPassword}> Change Password</button>
              }
            </div>
            <div className=" profile-dtl d-flex">
              <h4> {userData.name}</h4>
              <p className="profile-id">{"KMS " + userData.unique_id}</p>
            </div>
            <div className="d-flex justify-content-between align-items-end ">
              <div className="d-grid justify-content-start">
                <img
                  src={imagePath(img)} className="profile-img " alt=""></img>
                <button onClick={actionEdit} className="btn btn-primary mt-2 " >Edit Image</button>
              </div>
              {admin === "ADMIN" ?
                <button className={`btn btn-${userData.verified === 1 ? "success" : "primary"} mt-2 h-25`} disabled={userVerifiedLoading} onClick={actionUserVerified}>{userData.verified === 1 ? "Verified" : " Verification"}
                  {userVerifiedLoading ? <span class=" ml-3 spinner-border spinner-border-sm" role="status" aria-hidden="true" /> : <></>}
                </button> : <></>}
            </div>
            <div className="doc-uplode-div row mt-2">
              {/* <div className="col-md-6">
                <p>சாதிச் சான்றிதழ் -<button id="1" className="btn btn-link text-decoration-none" onClick={actionUploadFile}>View</button></p>
              </div>
              <div className="col-md-6">
                <p>ஜாதகம் -<button id="2" className="btn btn-link text-decoration-none" onClick={actionUploadFile}>View</button></p>
              </div> */}
            </div>
            <div>{hasUpdateLoading ? <SkeletonPage /> :
              <div className="basic-dtl-div">
                <EditComp
                  headLine={"அடிப்படை விவரங்கள்"}
                  data={basicData}
                  actionInputChange={actionInputUpdate}
                  actionUpdate={actionUpdate}
                  hasTable={false}
                />
                <div className="basic-dtl-div mt-3">
                  <EditComp
                    headLine={"கல்வி & வேலை பற்றிய விபரம்"}
                    data={eduData}
                    actionInputChange={actionInputUpdate}
                    actionUpdate={actionUpdate}
                    hasTable={false}
                  />
                </div>
                <div className="basic-dtl-div mt-3">
                  <EditComp
                    headLine={"குடும்பம் பற்றிய விபரம்"}
                    data={familyData}
                    actionInputChange={actionInputUpdate}
                    actionUpdate={actionUpdate}
                    hasTable={false}
                  />
                </div>
                <div className="basic-dtl-div mt-3">
                  <EditComp
                    headLine={"ஜாதகம் பற்றிய விபரம்"}
                    data={jathagamData}
                    actionInputChange={actionInputUpdate}
                    actionUpdate={actionUpdate}
                    hasTable={false}
                  />
                </div>
                <div className="basic-dtl-div mt-3 ">
                  <EditComp
                    headLine={"எதிர்பார்க்கும் வரன் பற்றிய விபரம்"}
                    actionInputChange={actionInputUpdate}
                    actionUpdate={actionUpdate}
                    hasTable={false}
                    data={expectationData}
                    colLength={"col-md-12"}
                  />
                </div>
                <div className="basic-dtl-div mt-3">
                  <EditComp
                    headLine={"ராசி & நவாம்சம்"}
                    hasTable={true}
                    userData={userData}
                    actionInputChange={actionInputUpdate}
                    actionSelectorChange={actionSelecterUpdate}
                    actionUpdate={actionUpdate}
                  />
                  {/* <CustomTable stateData = {userData} hasedit = {true} /> */}
                  {/* <CustomTable stateData = {userData} hasedit = {true} /> */}
                  {admin === 'ADMIN' ? <></> : <><div className="delete-item">
                    <button className="delete-val btn btn-outline-danger" onClick={() => setDeletingRequest(true)} disabled={userProfile.isDeleteRequested ? true : false}>Delete Account</button>
                    {deletingRequest ? <PopUp title={'Delete Account'} actionClose={actionClose} hasPopUp={hasPopUp}>
                      <div className="modal-body">
                        <Input label={"What are reasons to delete account?"} onchange={actiondeletingReasons} value={deletingReasons} />
                      </div>
                      <div className="modal-footer">
                        <button type="button" onClick={() => setDeletingRequest(false)} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={actiondeletingRequest} > Send
                        </button>
                      </div>
                    </PopUp> : timer ? <PopUp title={'Log Out'} actionClose={actionClose} hasPopUp={hasPopUp}>

                      <div className="model-food">
                        <div className="timer-wrapper">

                          <CountdownCircleTimer
                            isPlaying
                            duration={5}
                            colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                            colorsTime={[10, 6, 3, 0]}
                            onComplete={() => ({ shouldRepeat: true, delay: 1 })}

                          >
                            {renderTime}
                          </CountdownCircleTimer>
                        </div>
                      </div>
                    </PopUp> : <></>}
                  </div>
                    {userProfile.isDeleteRequested ? <div style={{
                      color: 'red', 'text-align': 'center',
                      'padding-bottom': '10px'
                    }}> Delete Request Sent to Admin*</div> : <div></div>}</>}
                </div>

              </div>}
            </div>

          </div>
        </div >
  );
}


//status code :
//0=> disable
//1=> enable
//2=> delete
