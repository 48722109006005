import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './home.css'
import { useNavigate } from "react-router-dom";

export default function Home() {
  // const images = [
  //   "./img/79859e3ad2c17ef4393abb2f97e52f61.jpg",
  //    "./img/15121696467_1824c3ec33_b.jpg",
  //    "./img/KMCoverImg.jpg" 
  // ]
  const navigate = useNavigate();
  return (
    <div className="home_div">
      <Carousel showThumbs={false} infiniteLoop={true}>
        <div>
          <img className="Carouse_head" src="./img/79859e3ad2c17ef4393abb2f97e52f61.jpg" />
          <h3 className="legend">இரு மணங்களை இணைத்திட</h3>
        </div>
        <div>
          <img className="Carouse_head" src="./img/mrg.jpeg" />
          <p className="legend">கொங்கு இன மக்களுக்காக</p>
        </div>
        <div>
          <img className="Carouse_head" src="./img/15121696467_1824c3ec33_b.jpg" />
          <p className="legend">கொங்கு திருமண தகவல் மையம்</p>
        </div>
      </Carousel>
      <div className="where_togo_area">
        <div className="container">
          <div className="align-items-center row">
            <div className="col-lg-9">
              <div className="form_area">
                <h3>Access Thousands of Matching Profiles Now. Register FREE</h3>
              </div>
            </div>
            <div className="col-lg-3 subbtn">
              <a style={{ textAlign: 'center' }} href="#">
                <button className="boxed-btn4" type="submit" onClick={() => navigate("/register/step1")}>பதிவு செய்ய</button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5 pb-10">
        <div style={{ paddingTop: '20px' }} className="container">
          <h4 className="h4_name" style={{ paddingBottom: '20px' }}>Bringing Two Souls Together</h4>
          <div className="row">
            <div className="col-lg-4 col-md-6 margin-30px-bottom xs-margin-20px-bottom">
              <div className="services-block-three">
                <a href="#">
                  <div className="padding-15px-bottom">
                    <img style={{ width: '150px' }} src="./img/Community-Logo-Background-PNG-Image.png" />
                    {/* <i className="fa fa-eercast"></i> */}
                  </div>
                  <h4>100% Community Profiles</h4>
                  <p className="xs-font-size13 xs-line-height-22">All Profiles are belong to Kongu Community</p>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 margin-30px-bottom xs-margin-20px-bottom">
              <div className="services-block-three">
                <a href="javascript:void(0)">
                  <div className="padding-15px-bottom">
                    <img style={{ width: '100px' }} src="./img/7595571.png" />
                  </div>
                  <h4>Verified by Admin</h4>
                  <p className="xs-font-size13 xs-line-height-22">All Profiles are Verified & Managed by Admin</p>
                </a>
              </div>
            </div>
            <div className="services-block-three col-lg-4 col-md-6 margin-30px-bottom xs-margin-20px-bottom">
              <div className="services-block-three">
                <a href="javascript:void(0)">
                  <div className="padding-15px-bottom">
                    <img style={{ width: '100px' }} src="./img/admin-settings-male.png" />
                  </div>
                  <h4>Controlled by Administrator</h4>
                  <p className="xs-font-size13 xs-line-height-22">Administrator can Manually Controlled all Profiles</p>
                </a>
              </div>
            </div>
            <div className="services-block-three col-lg-4 col-md-6 sm-margin-30px-bottom xs-margin-20px-bottom">
              <div className="services-block-three">
                <a href="javascript:void(0)">
                  <div className="padding-15px-bottom">
                    <img style={{ width: '100px' }} src="./img/astrology.png" />
                  </div>
                  <h4>Star Matches</h4>
                  <p className="xs-font-size13 xs-line-height-22">Find your Perfect match using Thosam & Star Matches</p>
                </a>
              </div>
            </div>
            <div className="services-block-three col-lg-4 col-md-6 xs-margin-20px-bottom">
              <div className="services-block-three">
                <a href="javascript:void(0)">
                  <div className="padding-15px-bottom">
                    <img style={{ width: '80px' }} src="./img/printer+icon-1320196167104736655.png" />
                  </div>
                  <h4>Get Partner's Horoscope</h4>
                  <p className="xs-font-size13 xs-line-height-22">You can get your Partner's Horoscope in standard KMS format</p>
                </a>
              </div>
            </div>
            <div className="services-block-three col-lg-4 col-md-6">
              <div className="services-block-three">
                <a href="javascript:void(0)">
                  <div className="padding-15px-bottom">
                    <img style={{ width: '100px' }} src="./img/pngtree-target-focus-png-image_6467812.png" />
                  </div>
                  <h4>Hundreds of Success Stories</h4>
                  <p className="xs-font-size13 xs-line-height-22">KMS has Hundreds of Success Stories in this Platform</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-9">
              <div className="form_area">
                <h3>Find Your Perfect Match Now</h3>
                <p style={{ color: '#AAB1B7' }}>Get started with Create your Account and access all your Matching Profiles</p>
              </div>
            </div>
            <div className="col-lg-3 subbtn">
              <a style={{ textAlign: 'center' }} href="#">
                <button className="boxed-btn4" type="submit" onClick={() => navigate("/register/step1")}>Register Now</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container" style={{ paddingTop: '20px' }}>
          <h4 className="h4_name ">Find Your Special</h4>
          <div className="find_your_special row">
            <div className="col-md-4">
              <div className="sets-us"><img src="./img/icon_sOQTkc5JR-7Dt0WXW7WjbRHJGMYqqVVBTfhCJ9fjQhA=.png" title="Manually Verified Profiles" />
                <h4>Create Profile</h4>
                <p>Register for free & Make your Profile</p></div>
            </div>
            <div className="col-md-4">
              <div className="sets-us"><img src="./img/67630-heart-love-logo-png-download-free.png" title="100% Secure &amp; Confidential Profile Creation" />
                <h4>Match</h4>
                <p>Select & Find your Perfect Matches</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="sets-us"><img src="./img/chatcoin-chat-logo.png" title="Intelligent Matchmaking Algorithm" />
                <h4>Interact</h4>
                <p>Start a Conversation with your Preffered Profile</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}