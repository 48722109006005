import { combineReducers } from "redux";
import { alertReducer, pageCountReducer } from "./common/commonReducer";
import { DashboardReducer } from "./dashboard/dashboardReducer";
import { loginReducer } from "./login/loginReducer";
import { registerReducer } from "./register/registerReducer";
import { interestPeopleReducer } from "./sendIntrest/sendIntrestReducer";
import { Types } from "./types";
import { viewProfileReducer } from "./viewProfile/viewProfileReducer";
import { adminReducer } from "./login/AdminReducer";
import { passwordReducer } from "./updatePassword/passwordReducer";
import { adminChangeReducer } from "./admin/adminChangeReducer";
import { AccountDeleteReducer, RejectDeleteReducer, getDeleteReducer, userDeleteReducer } from "./deleteRequest/deleteRequestReducer";
import { RequestMobileNumberReducer } from "./mobileNumber/viewMobileNumberReducer";
import { RequestAccountReducer } from "./acceptRequest/acceptRequestReducer";
import { userUpdateReducer } from "./userUpdate/userupdateReducer";
import { ImageUploadReducer } from "./imgUplode/imgUplodeReducer";
import { passwordResetReducer } from "./passwordReset/resetReducer";

const combineReducer = combineReducers({
    alert: alertReducer,
    login: loginReducer,
    profile: DashboardReducer,
    register: registerReducer,
    viewProfile: viewProfileReducer,
    requestProfile: interestPeopleReducer,
    admin: adminReducer,
    password: passwordReducer,
    userDelete: userDeleteReducer,
    getDeleteUser: getDeleteReducer,
    // navigation: navigationReducer
    pageNumber: pageCountReducer,
    request: RequestAccountReducer,
    accessNumber: RequestMobileNumberReducer,
    adminChange: adminChangeReducer,
    AcceptDelete: AccountDeleteReducer,
    RejectDelete: RejectDeleteReducer,
    passwordReset: passwordResetReducer,
    userUpdate: userUpdateReducer,
    imageUpload: ImageUploadReducer,
});

const RootReducer = (state, action) => {
    //action to clear application redux
    if (action.type === Types.USER_LOGOUT) {
        state = {};
    }
    return combineReducer(state, action);
}

export default RootReducer;
