import axios from "axios";
import { Types } from "../types";
import { interestAcceptReject } from "../API";
import { ComingRequestedProfile } from "../dashboard/dashboardService";

export const ActionToRequestProfile = (id, type) => {
    return (dispatch) => {
        dispatch({ type: Types.ACTION_REQUEST_PROFILE_REQUEST });
        axios
            .get(`${interestAcceptReject}/${id}/${type}`)
            .then((response) => {
                dispatch(ComingRequestedProfile());
                dispatch({ type: Types.ACTION_REQUEST_PROFILE_SUCCESS, payload: { auth_id: Number(id) } });
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: response?.data?.message } })
            })
            .catch((err) => {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.message } })
                dispatch({ type: Types.ACTION_REQUEST_PROFILE_ERROR })
            });
    };
}