import { Types } from "../types";

export const userDeleteReducer = (state = {
    hasDeleteRequest: false,
    hasDeleteRequestSuccess: false
}, action) => {

    switch (action.type) {
        case Types.DELETING_REQUEST:
            return {
                hasDeleteRequest: true
            }
        case Types.PASSWORD_CHANGE_SUCCESS:
            return {
                hasDeleteRequestSuccess: true,
                hasDeleteRequest: false
            }
        case Types.PASSWORD_CHANGE_ERROR:
            return {
                hasDeleteRequest: false
            }
        default:
            return state;
    }
}
let initialState = {
    loading: false,
    Deleteddata: []
};
export const getDeleteReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.GET_DELETING_REQUEST:
            return {
                ...state,
                loading: true
            }
        case Types.GET_DELETING_REQUEST_SUCCESS:
            return {
                ...state,
                Deleteddata: action.payload,
                loading: false
            };
        case Types.GET_DELETING_REQUESTY_ERROR:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }
};
let initialValue = {
    hasLoading: false,
    hasacceptDelete: false
}

export const AccountDeleteReducer = (state = initialValue, action) => {

    switch (action.type) {
        case Types.ACCEPT_DELETE_REQUEST:
            return {
                hasLoading: true
            }
        case Types.ACCEPT_DELETE_REQUEST_SUCCESS:
            return {
                ...state,
                hasLoading: false,
                hasacceptDelete: action.payload
            }
        case Types.ACCEPT_DELETE_REQUEST_ERROR:
            return {
                hasLoading: false
            }
        default:
            return state;
    }

}
let initialValues = {
    hasLoading: false,
    hasrejectDelete: false
}

export const RejectDeleteReducer = (state = initialValues, action) => {

    switch (action.type) {
        case Types.REJECT_DELETE_REQUEST:
            return {
                hasLoading: true
            }
        case Types.REJECT_DELETE_REQUEST_SUCCESS:
            return {
                ...state,
                hasLoading: false,
                hasrejectDelete: action.payload
            }
        case Types.REJECT_DELETE_REQUEST_ERROR:
            return {
                hasLoading: false
            }
        default:
            return state;
    }

}
