import { Types } from "../types"

let initialState = {
    isLoading: false,
    hasUpdateComplite: false,
    viewProfile: {}
}

export const viewProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.VIEW_PROFILE_REQUEST:
            return {
                ...state,
                hasUpdateComplite: false,
                isLoading: true,
                viewProfile: {}
            }
        case Types.VIEW_PROFILE_SUCCESS:
            return {
                ...state,
                hasUpdateComplite: true,
                isLoading: false,
                viewProfile: action.payload
            }
        case Types.VIEW_PROFILE_ERROR:
            return {
                ...state,
                hasUpdateComplite: false,
                isLoading: false
            }
        default:
            return state;
    }

}