import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  dobDays,
  dobMonths,
  dobYear,
  Gender,
  Natchatram,
  Raasi,
  RaasiNatchatram,
  Register,
  Thosam,
  dobYears,
  getListofYearsFemale,
  getListofYearsMale
} from "../../components/utilities/Constants";
import { handleValidation } from "../../components/utilities/validations";
import { Input } from "../../form/input";
import Select from "../../form/select";
import { variables } from "../../form/var";
import { UserRegister } from "../../store/register/registerService";
import { useSelector } from "react-redux";
import { BiHide, BiShow } from "react-icons/bi";
import { Types } from "../../store/types";

export default function RegisterStep1() {
  // const [state, setState] = useState({ name: "", birthdate: "", birthmonth: "", birthyear: "", contactnumber: "", dosam: "", mobile: "", natchatram: "", password: "", raasi: "" });
  const [error, setError] = useState({});
  const [show, setShow] = useState(false);
  const { oneComplete, oneLoading } = useSelector((state) => state.register);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (oneComplete) {
      navigate("/register/step2")
    }
  }, [oneComplete])

  const [register, registerUpdate] = useState({
    mobile: "",
    contactnumber: "",
    password: "",
    name: "",
    registerer: 0,
    gender: 0,
    birthdate: 0,
    birthmonth: 0,
    birthyear: 0,
    birthhour: 0,
    birthminute: 0,
    birthtime: "",
    kulam: 0,
    raasi: 0,
    nachathiram: 0,
    dosam: 0,
    laknam: 0,
    bloodgroup: 0,
    district: "",
    height: "",
    weight: "",
    color: "",
    education: "",
    job: "",
    joblocation: "",
    income: "",
    father: "",
    fatherjob: "",
    mother: "",
    motherjob: "",
    siblings: "",
    god: "",
    godtemple: "",
    familyincome: "",
    housetype: 0,
    asset: "",
    birthlocation: "",
    disa: "",
    disayear: "",
    disamonth: "",
    disadate: "",
    aboutme: "",
    aboutasset: "",
    aboutexpect: "",
    r1: "",
    r2: "",
    r3: "",
    r4: "",
    r5: "",
    r6: "",
    r7: "",
    r8: "",
    r9: "",
    r10: "",
    r11: "",
    r12: "",
    n1: "",
    n2: "",
    n3: "",
    n4: "",
    n5: "",
    n6: "",
    n7: "",
    n8: "",
    n9: "",
    n10: "",
    n11: "",
    n12: "",
    auth_id: 0,
  });
  console.log('register', register.gender);
  const inputUpdateFiled = (e) => {
    registerUpdate({
      ...register,
      [e.target.name]: e.target.value,
    });
  };
  const actionSubmit = (e) => {
    e.preventDefault();
    let conditions = [
      { condition: 'required', name: 'name', value: register.name, text: 'Name' },
      { condition: 'required', name: 'registerer', value: register.registerer, text: 'Registerer' },
      { condition: 'required', name: 'gender', value: register.gender, text: 'Gender' },
      { condition: 'required', name: 'birthdate', value: register.birthdate, text: 'Birthdate' },
      { condition: 'required', name: 'birthmonth', value: register.birthmonth, text: 'Birthmonth' },
      { condition: 'required', name: 'birthyear', value: register.birthyear, text: 'Birthyear' },
      { condition: 'required', name: 'raasi', value: register.raasi, text: 'Raasi' },
      { condition: 'required', name: 'nachathiram', value: register.nachathiram, text: 'Nachathiram' },
      { condition: 'required', name: 'dhosam', value: register.dhosam, text: 'Dhosam' },
      { condition: 'required', name: 'mobile', value: register.mobile, text: 'Mobile' },
      { condition: 'required', name: 'contactnumber', value: register.contactnumber, text: '' },
      { condition: 'required', name: 'password', value: register.password, text: 'Password' },
    ]

    let errors = handleValidation(conditions);
    console.log("errors", error);
    console.log("errors.errors", errors.errors);

    if (!errors.isError) {
      setError({});
      dispatch({ type: Types.ALERT_ERROR, payload: { message: errors.isError } })
      setError(errors.errors)
      dispatch(UserRegister(register, 1, false))
        .then((err) => {
          dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.message } })
        });
    } else {
      setError(errors.errors);
      dispatch({ type: Types.ALERT_ERROR, payload: { message: "Fields are Mandatory" } })
    }
  };

  return (
    <div className="step1-parent-wrap">
      <form noValidate={false} onSubmit={actionSubmit}>
        <div className="jumbotron seperat margin-md">
          <p className="titl-step-1 mb-1">{variables.newRegister}</p>
          <div className="row p-md-4 mt-2">
            <div style={{ paddingTop: '10px' }} className="col-lg-4 form-group col-md-3 col-sm-6">
              {/* <span className="text-danger floatRight">
                {error.name}ss
              </span> */}
              <span className="text-danger floatRight">
                {error.name}
              </span>
              <Input
                label={variables.name}
                value={register.name}
                name={"name"}
                onchange={inputUpdateFiled}
                error={error.name}
                required
              />
            </div>

            <div className="col-lg-4 form-group col-sm-4 col-sm-6 ">
              <span className="text-danger floatRight">
                {error.registerer}
              </span>
              <Select name='registerer' value={register.registerer} label={variables.registrar} onchange={inputUpdateFiled} options={Register} required />
            </div>
            <div className="col-lg-4 form-group col-sm-4 col-sm-6">
              <span className="text-danger floatRight">
                {error.gender}
              </span>
              <Select name='gender' value={register.gender} label={variables.gender} onchange={inputUpdateFiled} options={Gender} required />

            </div>
            <div className="col-lg-4 form-group col-sm-4 col-sm-6">
              <span className="text-danger floatRight">
                {error.birthdate}
              </span>
              <Select name='birthdate' value={register.birthdate} label={variables.birthDay} onchange={inputUpdateFiled} options={dobDays}
                required />
            </div>
            <div className="col-lg-4 form-group col-sm-4 col-sm-6">
              <span className="text-danger floatRight">
                {error.birthmonth}
              </span>
              <Select name="birthmonth" value={register.birthmonth} label={variables.month} onchange={inputUpdateFiled} options={dobMonths}
                required />
            </div>
            <div className="col-lg-4 form-group col-sm-4 col-sm-6">
              <span className="text-danger floatRight">
                {error.birthyear}
              </span>
              <Select name="birthyear" value={register.birthyear} label={variables.year} onchange={inputUpdateFiled} options={Number(register?.gender) === 1 ? getListofYearsMale() : getListofYearsFemale()}
                required />
            </div>
            <div className="col-lg-4 form-group col-sm-4 col-sm-6">
              <span className="text-danger floatRight">
                {error.raasi}
              </span>
              <Select name='raasi' value={register.raasi} label={variables.rashi} options={Raasi} onchange={inputUpdateFiled}
                required />
            </div>
            <div className="col-lg-4 form-group col-sm-4 col-sm-6">
              <span className="text-danger floatRight">
                {error.nachathiram}
              </span>
              <Select name='nachathiram' label={variables.nachathiram} options={register.raasi ? RaasiNatchatram[register.raasi] : Natchatram} value={register.nachathiram} onchange={inputUpdateFiled}
                required />
            </div>
            <div className="col-lg-4 form-group col-sm-4 col-sm-6">
              <span className="text-danger floatRight">
                {error.dhosam}
              </span>
              <Select name='dhosam' label={variables.dhosam} options={Thosam} value={register.dhosam} onchange={inputUpdateFiled}
                required />
            </div>
            <div className=" mt-2  col-lg-4 form-group col-sm-4 col-sm-6">
              <span className="text-danger floatRight">
                {error.mobile}
              </span>
              <Input
                label={variables.loginMobileNumber}
                value={register.mobile}
                name={"mobile"}
                onchange={inputUpdateFiled}
                error={error.mobile}
                maxLength={14}
                minLength={8}
                required
                type="text"
                pattern="[0-9]+"
              />
            </div>
            <div className=" mt-2  col-lg-4 form-group col-sm-4 col-sm-6">
              <span className="text-danger floatRight">
                {error.contactnumber}
              </span>
              <Input
                label={variables.mobileNumber}
                value={register.contactnumber}
                name={"contactnumber"}
                onchange={inputUpdateFiled}
                error={error.contactnumber}
                required
                maxLength={14}
                minLength={8}
                type="text"
                pattern="[0-9]+"
              />
            </div>
            <div className=" mt-2  col-lg-4 form-group col-sm-4  col-sm-6">
              <span className="text-danger floatRight">
                {error.password}
              </span>
              <Input
                label={variables.password}
                type={show ? "text" : "password"}
                value={register.password}
                name={"password"}
                onchange={inputUpdateFiled}
                error={error.password}
                pattern="[0-9]+"
                maxLength={20}
                minLength={8}
                required>
              </Input>
              <span className="p-viewer">
                {show ? <BiHide onClick={() => setShow(!show)} size={22} className="showicon" /> : <BiShow onClick={() => setShow(!show)} size={22} className="hideicon" />}
              </span>
            </div>
          </div>
          <div className=" btnGroup btns-step-1">
            <button type="submit" class="nextBtn" disabled={oneLoading}>
              Next
              {oneLoading ? <span class=" ml-4 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <></>}
            </button>
          </div>
        </div>
      </form>
    </div>

  );
}
