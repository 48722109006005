import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AiOutlineUser, AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { BiGitPullRequest } from "react-icons/bi";
import {
  getAge,
  getDetails,
  getStarValue,
  Height,
  Kulam,
  Natchatram,
  Weight,
} from "../utilities/Constants";

import StarRating from "../layout/starRating";
import { useNavigate } from "react-router-dom";
import { SendIntresetToServer } from "../../store/sendIntrest/sendIntrestService";
import { GetMachedProfile } from "../../store/dashboard/dashboardService";
import { GotoPage } from "../../store/common/commonService";
import { imgUrl } from "../../store/API";
import { Skeleton } from "../utilities/skeleton";
import styles from './MachedProfileComponents.module.css'
import { imageUrlSort } from "../utilities/functions";
export function MachedProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, getUserDetails] = useState({ matchedData: [], resentRequestId: [], clickedBtn: "", hasMatchedProfile: false });
  let { userProfile, hasMatchedProfile, matchedProfile } = useSelector((state) => state.profile);
  let { isLoggedIn } = useSelector((state) => state.login);
  let { interestList, hasLoading } = useSelector((state) => state.requestProfile);


  useEffect(() => {
    if (isLoggedIn) {
      dispatch(GetMachedProfile());
    }
  }, [isLoggedIn]);

  useEffect(() => {
    getUserDetails({ ...data, matchedData: matchedProfile, hasMatchedProfile: hasMatchedProfile });
  }, [matchedProfile, hasMatchedProfile])

  useEffect(() => {
    let id = []
    if (interestList) {
      interestList.forEach((val) => {
        id.push(val.auth_id)
      })
      getUserDetails({ ...data, resentRequestId: id });
    }
  }, [interestList])

  const actionViewProfile = (id) => {
    // dispatch(ViewSelectdProfile(e.target.id))
    if (id) {
      navigate(`/viewProfile/${id}`);
    } else {
      console.log("id missing", id)
    }
  }
  const actionSendInterest = (id, e) => {
    if (id && e.target.id === "Sent Interest") {
      getUserDetails({ ...data, clickedBtn: id });
      dispatch(SendIntresetToServer(id))
    }

  }
  const actionGetMoreProfile = () => {
    dispatch(GetMachedProfile(true));
    dispatch(GotoPage(1))
    setTimeout(() => {
      navigate("/matchedAllProfile");
    }, 1000)
  }
  return (
    <div>

      <h5 className="mt-2 ml-2 head" >Matches for you</h5>

      {data.hasMatchedProfile ? <div className={styles.container}>
        {data.matchedData?.map((i, index) => {
          return (
            <div className={`${styles.mb_5} ${styles.position}`}>
              <div className={`${styles.border} ${styles.row}`}>
                {Number(i.verified) === 0 ? <></> : <div id={index} className={styles.my_badge}><div style={{
                  paddingTop: '6px', fontSize: '12px'
                }}>Verified</div></div>}
                <div className={`${styles.col_md_auto} ${styles.col_4}`}>
                  <div className={`${styles.text_center} ${styles.text_md_left} ${styles.pt_3} ${styles.pt_md_0}`}>
                    <img src={!i.images[0].targetName ? i.gender === 1 ? "/img/boy.png" : "/img/girl.png" : imgUrl + imageUrlSort(i?.images[0]?.path)} className={`img_fit ${styles.img_fit} ${styles.mw_100} ${styles.size_150px} ${styles.size_md_250px} ${styles.rounded_circle} ${styles.md_rounded_0}`} alt=""></img>
                  </div>
                </div>
                <div className={` ${styles.col_8} ${styles.position_static} ${styles.d_flex} ${styles.align_items_center}`}>

                  <div className={`${styles.px_md_4} ${styles.p_3} ${styles.flex_grow_1}`}>
                    <h2 className={`${styles.h6} ${styles.fw_600} ${styles.fs_18} ${styles.text_truncate} ${styles.mb_1}`}>{i.name}</h2>
                    <div className={`${styles.mb_2} ${styles.fs_12}`}>
                      <span className={styles.opacity_60}>Member ID: </span>
                      <span className={`${styles.ml_4} ${styles.text_primary} `}>{"KMS " + i.unique_id}</span>
                    </div>
                    <table className={`${styles.w_100} ${styles.opacity_70} ${styles.mb_2} ${styles.fs_12}`}>
                      <tbody>
                        <tr>
                          {i.kulam === "0" || i.kulam === "" || i.kulam === "kulam" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                            <span>குலம்:</span></td>
                            <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>  {getDetails(Kulam, i.kulam)}</td></>}
                          {i.birthyear === "" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                            <span>பிறந்த நாள்:</span></td>
                            <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}> <i className="fa fa-birthday-cake fa-1x"></i>{" "}
                              {getAge(i.birthyear + "-" + i.birthmonth + "-" + i.birthdate)} {"வருடம்"}</td></>}
                        </tr>
                        <tr>
                          {i.height === "0" || i.height === "" || i.height === "height" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                            <span>உயரம்:</span></td>
                            <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>   <i className="fa fa-ruler-vertical fa-1x"></i>   {getDetails(Height, i.height)}</td></>}
                          {i.weight === "0" || i.weight === "" || i.weight === "weight" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                            <span>எடை:</span></td>
                            <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>  <i className="fa fa-weight fa-1x"></i> {getDetails(Weight, i.weight)}</td></>}
                        </tr>
                        <tr>
                          {i.natchatram === "0" || i.natchatram === "" || i.natchatram === "natchatram" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                            <span>நட்சத்திரம்:</span></td>
                            <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>  <i className="fa fa-star fa-1x"></i>{" "}
                              {getDetails(Natchatram, i.natchatram)}</td></>}
                          {i.education === "education" || i.weight === "" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                            <span>படிப்பு:</span></td>
                            <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>   <i className="fa fa-book fa-1x"></i> {i.education}</td></>}
                        </tr>
                        <tr>
                          <td className={`${styles.py_1} ${styles.w_25}`}>
                            <span style={{ color: userProfile.dosam === i.dosam ? 'green' : 'red' }}>தோஷ பொருத்தம்:</span></td>
                          <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`} style={{ color: userProfile.dosam === i.dosam ? 'green' : 'red' }}>
                            {userProfile.dosam === i.dosam ? "உண்டு" : "இல்லை"}</td>
                        </tr>
                        <tr>
                          <td className={`${styles.py_1} ${styles.w_25}`}>
                            <span>நட்சத்திர பொருத்தம்:</span></td>
                          <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>
                            <StarRating className={styles.star_rating} value={getStarValue(userProfile.gender, userProfile.natchatram, i.natchatram)} />({getStarValue(userProfile.gender, userProfile.natchatram, i.natchatram)}/12)
                          </td>
                          <td className={`${styles.py_1} ${styles.w_25}`}>
                            <span></span></td>
                        </tr>
                      </tbody>
                    </table>
                    <div className={`${styles.row} ${styles.gutters_5}`}>
                      <div className={styles.col} id={i.auth_id} onClick={() => actionViewProfile(i.auth_id)}>
                        <div style={{ textAlign: 'center' }} name="View Profile" id={i.auth_id} className={`${styles.text_reset} ${styles.c_pointer}`}>
                          <AiOutlineUser name="View Profile" id={i.auth_id} className={`fa-thin fa-user ${styles.fs_20} ${styles.text_primary}`} />
                          <span id={i.auth_id} className={`${styles.d_block} ${styles.fs_10} ${styles.opacity_60}`}>Full Profile</span>
                        </div>
                      </div>
                      <div className={styles.col}>
                        <div style={{ textAlign: 'center' }} name={i.accepted === 0 ? "Requested" : i.accepted === 1 ? "Accepted" : "Sent Intrest"} id={i.auth_id} className={`${styles.text_reset} ${styles.c_pointer}`}>
                          {data.clickedBtn == i.auth_id && hasLoading ?
                            <div class="bg-transparent" type="button" disabled>
                              <span class="spinner-grow spinner-grow-sm bg-danger border-0" role="status" aria-hidden="true"></span>
                            </div> :
                            i.accepted === 0 || data.resentRequestId.includes(i.auth_id) ?
                              <BiGitPullRequest id="Request" className={`fa-thin fa-user ${styles.fs_20} ${styles.text_primary}`} />
                              :
                              i.accepted === 1 ?
                                <AiFillHeart id="Accepted" className={`fa-thin fa-user ${styles.fs_20} ${styles.text_primary}`} />
                                :
                                <AiOutlineHeart id="Sent Interest" onClick={(e) => actionSendInterest(i.auth_id, e)} className={`fa-thin fa-user ${styles.fs_20} ${styles.text_primary}`} />}
                          <span className={`${styles.d_block} ${styles.fs_10} ${styles.opacity_60}`}>{i.accepted === 0 ? "Requested" : i.accepted === 1 ? "Accepted" : "Sent Intrest"}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {data.matchedData.length ?
          <div className={`more-btn-div ${styles.bordersty}`}>
            {/* <button type="button" onClick={actionGetMoreProfile} className="btn btn-block mb-3">More Profile</button> */}
            <button style={{ 'border-radius': '16px' }} type="button" onClick={actionGetMoreProfile} className={styles.butn}><span> More Profile</span></button>
            {/* <button className={`${styles.btn} ${styles.btn_block} ${styles.mb_3}`}>More Profile </button> */}


          </div> : <></>}
      </div> : <Skeleton />
      }
    </div >

  );
}



    // {
    //   /*<div className="intrest-view">
    //                   <div className="row intrest-wrap">
    //                     <div className="col-3 intrest-inside">
    //                       <i className="fa-solid fa-heart ml-n1"></i>
    //                     </div>
    //                     <div className=" intrest-txt">Send Intrest</div>
    //                   </div>
    //                   <div className="row view-to-profile-wrap">
    //                     <div className="col-3 view-to-profile-inside">
    //                       <i className="fa-regular fa-eye"></i>{" "}
    //                     </div>
    //                     <div className="view-to-profile-txt">View Profile</div>
    //                   </div>
    //                 </div> */
    // }
    // }

//   )
// }
