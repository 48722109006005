import axios from "axios";
import { Types } from "../types"
import { getAcceptPasswordReset, getPasswordResetRequests, passwordResetRequest } from "../API";

export const UserPasswordReset = (data) => {
    return (dispatch) => {
        dispatch({ type: Types.USER_RESET_PASSWORD_REQUEST });
        return axios
            .post(passwordResetRequest, data)
            .then((resp) => {
                dispatch({
                    type: Types.ALERT_SUCCESS,
                    payload: { message: resp.data?.message },
                });
                dispatch({ type: Types.USER_RESET_PASSWORD_SUCCESS });
                return resp
            })
            .catch((error) => {
                dispatch({
                    type: Types.ALERT_ERROR,
                    payload: { message: error.response?.data?.message ?? "Try Again Later Some Issue" },
                });
                dispatch({ type: Types.USER_RESET_PASSWORD_ERROR });
                return error.response
            });
    };
};

export const AdminGetPasswordResetRequest = () => {
    return (dispatch) => {
        dispatch({ type: Types.ADMIN_GET_REQUEST_LIST_REQUEST });
        return axios
            .get(getPasswordResetRequests)
            .then((resp) => {
                dispatch({ type: Types.ADMIN_GET_REQUEST_LIST_SUCCESS, payload: resp.data });
                return resp
            })
            .catch((error) => {
                dispatch({ type: Types.ADMIN_GET_REQUEST_LIST_ERROR });
                return error
            });
    };
}
export const AdminAcceptResetRequest = (id) => {
    return (dispatch) => {
        dispatch({ type: Types.ADMIN_ACCEPT_RESET_PASSWORD_REQUEST });
        axios
            .get(`${getAcceptPasswordReset}/${id}`)
            .then((resp) => {
                console.log(resp)
                dispatch({ type: Types.ADMIN_ACCEPT_RESET_PASSWORD_SUCCESS });
                dispatch({
                    type: Types.ALERT_SUCCESS,
                    payload: { message: resp.data?.message },
                });
                dispatch(AdminGetPasswordResetRequest())

            })
            .catch((error) => {
                dispatch({ type: Types.ADMIN_ACCEPT_RESET_PASSWORD_ERROR });
                dispatch({
                    type: Types.ALERT_ERROR,
                    payload: { message: error.response?.data?.message },
                });
            });
    };
}