import { useState } from "react";
import { useNavigate } from "react-router-dom";



export function UploadCertificate() {
    const [certificate, getCertificate] = useState()
    const selectCertificate = (e) => {
        const [file] = e.target.files;
        getCertificate(file)
    }

    return (
        <div>
            <p>UploadCertificate</p>
            <span className="d-grid w-100 h-100">
                <input type="file"
                    onChange={selectCertificate}
                    accept="image/png, image/jpeg" />
                <div className="w-75 h-100">
                    {certificate === undefined ? <></> :
                        <img className="w-25 h-100" src={URL.createObjectURL(certificate)} alt="" />}
                </div>
            </span>
            {certificate === undefined ? <></> : <button>update</button>}

        </div>
    )
} 