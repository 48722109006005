import axios from 'axios';
import { baseProfileUrl } from '../API';
import { Types } from '../types';

export const GetUserProfile = (id) => {
  return (dispatch) => {
    dispatch({ type: Types.VIEW_PROFILE_REQUEST, payload: {} });
    axios
      .get(`${baseProfileUrl}getProfile/${id}`)
      .then((response) => {
        dispatch({ type: Types.VIEW_PROFILE_SUCCESS, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.message } })
      });
  };
}
export const GetUserProfileForMobileNumber = (id) => {
  return (dispatch) => {
    axios
      .get(`${baseProfileUrl}getProfile/${id}`)
      .then((response) => {
        dispatch({ type: Types.VIEW_PROFILE_SUCCESS, payload: response.data });
        dispatch({ type: Types.MOBILE_NUMBER_SUCCESS });
      })
      .catch((err) => {
        dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.message } })
      });
  };
}