import axios from 'axios';
import { baseProfileUrl } from '../API';
import { Types } from '../types';
import { MyInterstedProfile } from '../dashboard/dashboardService';

export const SendIntresetToServer = (id) => {
  return (dispatch) => {
    dispatch({ type: Types.INTEREST_REQUEST });
    axios
      .get(`${baseProfileUrl}sendInterest/${id}`)
      .then((response) => {
        dispatch(MyInterstedProfile());
        dispatch({ type: Types.INTEREST_SUCCESS, payload: { auth_id: Number(id) } });
        dispatch({ type: Types.ALERT_SUCCESS, payload: { message: response?.data?.message } })
      })
      .catch((err) => {
        dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.message } })
        dispatch({ type: Types.INTEREST_ERROR, payload: { message: err.response?.data?.message } })
      });
  };
}
