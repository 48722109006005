import { data } from "jquery";
import { getDetails, Jaathgam } from "../components/utilities/Constants";
import { Selector } from "./selector";
import { variables } from "./var";
import '../form/form.css'

const RasiKattam = [12, 1, 2, 3, 11, 4, 10, 5, 9, 8, 7, 6]
export function CustomTable({
  hasedit = false,
  onchange,
  hasRaasai = true,
  stateData,
  heading,
  innerHeading,
}) {
  var indents = []
  const viewData = (stringData) => {
    let data = "";
    const arrayData = typeof stringData !== 'object' ? stringData?.split(',') : stringData

    arrayData?.map((sData, index) => {
      const num = Number(sData)
      data += sData !== 0 ? (index === 0 ? "" : "/") + getDetails(Jaathgam, num) : ''
    })
    return data
  }
  const viewTable = () => {
    for (var i = 1; i <= 16; i++) {
      if (i === 6 || i === 7 || i === 10 || i === 11) {
        indents.push(<div key={i} className=" col-3 "> <p className={hasRaasai ? 'kattam-style-rasi' : "kattam-style-navamsam"}>{i === 10 ? hasRaasai ? variables.rashi : variables.navaamsam : ""} </p> </div>)
      } else {
        indents.push(
          <div key={i} className={`jathagam-view col-3 d-flex border border-1 border-dark`}>
            <p className="text-kattam">{
              i === 1 ? hasRaasai ? viewData(stateData.r12) : viewData(stateData.n12) :
                i === 2 ? hasRaasai ? viewData(stateData.r1) : viewData(stateData.n1) :
                  i === 3 ? hasRaasai ? viewData(stateData.r2) : viewData(stateData.n2) :
                    i === 4 ? hasRaasai ? viewData(stateData.r3) : viewData(stateData.n3) :
                      i === 5 ? hasRaasai ? viewData(stateData.r11) : viewData(stateData.n11) :
                        i === 8 ? hasRaasai ? viewData(stateData.r4) : viewData(stateData.n4) :
                          i === 9 ? hasRaasai ? viewData(stateData.r10) : viewData(stateData.n10) :
                            i === 12 ? hasRaasai ? viewData(stateData.r5) : viewData(stateData.n5) :
                              i === 13 ? hasRaasai ? viewData(stateData.r9) : viewData(stateData.n9) :
                                i === 14 ? hasRaasai ? viewData(stateData.r8) : viewData(stateData.n8) :
                                  i === 15 ? hasRaasai ? viewData(stateData.r7) : viewData(stateData.n7) :
                                    i === 16 ? hasRaasai ? viewData(stateData.r6) : viewData(stateData.n6) : {}
            }</p>
          </div>
        )
      }
    }
    return indents;
  }
  return (
    <div>
      {!hasedit ?
        <div id='"parent"' className="step3tbl row">
          <div id='l' className="col-lg-3 col-md-3">
            <Selector
              hasTableSelector={true}
              placeholder={"12"}
              isMulti={true}
              optionsData={Jaathgam}
              name={hasRaasai ? "r12" : "n12"}
              valueArray={hasRaasai ? stateData.r12 : stateData.n12}
              onchange={onchange}
            />{" "}
          </div>
          <div id='a' className="col-lg-3 col-md-3 ">
            <Selector
              hasTableSelector={true}
              placeholder={"1"}
              isMulti={true}
              optionsData={Jaathgam}
              name={hasRaasai ? "r1" : "n1"}
              valueArray={hasRaasai ? stateData.r1 : stateData.l1}
              onchange={onchange}
            />
          </div>
          <div id='b' className="col-lg-3 col-md-3 ">
            <Selector
              hasTableSelector={true}
              placeholder={"2"}
              isMulti={true}
              optionsData={Jaathgam}
              name={hasRaasai ? "r2" : "n2"}
              valueArray={hasRaasai ? stateData.r2 : stateData.n2}
              onchange={onchange}
            />
          </div>
          <div id='c' className="col-lg-3 col-md-3 ">
            <Selector
              hasTableSelector={true}
              placeholder={"3"}
              isMulti={true}
              optionsData={Jaathgam}
              name={hasRaasai ? "r3" : "n3"}
              valueArray={hasRaasai ? stateData.r3 : stateData.n3}
              onchange={onchange}
            />{" "}
          </div>
          <div id='k' className="col-lg-3 col-md-3 ">
            <Selector
              hasTableSelector={true}
              placeholder={"11"}
              isMulti={true}
              optionsData={Jaathgam}
              name={hasRaasai ? "r11" : "n11"}
              valueArray={hasRaasai ? stateData.r11 : stateData.n11}
              onchange={onchange}
            />{" "}
          </div>{" "}
          <div className="col-lg-3 col-md-3 "></div>{" "}
          <div className="col-lg-3 col-md-3 "> </div>
          <div id='d' className="col-lg-3 col-md-3 ">
            <Selector
              hasTableSelector={true}
              placeholder={"4"}
              isMulti={true}
              optionsData={Jaathgam}
              name={hasRaasai ? "r4" : "n4"}
              valueArray={hasRaasai ? stateData.r4 : stateData.n4}
              onchange={onchange}
            />{" "}
          </div>
          <div id='j' className="col-lg-3 col-md-3 ">
            <Selector
              hasTableSelector={true}
              placeholder={"10"}
              isMulti={true}
              optionsData={Jaathgam}
              name={hasRaasai ? "r10" : "n10"}
              valueArray={hasRaasai ? stateData.r10 : stateData.n10}
              onchange={onchange}
            />
          </div>{" "}
          <div className="col-lg-3 col-md-3 "></div>{" "}
          <div className="col-lg-3 col-md-3 ">
            {" "}
            <p className="innerStep3">{innerHeading}</p>
          </div>
          <div id='e' className="col-lg-3 col-md-3 ">
            <Selector
              hasTableSelector={true}
              placeholder={"5"}
              isMulti={true}
              optionsData={Jaathgam}
              name={hasRaasai ? "r5" : "n5"}
              valueArray={hasRaasai ? stateData.r5 : stateData.n5}
              onchange={onchange}
            />
          </div>
          <div id='i' className="col-lg-3 col-md-3 ">
            <Selector
              hasTableSelector={true}
              placeholder={"9"}
              isMulti={true}
              optionsData={Jaathgam}
              name={hasRaasai ? "r9" : "n9"}
              valueArray={hasRaasai ? stateData.r9 : stateData.n9}
              onchange={onchange}
            />{" "}
          </div>
          <div id='h' className="col-lg-3 col-md-3 ">
            <Selector
              hasTableSelector={true}
              placeholder={"8"}
              isMulti={true}
              optionsData={Jaathgam}
              name={hasRaasai ? "r8" : "n8"}
              valueArray={hasRaasai ? stateData.r8 : stateData.n8}
              onchange={onchange}
            />{" "}
          </div>
          <div id='g' className="col-lg-3 col-md-3 ">
            <Selector
              hasTableSelector={true}
              placeholder={"7"}
              isMulti={true}
              optionsData={Jaathgam}
              name={hasRaasai ? "r7" : "n7"}
              valueArray={hasRaasai ? stateData.r7 : stateData.n7}
              onchange={onchange}
            />
          </div>
          <div id='f' className="col-lg-3 col-md-3 ">
            <Selector
              hasTableSelector={true}
              placeholder={"6"}
              isMulti={true}
              optionsData={Jaathgam}
              name={hasRaasai ? "r6" : "n6"}
              valueArray={hasRaasai ? stateData.r6 : stateData.n6}
              onchange={onchange}
            />{" "}
          </div>
        </div> : <div className="row">{viewTable()}</div>}
    </div>
  );
}

