import axios from "axios";
import { baseProfileUrl, getMyProfileUrl, getProfileUrl, getProfileUrlforAdmin } from "../API";
import { Types } from "../types";


//self
export function GetUserData(id, user) {
  return (dispatch) => {
    dispatch({ type: Types.DASHBOARD_USERDATA_REQUEST })
    axios
      .get(user === "USER" ? getMyProfileUrl : `${user === "ADMIN" ? getProfileUrlforAdmin : getProfileUrl}/${id}`)
      .then((response) => {
        if (user === "ADMIN") {
          dispatch({ type: Types.VIEW_PROFILE_SUCCESS, payload: response.data })
        } else {
          dispatch({ type: Types.DASHBOARD_USERDATA, payload: response.data })
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: Types.DASHBOARD_USERDATA_ERROR })
        // dispatch({ type: Types.DASHBOARD_USERDATA_ERROR, payload: true })
        dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.message } })
      });
  };
}

//matched
export function GetMachedProfile(hasGetFullData = false) {
  return (dispatch) => {
    axios
      .get(`${baseProfileUrl}${hasGetFullData ? "getMatchAll" : "getMatch"}`)
      .then((response) => {
        dispatch({ type: hasGetFullData ? Types.DASHBOARD_MATCH_ALL_DATA : Types.DASHBOARD_MATCHDATA, payload: response.data });
        // dispatch({ type: Types.DASHBOARD_MATCHDATA, payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        //  dispatch({type: Types.ALERT_ERROR, payload:{ message: err.response?.data?.message }})
      });
  };
}

//viewed
export function GetViewedProfile(hasGetFullData = false) {
  return (dispatch) => {
    axios
      .get(
        `${baseProfileUrl}${hasGetFullData ? "getViewedProfilesAll" : "getViewedProfiles"
        }`
      )
      .then((response) => {
        dispatch({ type: Types.DASHBOARD_VIEWED, payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        // dispatch({type: Types.ALERT_ERROR, payload:{ message: err.response?.data?.message }})
      });
  };
}

//request
export const ComingRequestedProfile = (hasGetFullData = false) => {
  return (dispatch) => {
    axios
      .get(
        `${baseProfileUrl}${"viewInterestForMyProfile"}`
      )
      .then((response) => {
        dispatch({
          type: hasGetFullData ? Types.DASHBOARD_REQUESTEDDATA_ALL : Types.DASHBOARD_REQUESTEDDATA,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

//Interst
export const MyInterstedProfile = (hasGetFullData = false) => {
  return (dispatch) => {
    dispatch({ type: Types.DASHBOARD_INTERESTEDDATA_ALL_REQUESR })
    axios
      .get(
        `${baseProfileUrl}${hasGetFullData ? "getMyInterestedAll" : "getMyInterested"
        }`
      )
      .then((response) => {
        dispatch({
          type: hasGetFullData ? Types.DASHBOARD_INTERESTEDDATA_ALL : Types.DASHBOARD_INTERESTEDDATA,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({ type: Types.DASHBOARD_INTERESTEDDATA_ALL_ERROR })
        console.log(err);
      });
  };
};

// resent
export const GetResentAddedData = () => {
  const resentAdded = `${baseProfileUrl}getRecentProfiles`;
  return (dispatch) => {
    dispatch({ type: Types.DASHBOARD_RESENTADDED_REQUESR });
    axios
      .get(resentAdded)
      .then((response) => {
        dispatch({ type: Types.DASHBOARD_RESENTADDED, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: Types.DASHBOARD_RESENTADDED_ERROR });
        // dispatch({type: Types.ALERT_ERROR, payload:{ message: err.response?.data?.message }})
      });
  };
};
