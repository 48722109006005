import { Skeleton } from "./skeleton"

export const SkeletonPage = () => {
    return (
        <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
        </div>
    )
}