import "./btn.css";


export function Btn({ name = "", action, iconName = "", id = 0, value }) {
        const mouseEnter = () => {
                console.log("Enter Button")
        }
        const mouseLeave = () => {
                console.log("leave Button")
        }
        return (
                <div className={
                        name === "Sent Intrest" ? "main-div sentintrest-main " :
                                name === "Accepted" ? "main-div accept-main " :
                                        name === "Requested" ? "main-div request-main " :
                                                name === "View Profile" ? "main-div viewprofile-main " :
                                                        name === "Print" ? "main-div print-main " :
                                                                name === "Accept" ? "main-div accept-main" : ""}>
                        <i className={"icon-style " + iconName} ></i>
                        <button onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} value={value} id={id} className={
                                name === "Sent Intrest" ? "btn-style sentintrest-btn " :
                                        name === "Accepted" ? "btn-style accepted-btn " :
                                                name === "Requested" ? "btn-style request-btn " :
                                                        name === "View Profile" ? "btn-style viewprofile-btn " :
                                                                name === "Print" ? "btn-style print-btn" :
                                                                        name === "Accept" ? "btn-style accept-btn " : ""} onClick={action}>  &nbsp; {name}
                        </button>
                </div>
        )
}

export function ArrowBtn({ action, title = "", id = "" }) {
        return (<div className="arrow-main">
                <i id={id} className="fas fa-arrow-right fa-lg arrow-btn" onClick={action}></i>
        </div>)
}
