import React from "react";

export default function Photos() {
  return (
    <div>
      <div className="w-50 h-50 text-center m-auto">
        <img
          className="w-50 h-50"
          src="https://firebasestorage.googleapis.com/v0/b/billing-a8bed.appspot.com/o/gallery%2Fw1d52?alt=media&token=1a862efe-8e94-4152-83b1-8e30959f9dcb"
          alt="KMS"
        />
      </div>
    </div>
  );
}
