import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { AcceptDeleteRequeste, RejectDeleteRequeste, getDeleteRequests } from '../store/deleteRequest/deleteRequestService';
import { useDispatch } from 'react-redux';
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.css";
import { AgGridReact } from 'ag-grid-react';
import { SkeletonPage } from '../components/utilities/skeletonPage';
import { useNavigate } from 'react-router-dom';
import { PopUp } from '../popup/popup';



export default function DeletingRequestComp() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { loading } = useSelector((state) => state.admin);
    const { Deleteddata } = useSelector((state) => state.getDeleteUser);
    const [deleteid, setDeleteid] = useState([]);
    const [authId, setAuthId] = useState();
    const [userDataUpdate, setUserDataUpdate] = useState(Deleteddata);
    const [confirmation, setConfirmation] = useState(false);
    const [columnDefs, setColumnDefs] = useState({
        columnDefs: [
            { headerName: "No", field: "No", sortable: true, unSortIcon: true, width: 30, },
            { headerName: "Created", field: "created", sortable: true, unSortIcon: true, width: 140 },
            { headerName: "Name", field: "name", sortable: true, unSortIcon: true, width: 200 },
            { headerName: "Mobile", field: "mobile", sortable: true, unSortIcon: true, width: 180 },
            { headerName: "Reason", field: "reason", sortable: true, unSortIcon: true, width: 450 },
            {
                headerName: "View", sortable: false, filter: false, cellStyle: { borderColor: "transparent" },
                cellRendererFramework: (params) => {
                    return <button className=" btn btn-success" onClick={() => handleClick(params.data)}>
                        <i className="fa fa-eye mb-1"></i></button>
                }, width: 100
            }, {
                headerName: "Delete", sortable: false, filter: false, cellStyle: { borderColor: "transparent" },
                cellRendererFramework: (params) => {
                    return <button className=" btn btn-danger" onClick={() => deleteHandile(params.data)}>

                        <i className="fa fa-trash mb-1"></i>
                    </button>
                }, width: 100
            },],
        rowData: [],
        searchValue: "",
        searchRowdata: [],
    })

    useEffect(() => {
        dispatch(getDeleteRequests())
    }, []);


    useEffect(() => {
        setDeleteid(Deleteddata);
    }, [Deleteddata]);

    useEffect(() => {
        if (Deleteddata) {
            setUserDataUpdate(Deleteddata);
        }
    }, [Deleteddata]);

    const deleteHandile = (data) => {
        setAuthId(data.auth_id);
        setConfirmation(true);
    }

    const actiondeletingRequest = () => {

        if (authId) {
            dispatch(AcceptDeleteRequeste(authId));
            setConfirmation(!confirmation)
            setAuthId();
            pageReload()

        }
    }
    const pageReload = () => {
        setTimeout(() => {
            window.location.reload(false);
        }, 3000);

    }
    const rejectDeleteRequeste = () => {

        if (authId) {
            dispatch(RejectDeleteRequeste(authId));
            setConfirmation(!confirmation)
            setAuthId();
            pageReload();

        }
    }

    useEffect(() => {
        let allData = [];
        if (userDataUpdate.length) {
            userDataUpdate?.map((item, index) => {
                return (allData.push({
                    No: index + 1,
                    auth_id: item.auth_id,
                    created: new Date(parseInt(item.created)).toLocaleDateString(),
                    name: item.name,
                    father: item.father ?? "-",
                    reason: item?.reason,
                    mobile: item.cmobile ?? "-",
                    paid: item.amount ? (item.noacc - item.premiumcount) : "-",
                    status: item.married === 0 ? "Single" : "Married",
                    amount: item.amount ?? "-"
                }))
            })
            setColumnDefs(
                {
                    ...columnDefs,
                    rowData: allData,
                    searchRowdata: allData
                });
        }

    }, [userDataUpdate])

    const searchHandler = (e) => {
        let data = columnDefs.searchRowdata.filter((item) => {
            if (item?.name) {
                return Object.keys(item).some(key =>
                    item[key]?.toString().toLowerCase().includes(e.target.value)
                );
            }
        })
        setColumnDefs(
            {
                ...columnDefs,
                searchValue: e.target.value,
                rowData: data,
            });
    }

    const handleClick = (item) => {
        console.log('`````````item', item);
        navigate(`/profile/${item.auth_id}`, { state: { id: item.auth_id } });

    }
    return (
        <div
            className="ag-theme-alpine col-lg-12"
            style={{
                height: "530px",
            }}
        >
            {loading ?
                <SkeletonPage /> :
                <div
                    className="ag-theme-alpine col-lg-12"
                    style={{
                        height: "530px",
                    }}
                >
                    <h5 className="font-weight-bold mt-3">User Delete Request - நீக்கப்படவேண்டிய கணக்குகள்</h5>
                    <hr />
                    <div className="input-group w-50 mb-2">
                        <input className="form-control py-2 mr-1 pr-5 rounded" placeholder="Search..." type="search" onChange={(e) => searchHandler(e)} value={columnDefs.searchValue} />
                        <span className="input-group-append">
                            <button className="btn rounded-pill border-0 ml-n5 pr-5" type="button">
                                <i className="fa fa-search bg-transparent"></i>
                            </button>
                        </span>
                    </div>
                    <AgGridReact
                        columnDefs={columnDefs.columnDefs}
                        rowData={columnDefs.rowData}
                        pagination={true}
                        paginationAutoPageSize={true}
                        rowHeight={50}
                    ></AgGridReact>

                    <hr />
                </div>
            }
            {confirmation ? <PopUp title={'Delete Account'} actionClose={() => setConfirmation(false)} hasPopUp={confirmation}>
                <div className="modal-body">
                    <h4>Do you want to delete this Account?</h4>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={() => setConfirmation(false)} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary"
                        onClick={rejectDeleteRequeste}
                    > Reject
                    </button>
                    <button type="button" className="btn btn-danger"
                        onClick={actiondeletingRequest}
                    > Delete
                    </button>
                </div>
            </PopUp> : <></>}
        </div>
    )
}
