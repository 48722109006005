import axios from "axios";
import { Types } from "../types";
import { getAllUsers, loginUrl } from "../API";


export const UserLogin = (data) => {
  return (dispatch) => {
    dispatch({ type: Types.LOGIN_REQUEST });
    axios
      .post(loginUrl, data)
      .then((resp) => {
        if (resp.data.role === "ADMIN") {
          localStorage.setItem("mat.token", resp.data.token);
          dispatch({ type: Types.LOGIN_SUCCESS, payload: resp.data });
          dispatch({ type: Types.ADMIN_LOGIN_SUCCESS, payload: resp.data.role });
          dispatch(GetAllUser())
        } else {
          localStorage.setItem("mat.token", resp.data.token);
          dispatch({ type: Types.LOGIN_SUCCESS, payload: resp.data });
        }
      })
      .catch((error) => {
        dispatch({
          type: Types.ALERT_ERROR,
          payload: { message: error.response?.data?.message ?? "Try Again Later Some Issue" },
        });
        dispatch({ type: Types.LOGIN_ERROR });
      });
  };
};

export const UserLogout = () => {
  return (dispatch) => {
    localStorage.removeItem("mat.token");
    dispatch({ type: Types.USER_LOGOUT, payload: {} });
    dispatch({
      type: Types.ALERT_ERROR,
      payload: { message: "Logged Out", duration: 1000 },
    });
  };
};

export const GetAllUser = () => {
  return (dispatch) => {
    dispatch({ type: Types.ADMIN_LOGIN_REQUEST })
    axios
      .get(getAllUsers)
      .then((response) => {
        dispatch({ type: Types.ADMIN_USERDATA_ALL, payload: response.data })
      })
      .catch((err) => {
        console.log(err);
        // dispatch({type: Types.ALERT_ERROR, payload:{ message: err.response?.data?.message }})
      });
  };
}