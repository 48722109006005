import { Input } from "../../form/input";
import { useState } from "react";
import { CustomTable } from "../../form/table";
import { TextArea } from "../../form/textArea";
import Select from "../../form/select";
import { DateSelector } from "../../form/dateSelect";


export function EditComp({ headLine = "", userData, data = [], hasTable, colLength = "col-sm-4", isHideEditBtn = false
  , actionInputChange, actionSelectorChange, actionUpdate }) {
  const [hasEdit, actionEditInit] = useState(false);
  const actionEdit = () => {
    if (hasEdit === true) {
      actionEditInit(false);
      actionUpdate()
    } else {
      actionEditInit(true);
    }

  };
  return (
    <div>
      <div className="row">
        <div className="col-sm-8">{headLine === "" ? <></> : <h5 className="head-line-css ml-2 mt-2">{headLine}</h5>} </div>
        <div className="col-sm-4"> {isHideEditBtn ? <></> : <button className={hasEdit ? "basic-self-done-btn" : "basic-self-edit-btn"} onClick={actionEdit}>
          {hasEdit ? "Update" : "Edit"}
        </button>} </div>
      </div>
      <div className=" row profile-data-div mb-1">
        {hasTable ?
          <div className={`w-75 container mt-2 ${hasEdit ? "d-flex flex-wrap" : " ml-10 "} navam`}>
            <div className="row navam_row">
              <div className="col">
                <CustomTable stateData={userData} hasedit={!hasEdit} hasRaasai={true} onchange={actionSelectorChange} />
              </div>
            </div>
            <div className="row navam_row">
              <div className="col ">
                <CustomTable stateData={userData} hasedit={!hasEdit} hasRaasai={false} onchange={actionSelectorChange} />
              </div>
            </div>
          </div>

          : data?.map((i) => {
            return (
              <div className={colLength + " mb-1 mt-2 p-r-0"}>
                <div className="data-div">
                  <div className="i-con-div">
                    <i className={i.icon}></i>
                  </div>
                  <div className="dtl-div">
                    <h6 className="title-div">{i.title}</h6>
                    <div className="edit-data">

                      {hasEdit ?
                        !i.isEdit ? (i.hasInput ? i.type === "textArea" ? <TextArea onchage={actionInputChange} value={i.value} name={i.name} />
                          :
                          <div className="mb-1">
                            <Input
                              value={i.value}
                              name={i.name}
                              onchange={actionInputChange}
                            />
                          </div>
                          : i.type === "select" ? <div className="mb-1">
                            <DateSelector
                              data={i.editingData}
                              action={actionInputChange} />
                          </div> :
                            <div className="mb-1">
                              <Select
                                options={i.optionData}
                                value={i.value}
                                name={i.name}
                                onchange={actionInputChange}

                              />
                            </div>
                        ) : (<p className="name-div">
                          {i.hasInput ? i.value === "" ? "-" : i.value : i.displayValue === "" ? "-" : i.displayValue}
                        </p>) : <p className="name-div">
                          {i.hasInput ? i.value === "" ? "-" : i.value : i.displayValue === "" ? "-" : i.displayValue}
                        </p>
                      }
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
}
// 