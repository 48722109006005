import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { AdminAcceptResetRequest, AdminGetPasswordResetRequest } from "../store/passwordReset/resetService";
import { AgGridReact } from "ag-grid-react";
import { SkeletonPage } from "../components/utilities/skeletonPage";
import { getProfileUrlforAdmin } from "../store/API";
import { Types } from "../store/types";
import axios from "axios";
import { Popup } from "semantic-ui-react";
import { PopUp } from "../popup/popup";

export default function ResetPasswordPage() {

    const { requestList, requestListLoading } = useSelector((state) => state.passwordReset);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [resetRequest, setResetRequest] = useState(false)
    const [id, setId] = useState()
    const [columnDefs, setColumnDefs] = useState({
        columnDefs: [
            { headerName: "No", field: "No", sortable: true, unSortIcon: true, },
            { headerName: "Created", field: "created", sortable: true, unSortIcon: true },
            { headerName: "Name", field: "name", sortable: true, unSortIcon: true, },
            { headerName: "Mobile", field: "mobile", sortable: true, unSortIcon: true, },
            {
                headerName: "View", sortable: false, filter: false, cellStyle: { borderColor: "transparent" },
                cellRendererFramework: (params) => {
                    return <button className=" btn btn-success" onClick={() => { handleClick(params.data) }}>
                        <i className="fa fa-eye mb-1"></i></button>
                },
            }, {
                headerName: "Reset", sortable: false, filter: false, cellStyle: { borderColor: "transparent" },
                cellRendererFramework: (params) => {
                    return <button className=" btn btn-primary" onClick={() => { actionRequest(params.data) }}>
                        <i className="fa fa-check mb-1"></i> accept
                    </button>
                }
            },],
        rowData: [],
        searchValue: "",
        searchRowdata: [],
    })

    useEffect(() => {
        dispatch(AdminGetPasswordResetRequest());
    }, []);

    useEffect(() => {
        let allData = [];
        if (requestList?.length) {
            requestList?.map((item, index) => {
                return (allData.push({
                    No: index + 1,
                    auth_id: item.auth_id,
                    created: new Date(parseInt(item.created)).toLocaleDateString(),
                    name: item.name,
                    mobile: item.cmobile ?? "-",
                }))
            })
            setColumnDefs(
                {
                    ...columnDefs,
                    rowData: allData,
                    searchRowdata: allData
                });
        } else {
            setColumnDefs(
                {
                    ...columnDefs,
                    rowData: allData,
                    searchRowdata: allData
                });
        }

    }, [requestList])

    const actionRequest = (para) => {
        setResetRequest(!resetRequest)
        setId(para.auth_id)

    }
    console.log('resetRequest', resetRequest);

    const actionRequestAccept = () => {
        dispatch(AdminAcceptResetRequest(id))
        setId();
        setResetRequest(!resetRequest)
    }
    const actionClose = () => {
        setResetRequest(!resetRequest)
    }

    const searchHandler = (e) => {
        let data = columnDefs.searchRowdata.filter((item) => {
            if (item?.name) {
                return Object.keys(item).some(key =>
                    item[key]?.toString().toLowerCase().includes(e.target.value)
                );
            }
        })
        setColumnDefs(
            {
                ...columnDefs,
                searchValue: e.target.value,
                rowData: data,
            });
    }

    const handleClick = (item) => {
        navigate(`/profile/${item.auth_id}`, { state: { id: item.auth_id } });
        dispatch({ type: Types.VIEW_PROFILE_REQUEST });
        axios.get(`${getProfileUrlforAdmin}/${item.auth_id}`)
            .then((response) => {
                dispatch({ type: Types.VIEW_PROFILE_SUCCESS, payload: response.data });
            })
            .catch((err) => {
                dispatch({ type: Types.VIEW_PROFILE_ERROR });
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.message } })
            })
    }

    return (
        <div
            className="ag-theme-alpine col-lg-12"
            style={{
                height: "530px",
            }}
        >
            {requestListLoading ?
                <SkeletonPage /> :
                <div
                    className="ag-theme-alpine col-lg-12"
                    style={{
                        height: "720px",
                    }}
                >
                    <h5 className="font-weight-bold mt-3">Users List - உறுப்பினர்கள் பட்டியல்</h5>
                    <hr />
                    <div className="input-group w-50 mb-2">
                        <input className="form-control py-2 mr-1 pr-5 rounded" placeholder="Search..." type="search" onChange={(e) => searchHandler(e)} value={columnDefs.searchValue} />
                        <span className="input-group-append">
                            <button className="btn rounded-pill border-0 ml-n5 pr-5" type="button">
                                <i className="fa fa-search bg-transparent"></i>
                            </button>
                        </span>
                    </div>
                    <AgGridReact
                        columnDefs={columnDefs.columnDefs}
                        rowData={columnDefs.rowData}
                        // onGridReady={onGridReady}
                        pagination={true}
                        paginationPageSize={20}
                        rowHeight={50}
                    // onPaginationChanged={actionPagenumber}

                    ></AgGridReact>

                    <hr />

                </div>
            }
            {/* {resetRequest ? <Popup title={'Password Reset'} actionClose={actionClose} >
                <div className="modal-body">
                    <Input label={"What are reasons to delete account?"} onchange={actiondeletingReasons} value={deletingReasons} />
                    <div></div>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={() => actionClose()} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" onClick={actionRequestAccept} > Accept
                    </button>
                </div>
            </Popup> : <></>} */}
            {
                resetRequest ? <PopUp title="Password Reset" actionClose={actionClose} hasPopUp={resetRequest}>
                    < div className="modal-body" >
                        <div style={{ 'color': 'black', 'font-size': '16px' }}> Are you sure, You want to Reset Password? </div>
                    </div >
                    <div className="modal-footer">
                        <button type="button" onClick={() => actionClose()} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={actionRequestAccept}> Reset </button>
                    </div>
                </PopUp > : <></>
            }
        </div>
    )
}