import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Bloodgroup,
  Color,
  Days,
  Districts,
  Height,
  Hour,
  House,
  Kulam,
  Minute,
  Month,
  Numbers,
  Planets,
  Raasi,
  Time,
  Weight,
} from "../../components/utilities/Constants";
import { Input } from "../../form/input";
import Select from "../../form/select";
import { TextArea } from "../../form/textArea";
import { variables } from "../../form/var";
import { RegisterTwo, UserRegister } from "../../store/register/registerService";
import RegisterStep3 from "./registerStep3";
import { Types } from "../../store/types";
import { UplodeAndEditImage } from "../../store/imgUplode/imguplodeService";

export default function RegisterStep2() {
  var { register } = useSelector((state) => state.register);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [update, registerUpdate] = useState(register)

  useEffect(() => {
    registerUpdate(register)
  }, [register]);

  const inputUpdateFiled = (e) => {
    registerUpdate({
      ...update,
      [e.target.name]: e.target.value,
    });
  };

  const actionSkipToNext = () => {
    navigate("/register/step3");
  };

  const actionSubmit = (e) => {
    e.preventDefault();
    dispatch(RegisterTwo(update))
    navigate("/register/step3");
  };


  return (
    <div className="step1-parent-wrap">
      <div className="step2warp">
        <form noValidate={false}>
          <div className="jumbotron row-css  margin-md">
            <p className="h4 mt-2 titl-color">சுய விவரம்</p>
            <div className="row seperat p-md-4 mt-2">
              <div className="form-group col-md-3">
                <Select
                  label={variables.bloodGroup}
                  options={Bloodgroup}
                  value={update.bloodgroup}
                  name={"bloodgroup"}
                  onchange={inputUpdateFiled} />
              </div>
              <div className="col-md-3 form-group col-md-4 ">
                <Select
                  label={variables.dist}
                  options={Districts[1]}
                  value={update.district}
                  name={"district"}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group">
                <Select
                  label={variables.height}
                  options={Height}
                  value={update.height}
                  name={"height"}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group col-md-4">
                <Select
                  label={variables.weight}
                  options={Weight}
                  value={update.weight}
                  name={"weight"}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group ">
                <Select
                  label={variables.color}
                  options={Color}
                  value={update.color}
                  name={"color"}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="textarea-width padExpDtl">
                <TextArea
                  placeholder={variables.moreInfo}
                  value={update.aboutme}
                  name={"aboutme"}
                  onchage={inputUpdateFiled}
                />
              </div>
            </div>
            <p className="mt-4 mt-md-3 mb-n1 h5 titlMargin titl-color">
              {variables.educationTitle}
            </p>
            <div className="row seperat p-md-4 mt-2 ">
              <div className="col-md-3 form-group col-md-4">
                <Input
                  label={variables.education}
                  placeholder={variables.education}
                  name={"education"}
                  value={update.education}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group col-md-4">
                <Input
                  label={variables.work}
                  placeholder={variables.work}
                  name={"job"}
                  value={update.job}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group col-md-4">
                <Input
                  label={variables.workingPlace}
                  placeholder={variables.workingPlace}
                  name={"joblocation"}
                  value={update.joblocation}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group col-md-4">
                <Input
                  label={variables.income}
                  placeholder={variables.income}
                  name={"income"}
                  value={update.income}
                  onchange={inputUpdateFiled}
                />
              </div>{" "}
              <div className="col-lg-12 form-group col-md-12 padExpDtl">
                <TextArea
                  placeholder={variables.expectationDitails}
                  value={update.aboutexpect}
                  name={"aboutexpect"}
                  onchage={inputUpdateFiled}
                />
              </div>
            </div>
            <p className="mt-4 mb-n1 h5 mt-md-5 titl-color">
              {variables.familyDetails}
            </p>
            <div className="row seperat p-md-4 mt-2 ">
              <div className="col-md-3 form-group col-md-4">
                <Input
                  label={variables.fatherName}
                  placeholder={variables.fatherName}
                  value={update.father}
                  name={"father"}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group col-md-4">
                <Input
                  label={variables.fatherWork}
                  placeholder={variables.fatherWork}
                  value={update.fatherjob}
                  name={"fatherjob"}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group col-md-4">
                <Input
                  label={variables.motherName}
                  placeholder={variables.motherName}
                  value={update.mother}
                  name={"mother"}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group col-md-4">
                <Input
                  label={variables.motherWork}
                  placeholder={variables.motherWork}
                  value={update.motherjob}
                  name={"motherjob"}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group col-md-4 mt-1">
                <Input
                  label={variables.siblings}
                  placeholder={variables.siblings}
                />
              </div>
              <div className="col-md-3 form-group col-md-4">
                <Select
                  label={variables.kulam}
                  options={Kulam}
                  value={update.kulam}
                  name={"kulam"}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group col-md-4 mt-1">
                <Input
                  label={variables.kuladeyvam}
                  placeholder={variables.kuladeyvam}
                  value={update.god}
                  name={"god"}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group col-md-4 mt-1">
                <Input
                  label={variables.templePlace}
                  placeholder={variables.templePlace}
                  value={update.godtemple}
                  name={"godtemple"}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group col-md-4 mt-1">
                <Input
                  label={variables.familyMothlyIncome}
                  placeholder={variables.familyMothlyIncome}
                  name={"familyincome"}
                  value={update.familyincome}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group col-md-4">
                <Select
                  label={variables.livingHouse}
                  options={House}
                  value={update.housetype}
                  name={"housetype"}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group col-md-6 mt-1">
                <Input
                  label={variables.propertyValue}
                  placeholder={variables.propertyValue}
                  name={"asset"}
                  value={update.asset}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-lg-12 form-group col-md-12 padExpDtl">
                <TextArea
                  placeholder={variables.propertyDetails}
                  value={update.aboutasset}
                  name={"aboutasset"}
                  onchage={inputUpdateFiled}
                />
              </div>
            </div>
            <p className="mt-4 mb-n1 h5 mt-md-5 titl-color">
              {variables.jathagamInfo}
            </p>
            <div className="row seperat p-md-4 mt-2 ">
              <div className="col-md-3 form-group col-md-4">
                <Select
                  label={variables.hour}
                  options={Hour}
                  value={update.birthtime}
                  name={"birthtime"}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group col-md-4">
                <Select
                  label={variables.minute}
                  options={Minute}
                  value={update.birthminute}
                  name={"birthminute"}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group col-md-4">
                <Select
                  label={variables.amPm}
                  options={Time}
                  value={update.birthtime}
                  name={"birthtime"}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group">
                <Input
                  label={variables.birthPlace}
                  placeholder={variables.birthPlace}
                  name={"birthlocation"}
                  value={update.birthlocation}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group">
                <Select
                  label={variables.lakkanam}
                  options={Raasi}
                  value={update.laknam}
                  name={"laknam"}
                  onchange={inputUpdateFiled}
                  requerd={true}
                />
              </div>
              <div className="col-md-3 form-group ">
                <Select
                  label={variables.dhishaVarudam}
                  options={Numbers}
                  value={update.disayear}
                  name={"disayear"}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group">
                <Select
                  label={variables.dhishaMadham}
                  options={Month}
                  value={update.disamonth}
                  name={"disamonth"}
                  onchange={inputUpdateFiled}
                />
              </div>
              <div className="col-md-3 form-group col-md-3">
                <Select
                  label={variables.dhishaNaal}
                  options={Days}
                  value={update.disadate}
                  name={"disadate"}
                  onchange={inputUpdateFiled}
                />
              </div>{" "}
              <div className="col-md-3 m-auto form-group col-md-4">
                <Select
                  label={variables.dhishaErupu}
                  options={Planets}
                  value={update.disa}
                  name={"disa"}
                  onchange={inputUpdateFiled}
                />
              </div>
            </div>
            <div className="btnGroup">
              {" "}
              <button
                onClick={actionSkipToNext}
                type="button"
                className=" skipBtn"
              >
                Skip
              </button>
              <button onClick={actionSubmit} type="submit" className="nextBtn">
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
