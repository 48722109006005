import { Required } from "../components/utilities/required";

export function Input({
  value,
  label,
  placeholder,
  name,
  onchange,
  type = "text",
  required = false,
  hasPic = false,
  error = null,
  maxLength,
  minLength,
  pattern
}) {
  return (
    <div className="form-group">
      <div className="form-row">
        <label
          for="validationDefault"
          className="form-label whitespace-no-wrap"
        >
          {label}
          {required ? <Required /> : <></>}
          {/* {error ? <span className='text-danger floatRight'>{error}</span> : null} */}
        </label>
      </div>
      <div className="form-row input-group inpt-hit inpt-css">
        <input
          type={type}
          name={name}
          value={value}
          onChange={onchange}
          className={error ? "form-control is-invalid" : "form-control"}
          placeholder={placeholder}
          id="validationDefault"
          aria-describedby="inputGroupPrepend2"
          maxLength={maxLength}
          minLength={minLength}
          pattern={pattern}
        />
      </div>
    </div>
  );
}
