const Gender = [
    { label: 'ஆண்', value: 1 },
    { label: 'பெண்', value: 2 },
]

const Register = [
    { label: 'ஜாதகர்', value: 1 },
    { label: 'உடன்பிறந்தவர்', value: 2 },
    { label: 'பெற்றோர்', value: 3 },
    { label: 'உறவினர்', value: 4 },
    { label: 'நண்பர்கள்', value: 5 },
]

const Jaathgam = [
    { label: 'குரு', value: 1 },
    { label: 'புத', value: 2 },
    { label: 'ரா', value: 3 },
    { label: 'கே', value: 4 },
    { label: 'சந்', value: 5 },
    { label: 'சுக்', value: 6 },
    { label: 'செ', value: 7 },
    { label: 'லக்', value: 8 },
    { label: 'சனி', value: 9 },
    { label: 'மா', value: 10 },
    { label: 'சூரி', value: 11 },
]

const Age = [
    { label: '18', value: 18 },
    { label: '19', value: 19 },
    { label: '20', value: 20 },
    { label: '21', value: 21 },
    { label: '22', value: 22 },
    { label: '23', value: 23 },
    { label: '24', value: 24 },
    { label: '25', value: 25 },
    { label: '26', value: 26 },
    { label: '27', value: 27 },
    { label: '28', value: 28 },
    { label: '29', value: 29 },
    { label: '30', value: 30 },
    { label: '31', value: 31 },
    { label: '32', value: 32 },
    { label: '33', value: 33 },
    { label: '34', value: 34 },
    { label: '35', value: 35 },
    { label: '36', value: 36 },
    { label: '37', value: 37 },
    { label: '38', value: 38 },
    { label: '39', value: 39 },
    { label: '40', value: 40 },
    { label: '41', value: 41 },
    { label: '42', value: 42 },
    { label: '43', value: 43 },
    { label: '44', value: 44 },
    { label: '45', value: 45 },
    { label: '46', value: 46 },
    { label: '47', value: 47 },
    { label: '48', value: 48 },
    { label: '49', value: 49 },
    { label: '50', value: 50 },
    { label: '51', value: 51 },
    { label: '52', value: 52 },
    { label: '53', value: 53 },
    { label: '54', value: 54 },
    { label: '55', value: 55 },
    { label: '56', value: 56 },
    { label: '57', value: 57 },
    { label: '58', value: 58 },
    { label: '59', value: 59 },
    { label: '60', value: 60 },
]

const Numbers = [
    { label: '0', value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
    { label: '11', value: 11 },
    { label: '12', value: 12 },
    { label: '13', value: 13 },
    { label: '14', value: 14 },
    { label: '15', value: 15 },
    { label: '16', value: 16 },
    { label: '17', value: 17 },
    { label: '18', value: 18 },
    { label: '19', value: 19 },
    { label: '20', value: 20 },
    { label: '21', value: 21 },
    { label: '22', value: 22 },
    { label: '23', value: 23 },
    { label: '24', value: 24 },
    { label: '25', value: 25 }
]

const Days = [
    { label: '0', value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
    { label: '11', value: 11 },
    { label: '12', value: 12 },
    { label: '13', value: 13 },
    { label: '14', value: 14 },
    { label: '15', value: 15 },
    { label: '16', value: 16 },
    { label: '17', value: 17 },
    { label: '18', value: 18 },
    { label: '19', value: 19 },
    { label: '20', value: 20 },
    { label: '21', value: 21 },
    { label: '22', value: 22 },
    { label: '23', value: 23 },
    { label: '24', value: 24 },
    { label: '25', value: 25 },
    { label: '26', value: 26 },
    { label: '27', value: 27 },
    { label: '28', value: 28 },
    { label: '29', value: 29 },
    { label: '30', value: 30 },
    { label: '31', value: 31 },
    { label: '32', value: 32 }
]

const Month = [
    { label: '0', value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
    { label: '11', value: 11 },
    { label: '12', value: 12 },
]

const Education = [
    { value: 0, label: ' ' },
    { value: 1, label: 'Aeronautical Engineering' },
    { value: 2, label: 'B.Arch' },
    { value: 3, label: 'BCA' },
    { value: 4, label: 'BE' },
    { value: 5, label: 'B.Plan' },
    { value: 6, label: 'B.Sc IT/Computer Science' },
    { value: 7, label: 'B.Tech.' },
    { value: 8, label: 'Other Bachelor Degree in Engineering/Computers' },
    { value: 9, label: 'B.S.(Engineering)' },
    { value: 10, label: 'M.Arch.' },
    { value: 11, label: 'MCA' },
    { value: 12, label: 'ME' },
    { value: 13, label: 'M.Sc.IT/Computer Science' },
    { value: 14, label: 'M.S.(Engg.)' },
    { value: 15, label: 'M.Tech.' },
    { value: 16, label: 'PGDCA' },
    { value: 17, label: 'Other Master Degree in Engineering/Computers' },
    { value: 18, label: 'Aviation Degree' },
    { value: 19, label: 'B.A.' },
    { value: 20, label: 'B.Com.' },
    { value: 21, label: 'B.Ed.' },
    { value: 22, label: 'BFA' },
    { value: 23, label: 'BFT' },
    { value: 24, label: 'BLIS' },
    { value: 25, label: 'B.M.M.' },
    { value: 26, label: 'B.Sc.' },
    { value: 27, label: 'B.S.W' },
    { value: 28, label: 'B.Phil.' },
    { value: 29, label: 'Other Bachelor Degree in Arts/Science/Commerce' },
    { value: 30, label: 'M.A.' },
    { value: 31, label: 'MCom' },
    { value: 32, label: 'M.Ed.' },
    { value: 33, label: 'MFA' },
    { value: 34, label: 'MLIS' },
    { value: 35, label: 'M.Sc.' },
    { value: 36, label: 'MSW' },
    { value: 37, label: 'M.Phil.' },
    { value: 38, label: 'Other Master Degree in Arts/Science/Commerce' },
    { value: 39, label: 'BBA' },
    { value: 40, label: 'BFM(Financial Management)' },
    { value: 41, label: 'BHM(Hotel Management)' },
    { value: 42, label: 'Other Bachelor Degree in Management' },
    { value: 43, label: 'BHA/BHM(Hospital Administration)' },
    { value: 44, label: 'MBA' },
    { value: 45, label: 'MFM(Financial Management)' },
    { value: 46, label: 'MHM(Hotel Management)' },
    { value: 47, label: 'MHRM(Human Resource Management)' },
    { value: 48, label: 'PGDM' },
    { value: 49, label: 'Other Master Degree in Management' },
    { value: 50, label: 'MHA/MHM(Hospital Administration)' },
    { value: 51, label: 'B.A.M.S.' },
    { value: 52, label: 'BDS' },
    { value: 53, label: 'BHMS' },
    { value: 54, label: 'BSMS' },
    { value: 55, label: 'BPharm' },
    { value: 56, label: 'BPT' },
    { value: 57, label: 'BUMS' },
    { value: 58, label: 'BVSc' },
    { value: 59, label: 'MBBS' },
    { value: 60, label: 'B.Sc.Nursing' },
    { value: 61, label: 'Other Bachelor Degree in Medicine' },
    { value: 62, label: 'MDS' },
    { value: 63, label: 'MD/MS(Medical)' },
    { value: 64, label: 'M.Pharm' },
    { value: 65, label: 'MPT' },
    { value: 66, label: 'MVSc' },
    { value: 67, label: 'Other Master Degree in Medicine' },
    { value: 68, label: 'BGL' },
    { value: 69, label: 'B.L.' },
    { value: 70, label: 'LL.B.' },
    { value: 71, label: 'Other Bachelor Degree in Legal' },
    { value: 72, label: 'LL.M.' },
    { value: 73, label: 'M.L.' },
    { value: 74, label: 'Other Master Degree in Legal' },
    { value: 75, label: 'CA' },
    { value: 76, label: 'CFA(Chartered Financial Analyst)' },
    { value: 77, label: 'CS' },
    { value: 78, label: 'ICWA' },
    { value: 79, label: 'Other Degree in Finance' },
    { value: 80, label: 'IAS' },
    { value: 81, label: 'IES' },
    { value: 82, label: 'IFS' },
    { value: 83, label: 'IRS' },
    { value: 84, label: 'IPS' },
    { value: 85, label: 'Other Degree in Service' },
    { value: 86, label: 'Ph.D.' },
    { value: 87, label: 'Diploma' },
    { value: 88, label: 'Polytechnic' },
    { value: 89, label: 'Trade School' },
    { value: 90, label: 'Others in Diploma' },
    { value: 91, label: 'Master of International Business' },
    { value: 92, label: 'Bachelor of Tourism Studies' },
    { value: 93, label: 'Commercial Pilot Licence' },
    { value: 94, label: 'Higher Secondary School/High School' },
]

const Hour = [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
]

const Minute = [
    { value: '00', label: '00' },
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
    { value: '25', label: '25' },
    { value: '26', label: '26' },
    { value: '27', label: '27' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' },
    { value: '31', label: '31' },
    { value: '32', label: '32' },
    { value: '33', label: '33' },
    { value: '34', label: '34' },
    { value: '35', label: '35' },
    { value: '36', label: '36' },
    { value: '37', label: '37' },
    { value: '38', label: '38' },
    { value: '39', label: '39' },
    { value: '40', label: '40' },
    { value: '41', label: '41' },
    { value: '42', label: '42' },
    { value: '43', label: '43' },
    { value: '44', label: '44' },
    { value: '45', label: '45' },
    { value: '46', label: '46' },
    { value: '47', label: '47' },
    { value: '48', label: '48' },
    { value: '49', label: '49' },
    { value: '50', label: '50' },
    { value: '51', label: '51' },
    { value: '52', label: '52' },
    { value: '53', label: '53' },
    { value: '54', label: '54' },
    { value: '55', label: '55' },
    { value: '56', label: '56' },
    { value: '57', label: '57' },
    { value: '58', label: '58' },
    { value: '59', label: '59' },
]

const Time = [
    { value: 1, label: 'AM' },
    { value: 2, label: 'PM' },
]
// const MaritalStatus = [
//     { label: 'Any', value: 1 },
//     { label: 'திருமணம் ஆகாதவர்', value: 2 },
//     { label: 'விதவை', value: 3 },
//     { label: 'விவாகரத்தானவர்', value: 4 },
// ]

// const PhysicalStatus = [
//     { label: 'இயல்பானவர்', value: 1 },
//     { label: 'ஊனமுற்றோர்', value: 2 },
//     { label: 'Doesn\'t Matter', value: 3 },
// ]

const Natchatram = [
    { label: 'அஸ்வினி', value: 1 },
    { label: 'பரணி', value: 2 },
    { label: 'கார்த்திகை 1', value: 3 },
    { label: 'கார்த்திகை 2,3,4', value: 4 },
    { label: 'ரோகினி', value: 5 },
    { label: 'மிருகசிரிஷம் 1, 2', value: 6 },
    { label: 'மிருகசிரிஷம் 3, 4', value: 7 },
    { label: 'திருவாதிரை', value: 8 },
    { label: 'புனர்பூசம் 1,2,3', value: 9 },
    { label: 'புனர்பூசம் 4', value: 10 },
    { label: 'பூசம்', value: 11 },
    { label: 'ஆயில்யம்', value: 12 },
    { label: 'மகம்', value: 13 },
    { label: 'பூரம்', value: 14 },
    { label: 'உத்திரம் 1', value: 15 },
    { label: 'உத்திரம் 2,3,4', value: 16 },
    { label: 'ஹஸ்தம்', value: 17 },
    { label: 'சித்திரை 1, 2', value: 18 },
    { label: 'சித்திரை 3, 4', value: 19 },
    { label: 'சுவாதி', value: 20 },
    { label: 'விசாகம் 1,2,3', value: 21 },
    { label: 'விசாகம் 4', value: 22 },
    { label: 'அனுசம்', value: 23 },
    { label: 'கேட்டை', value: 24 },
    { label: 'மூலம்', value: 25 },
    { label: 'பூராடம்', value: 26 },
    { label: 'உத்திராடம் 1', value: 27 },
    { label: 'உத்திராடம் 2,3,4', value: 28 },
    { label: 'திருவோணம்', value: 29 },
    { label: 'அவிட்டம் 1, 2', value: 30 },
    { label: 'அவிட்டம் 3, 4', value: 31 },
    { label: 'சதயம்', value: 32 },
    { label: 'பூரட்டாதி 1,2,3', value: 33 },
    { label: 'பூரட்டாதி 4', value: 34 },
    { label: 'உத்திரட்டாதி', value: 35 },
    { label: 'ரேவதி', value: 36 },
]

const Kulam = [
    { value: 1, label: 'அக்கினி குலம்' },
    { value: 2, label: 'அந்துவன் குலம்' },
    { value: 3, label: 'அனகன் குலம்' },
    { value: 4, label: 'அழகு குலம்' },
    { value: 5, label: 'அஷரியன் குலம்' },
    { value: 6, label: 'ஆடகுலம்' },
    { value: 7, label: 'ஆடி(எ)ஆடர் குலம்' },
    { value: 8, label: 'ஆதி(எ)ஆதிகுடி குலம்' },
    { value: 9, label: 'ஆதித்தேயன் குலம்' },
    { value: 10, label: 'ஆதினை குலம்' },
    { value: 11, label: 'ஆந்தை குலம்' },
    { value: 12, label: 'ஆலங்குலம்' },
    { value: 13, label: 'ஆவன் குலம்' },
    { value: 14, label: 'இந்திரன் குலம்' },
    { value: 15, label: 'ஈஞ்ச குலம்' },
    { value: 16, label: 'உறுகலன் குலம்' },
    { value: 17, label: 'உழவன் குலம்' },
    { value: 18, label: 'உவணன் குலம்' },
    { value: 19, label: 'எஞ்சன் குலம்' },
    { value: 20, label: 'எண்ணகுலம்' },
    { value: 21, label: 'எண்ணெய் குலம்' },
    { value: 22, label: 'ஏன குலம்' },
    { value: 23, label: 'ஒதியன் குலம்' },
    { value: 24, label: 'ஒழுக்கன் குலம்' },
    { value: 25, label: 'ஓதாளன் குலம்' },
    { value: 26, label: 'கட்செவியன் குலம்' },
    { value: 27, label: 'கணக்கன் குலம்' },
    { value: 28, label: 'கணவளான் குலம்' },
    { value: 29, label: 'கண்ணண் குலம்' },
    { value: 30, label: 'கண்ணந்தை குலம்' },
    { value: 31, label: 'கம்பன் குலம்' },
    { value: 32, label: 'கரிஞ்சி குலம்' },
    { value: 33, label: 'கருங்கண்ணன் குலம்' },
    { value: 34, label: 'கர்டன் குலம்' },
    { value: 35, label: 'கவுரி குலம்' },
    { value: 36, label: 'காடர் குலம்' },
    { value: 37, label: 'காடை குலம்' },
    { value: 38, label: 'காதகுடையர்' },
    { value: 39, label: 'காரி குலம்' },
    { value: 40, label: 'காளன் குலம்' },
    { value: 41, label: 'கிள்ளியன் குலம்' },
    { value: 42, label: 'கீரை குலம்' },
    { value: 43, label: 'குங்கரி குலம்' },
    { value: 44, label: 'குணக்கர் குலம்' },
    { value: 45, label: 'குணியன் குலம்' },
    { value: 46, label: 'குண்டரி குலம்' },
    { value: 47, label: 'குண்டாலி குலம்' },
    { value: 48, label: 'கும்பன் குலம்' },
    { value: 49, label: 'குயிலர் குலம்' },
    { value: 50, label: 'குழாயர் குலம்' },
    { value: 51, label: 'குழையன் குலம்' },
    { value: 52, label: 'கூரை குலம்' },
    { value: 53, label: 'கூறுபன் குலம்' },
    { value: 54, label: 'கொட்டாரம் குலம்' },
    { value: 55, label: 'கோதனு குலம்' },
    { value: 56, label: 'கோதர் குலம்' },
    { value: 57, label: 'கோவர் குலம்' },
    { value: 58, label: 'கோவேந்தர் குலம்' },
    { value: 59, label: 'சங்கர் குலம்' },
    { value: 60, label: 'சனகன் குலம்' },
    { value: 61, label: 'சன் குலம்' },
    { value: 62, label: 'சாத்தந்தை குலம்' },
    { value: 63, label: 'சாத்துவராயன் குலம்' },
    { value: 64, label: 'சாந்த குலம்' },
    { value: 65, label: 'சிலம்பன் குலம்' },
    { value: 66, label: 'சிவன் குலம்' },
    { value: 67, label: 'சுரபி குலம்' },
    { value: 68, label: 'சூரியன் குலம்' },
    { value: 69, label: 'சூலன் குலம்' },
    { value: 70, label: 'செங்கண்ணன் குலம்' },
    { value: 71, label: 'செங்கன்னி குலம்' },
    { value: 72, label: 'செம்ப குலம்' },
    { value: 73, label: 'செம்பூத்தான் குலம்' },
    { value: 74, label: 'செம்பூபொன் குலம்' },
    { value: 75, label: 'செல்லம் குலம்' },
    { value: 76, label: 'செல்வ குலம்' },
    { value: 77, label: 'செல்வன் குலம்' },
    { value: 78, label: 'செவழன் குலம்' },
    { value: 79, label: 'செவ்வாய் குலம்' },
    { value: 80, label: 'சேகர் குலம்' },
    { value: 81, label: 'சேடன் குலம்' },
    { value: 82, label: 'சேரன் குலம்' },
    { value: 83, label: 'சேரலன் குலம்' },
    { value: 84, label: 'சோதி குலம்' },
    { value: 85, label: 'சோமன் குலம்' },
    { value: 86, label: 'தனவந்தன் குலம்' },
    { value: 87, label: 'தன்ஞ்செயன் குலம்' },
    { value: 88, label: 'தழிஞ்சி குலம்' },
    { value: 89, label: 'துந்துமன் குலம்' },
    { value: 90, label: 'தூரன் குலம்' },
    { value: 91, label: 'தேமான் குலம்' },
    { value: 92, label: 'தேர்வேந்தர் குலம்' },
    { value: 93, label: 'தேவேந்திரன் குலம்' },
    { value: 94, label: 'தோடை குலம்' },
    { value: 95, label: 'நந்தர் குலம்' },
    { value: 96, label: 'நாரை குலம்' },
    { value: 97, label: 'நிலன் குலம்' },
    { value: 98, label: 'நெய்தல் குலம்' },
    { value: 99, label: 'நேரியன் குலம்' },
    { value: 100, label: 'பங்சமன் குலம்' },
    { value: 101, label: 'பண்ணை குலம்' },
    { value: 102, label: 'பதரி குலம்' },
    { value: 103, label: 'பதுமன் குலம்' },
    { value: 104, label: 'பனங்காடை குலம்' },
    { value: 105, label: 'பனையன் குலம்' },
    { value: 106, label: 'பயிரன் குலம்' },
    { value: 107, label: 'பரமன் குலம்' },
    { value: 108, label: 'பருகுவி குலம்' },
    { value: 109, label: 'பவமன் குலம்' },
    { value: 110, label: 'பவழ குலம்' },
    { value: 111, label: 'பாண்டியன் குலம்' },
    { value: 112, label: 'பாம்பன் குலம்' },
    { value: 113, label: 'பாலியன் குலம்' },
    { value: 114, label: 'பாவலர் குலம்' },
    { value: 115, label: 'பிரமன் குலம்' },
    { value: 116, label: 'பில்லன் குலம்' },
    { value: 117, label: 'புண்ணை குலம்' },
    { value: 118, label: 'புதியன் குலம்' },
    { value: 119, label: 'புத்தன் குலம்' },
    { value: 120, label: 'பூசன் குலம்' },
    { value: 121, label: 'பூச்சந்தை குலம்' },
    { value: 122, label: 'பூந்தாரன் குலம்' },
    { value: 123, label: 'பூந்தை குலம்' },
    { value: 124, label: 'பூவன்' },
    { value: 125, label: 'பெரிய குலம்' },
    { value: 126, label: 'பெருங்குடியான் குலம்' },
    { value: 127, label: 'பேரிழந்தான் குலம்' },
    { value: 128, label: 'பைகரி குலம்' },
    { value: 129, label: 'பைரன் குலம்' },
    { value: 130, label: 'பொடியன் குலம்' },
    { value: 131, label: 'பொன்ன குலம்' },
    { value: 132, label: 'பொன்னர் குலம்' },
    { value: 133, label: 'பொன்னர்.சங்கர்' },
    { value: 134, label: 'பொருள்தந்த குலம்' },
    { value: 135, label: 'மணியன் குலம்' },
    { value: 136, label: 'மயிலான் குலம்' },
    { value: 137, label: 'மாடை குலம்' },
    { value: 138, label: 'மாதங்கன் குலம்' },
    { value: 139, label: 'மாதுரி குலம்' },
    { value: 140, label: 'மாயவர் குலம்' },
    { value: 141, label: 'முத்தன் குலம்' },
    { value: 142, label: 'முனையன் குலம்' },
    { value: 143, label: 'முல்லை குலம்' },
    { value: 144, label: 'முழுக்காதன் குலம்' },
    { value: 145, label: 'மூலன் குலம்' },
    { value: 146, label: 'மேதி குலம்' },
    { value: 147, label: 'வண்ணக்கன் குலம்' },
    { value: 148, label: 'வாச்சர் குலம்' },
    { value: 149, label: 'வாணன் குலம்' },
    { value: 150, label: 'விரதன் குலம்' },
    { value: 151, label: 'விலையன் குலம்' },
    { value: 152, label: 'விலோசணன் குலம்' },
    { value: 153, label: 'வில்லி குலம்' },
    { value: 154, label: 'விளியன் குலம்' },
    { value: 155, label: 'விவிழி குலம்' },
    { value: 156, label: 'வீரன் குலம்' },
    { value: 157, label: 'வெண்டுவன் குலம்' },
    { value: 158, label: 'வெண்ணை குலம்' },
    { value: 159, label: 'வெளையன் குலம்' },
    { value: 160, label: 'வெள்ளமை குலம்' },
    { value: 161, label: 'வெள்ளம்மன்குலம்' },
    { value: 162, label: 'வேணியன் குலம்' },
    { value: 163, label: 'வேந்தன் குலம்' },
    { value: 164, label: 'வொளம்பர் குலம்' },
    { value: 165, label: 'சேவடி குலம்' }
];

const FemaleMatch = {
    1: [{ 29: [10], 14: [9], 23: [9], 26: [9], 35: [9], 2: [8], 5: [8], 11: [8], 21: [8], 22: [8], 33: [8], 34: [8], 19: [7], 27: [7], 28: [7], 30: [7], 31: [7], 32: [7], 3: [6], 15: [6], 6: [6], 10: [6], 4: [5] }],
    2: [{ 1: [9], 15: [9], 22: [9], 24: [9], 36: [9], 27: [9], 28: [9], 13: [8], 19: [8], 20: [8], 21: [8], 25: [8], 29: [8], 3: [7], 6: [7], 10: [7], 12: [7], 16: [7], 32: [7], 4: [6], 5: [6], 7: [6], 9: [6], 17: [6], 18: [6] }],
    3: [{ 24: [11], 32: [11], 23: [10], 25: [10], 1: [9], 2: [9], 13: [9], 19: [9], 20: [9], 30: [9], 31: [9], 35: [9], 12: [7], 17: [7], 36: [7], 11: [7], 10: [5], 7: [5] }],
    4: [{ 32: [11], 24: [10], 1: [9], 2: [9], 19: [9], 20: [9], 25: [9], 30: [9], 31: [9], 35: [9], 23: [9], 17: [8], 8: [8], 18: [8], 36: [7], 11: [7], 5: [7], 6: [7], 12: [7], 13: [7], 14: [7], 7: [6] }],
    5: [{ 1: [9], 5: [9], 16: [9], 17: [9], 35: [9], 2: [8], 3: [8], 4: [8], 8: [8], 15: [8], 20: [8], 23: [8], 25: [8], 26: [8], 27: [8], 29: [8], 32: [8], 33: [8], 34: [8], 36: [8], 9: [7], 28: [7], 21: [6], 22: [6], 24: [6], 11: [6] }],
    6: [{ 1: [9], 5: [9], 20: [9], 29: [9], 35: [9], 2: [8], 3: [8], 4: [8], 16: [8], 17: [8], 23: [8], 28: [8], 32: [8], 33: [8], 34: [8], 36: [8], 11: [7], 21: [7], 22: [7], 24: [7], 25: [7], 26: [7], 27: [7], 8: [7], 15: [7], 9: [6], 10: [6] }],
    7: [{ 1: [9], 5: [9], 16: [9], 17: [9], 35: [9], 2: [8], 3: [8], 4: [8], 8: [8], 15: [8], 20: [8], 23: [8], 25: [8], 26: [8], 27: [8], 29: [8], 32: [8], 33: [8], 34: [8], 36: [8], 9: [7], 28: [7], 21: [6], 22: [6], 24: [6], 11: [6] }],
    8: [{ 36: [10], 2: [9], 6: [9], 7: [9], 26: [9], 27: [9], 3: [8], 4: [8], 18: [8], 22: [8], 28: [8], 31: [8], 33: [8], 34: [8], 35: [8], 1: [7], 14: [7], 16: [7], 19: [7], 21: [7], 30: [7], 15: [6], 25: [6] }],
    9: [{ 1: [9], 5: [9], 6: [9], 7: [9], 23: [9], 35: [9], 2: [8], 18: [8], 20: [8], 26: [8], 29: [8], 31: [8], 36: [8], 17: [7], 19: [9], 30: [7], 32: [7], 11: [6], 24: [6] }],
    10: [{ 1: [9], 7: [9], 23: [9], 35: [9], 2: [8], 5: [8], 6: [8], 26: [8], 29: [8], 36: [8], 18: [7], 20: [7], 30: [7], 31: [7], 11: [7], 17: [6], 32: [6], 19: [6], 24: [6] }],
    11: [{ 9: [10], 34: [10], 36: [10], 1: [9], 5: [9], 8: [9], 10: [9], 22: [9], 24: [9], 27: [9], 33: [9], 7: [9], 3: [8], 17: [8], 20: [8], 25: [8], 28: [8], 32: [8], 4: [7], 21: [7], 12: [7], 15: [7], 16: [7], 30: [7], 31: [7], 11: [6] }],
    12: [{ 3: [10], 4: [9], 7: [9], 9: [9], 22: [9], 23: [9], 27: [9], 30: [9], 31: [9], 32: [9], 34: [9], 2: [8], 6: [8], 10: [8], 11: [8], 15: [8], 18: [8], 26: [8], 28: [8], 33: [8], 19: [7], 21: [6], 29: [6], 5: [6], 17: [6] }],
    13: [{ 2: [9], 8: [9], 11: [9], 32: [9], 3: [8], 14: [8], 17: [8], 23: [8], 26: [8], 29: [8], 33: [8], 34: [8], 35: [8], 30: [8], 31: [8], 4: [7], 15: [7], 18: [7], 22: [7], 19: [6], 21: [6], 16: [6] }],
    14: [{ 1: [10], 33: [10], 34: [10], 3: [9], 8: [9], 13: [9], 15: [9], 27: [9], 28: [9], 29: [9], 36: [9], 4: [8], 16: [8], 24: [8], 25: [8], 30: [8], 31: [8], 18: [7], 22: [7], 32: [7], 17: [7], 19: [6], 21: [6], 20: [6] }],
    15: [{ 8: [10], 11: [10], 14: [10], 2: [10], 1: [9], 5: [9], 7: [9], 12: [9], 13: [9], 26: [9], 29: [9], 35: [9], 36: [9], 6: [8], 20: [8], 23: [8], 32: [8], 25: [7], 17: [7], 24: [6] }],
    16: [{ 8: [11], 35: [11], 36: [11], 5: [10], 14: [10], 7: [10], 2: [9], 6: [9], 11: [9], 13: [9], 20: [9], 26: [9], 29: [9], 1: [8], 12: [8], 17: [8], 23: [8], 32: [8], 25: [7], 30: [7], 31: [7], 24: [6] }],
    17: [{ 7: [11], 9: [11], 34: [11], 36: [11], 6: [10], 14: [10], 35: [10], 2: [9], 4: [9], 10: [9], 13: [9], 15: [9], 16: [9], 26: [9], 27: [9], 28: [9], 33: [9], 3: [8], 11: [8], 12: [8], 18: [8], 22: [8], 30: [8], 31: [8], 19: [7], 23: [7], 24: [6], 25: [6] }],
    18: [{ 3: [9], 4: [9], 8: [9], 9: [9], 12: [9], 13: [9], 1: [8], 5: [8], 17: [8], 32: [8], 34: [8], 36: [8], 2: [7], 10: [7], 14: [7], 29: [7], 25: [7], 27: [7], 28: [7], 11: [6], 20: [6], 21: [6], 22: [6], 23: [6], 24: [6], 33: [6], 26: [5] }],
    19: [{ 3: [9], 4: [9], 1: [8], 5: [8], 8: [8], 13: [8], 17: [8], 28: [8], 29: [8], 32: [8], 9: [7], 11: [7], 12: [7], 20: [7], 21: [7], 25: [7], 27: [7], 2: [7], 10: [6], 14: [6], 22: [6], 23: [6], 24: [6], 33: [6], 34: [6], 35: [6], 36: [6], 26: [5] }],
    20: [{ 2: [9], 6: [9], 7: [9], 9: [9], 16: [9], 10: [8], 14: [8], 15: [8], 18: [8], 19: [8], 24: [8], 26: [8], 30: [8], 33: [8], 34: [8], 36: [8], 3: [7], 4: [7], 11: [7], 12: [7], 21: [7], 23: [7], 28: [7], 31: [7], 35: [7], 22: [6], 27: [6], 25: [6], 13: [5] }],
    21: [{ 30: [10], 1: [9], 18: [9], 19: [9], 31: [9], 32: [9], 6: [8], 7: [8], 8: [8], 13: [8], 17: [8], 25: [8], 5: [7], 11: [7], 20: [7], 2: [7], 12: [6], 14: [6], 23: [6], 24: [6], 29: [5] }],
    22: [{ 11: [9], 13: [9], 18: [9], 19: [9], 30: [9], 1: [8], 6: [8], 12: [8], 17: [8], 25: [8], 31: [8], 32: [8], 5: [7], 20: [7], 23: [7], 14: [7], 24: [7], 7: [6], 8: [6], 29: [5] }],
    23: [{ 10: [10], 5: [9], 6: [9], 12: [9], 17: [9], 20: [9], 15: [8], 16: [8], 21: [8], 33: [8], 34: [8], 36: [8], 1: [7], 4: [7], 7: [7], 9: [7], 18: [7], 19: [7], 22: [7], 24: [7], 29: [7], 32: [7], 13: [7], 3: [6], 27: [6], 28: [6] }],
    24: [{ 3: [11], 4: [10], 11: [10], 18: [10], 19: [10], 21: [10], 22: [10], 6: [9], 10: [9], 15: [9], 16: [9], 20: [9], 23: [9], 5: [8], 14: [8], 27: [8], 28: [8], 30: [8], 31: [8], 35: [8], 7: [7], 2: [7], 17: [7], 32: [7], 33: [7], 34: [7], 9: [6] }],
    25: [{ 22: [10], 14: [9], 21: [9], 35: [9], 2: [8], 3: [8], 5: [8], 11: [8], 17: [8], 18: [8], 19: [8], 20: [8], 23: [8], 26: [8], 29: [8], 34: [8], 4: [7], 7: [7], 8: [7], 15: [7], 27: [7], 30: [7], 31: [7], 32: [7], 6: [6], 16: [6], 28: [6] }],
    26: [{ 15: [10], 36: [10], 1: [9], 7: [9], 9: [9], 10: [9], 13: [9], 16: [9], 22: [9], 24: [9], 25: [9], 27: [9], 34: [9], 6: [8], 8: [8], 12: [8], 17: [8], 18: [8], 19: [8], 20: [8], 21: [8], 28: [8], 33: [8], 30: [7], 31: [7], 5: [7], 29: [7], 32: [6], }],
    27: [{ 8: [10], 14: [10], 23: [10], 26: [10], 1: [9], 2: [9], 11: [9], 17: [9], 20: [9], 24: [9], 25: [9], 13: [8], 32: [8], 35: [8], 36: [8], 12: [7], 18: [7], 19: [7], 29: [7] }],
    28: [{ 8: [10], 17: [10], 20: [10], 26: [10], 1: [9], 2: [9], 25: [9], 32: [9], 11: [8], 14: [8], 18: [8], 19: [8], 24: [8], 29: [8], 35: [8], 30: [7], 31: [7], 36: [7], 12: [6], 21: [6], 13: [6] }],
    29: [{ 9: [10], 16: [10], 26: [10], 33: [10], 2: [9], 6: [9], 7: [9], 10: [9], 18: [9], 19: [9], 21: [9], 25: [9], 27: [9], 28: [9], 34: [9], 1: [8], 14: [8], 15: [8], 22: [8], 23: [8], 24: [8], 30: [8], 31: [8], 35: [7], 36: [7], 12: [6], 13: [6] }],
    30: [{ 25: [9], 3: [8], 4: [8], 16: [8], 17: [8], 20: [8], 21: [8], 27: [8], 28: [8], 29: [8], 32: [8], 5: [7], 8: [7], 13: [7], 22: [7], 23: [7], 24: [7], 9: [6], 11: [6], 12: [6], 1: [6], 15: [6], 26: [6], 33: [6], 10: [5] }],
    31: [{ 25: [9], 3: [8], 4: [8], 20: [8], 21: [8], 27: [8], 28: [8], 29: [8], 32: [8], 5: [7], 8: [7], 13: [7], 16: [7], 17: [7], 22: [7], 23: [7], 24: [7], 26: [7], 1: [6], 9: [6], 11: [6], 12: [6], 15: [6], 33: [6], 34: [6], 10: [5], 14: [5] }],
    32: [{ 18: [9], 30: [9], 31: [9], 36: [9], 12: [8], 19: [8], 21: [8], 22: [8], 24: [8], 26: [8], 27: [8], 28: [8], 6: [7], 7: [7], 25: [7], 3: [7], 4: [7], 16: [7], 34: [7], 13: [6], 14: [6], 23: [6], 2: [6], 9: [6], 10: [5], 11: [5], 15: [5] }],
    33: [{ 29: [10], 20: [9], 30: [9], 6: [8], 7: [8], 19: [8], 23: [8], 25: [8], 26: [8], 31: [8], 5: [7], 8: [7], 13: [7], 17: [7], 18: [7], 32: [7], 11: [7], 35: [7], 14: [6], 1: [6], 12: [5] }],
    34: [{ 29: [10], 23: [9], 30: [9], 11: [8], 13: [8], 25: [8], 26: [8], 31: [8], 35: [8], 1: [7], 6: [7], 7: [7], 14: [7], 17: [7], 18: [7], 32: [7], 20: [8], 19: [6], 5: [6], 8: [6], 12: [6], 24: [6] }],
    35: [{ 29: [10], 28: [9], 33: [9], 34: [9], 10: [8], 17: [8], 24: [8], 27: [8], 30: [8], 32: [8], 36: [8], 8: [7], 9: [7], 12: [7], 15: [7], 16: [7], 20: [7], 25: [7], 31: [7], 13: [6], 1: [6], 5: [6] }],
    36: [{ 28: [11], 15: [10], 16: [10], 23: [10], 27: [10], 35: [10], 10: [9], 11: [9], 26: [9], 29: [9], 32: [9], 9: [8], 14: [8], 22: [8], 8: [8], 17: [8], 18: [8], 3: [7], 2: [7], 6: [7], 7: [7], 19: [7], 20: [7], 4: [6], 21: [6] }],
}

const MaleMatch = {
    1: [{ 5: [10], 14: [10], 2: [9], 3: [9], 4: [9], 6: [9], 7: [9], 9: [9], 10: [9], 11: [9], 15: [9], 21: [9], 26: [9], 27: [9], 28: [9], 16: [8], 18: [8], 19: [8], 22: [8], 29: [8], 8: [7], 23: [7], 34: [7], 30: [6], 31: [6], 33: [6], 35: [6] }],
    2: [{ 5: [10], 15: [10], 3: [9], 4: [9], 8: [9], 13: [9], 16: [9], 17: [9], 20: [9], 27: [9], 28: [9], 29: [9], 1: [8], 6: [8], 7: [8], 9: [8], 10: [8], 12: [8], 25: [8], 18: [7], 19: [7], 21: [7], 24: [7], 36: [7], 32: [6] }],
    3: [{ 12: [10], 24: [10], 14: [9], 18: [9], 19: [9], 5: [8], 6: [8], 7: [8], 8: [8], 11: [8], 13: [8], 17: [8], 25: [8], 30: [8], 31: [8], 2: [7], 20: [7], 36: [7], 32: [7], 1: [6], 23: [6] }],
    4: [{ 24: [11], 12: [9], 17: [9], 18: [9], 19: [9], 5: [8], 6: [8], 7: [8], 8: [8], 14: [8], 30: [8], 31: [8], 11: [7], 13: [7], 20: [7], 23: [7], 25: [7], 32: [7], 2: [6], 36: [6], 1: [5] }],
    5: [{ 16: [10], 6: [9], 7: [9], 9: [9], 11: [9], 15: [9], 23: [9], 1: [8], 10: [8], 18: [8], 19: [8], 24: [8], 25: [8], 21: [7], 22: [7], 30: [7], 31: [7], 33: [7], 4: [7], 26: [7], 2: [6], 12: [6], 34: [6], 35: [6] }],
    6: [{ 17: [10], 5: [9], 8: [9], 9: [9], 16: [9], 20: [9], 23: [9], 24: [9], 29: [9], 10: [8], 12: [8], 15: [8], 21: [8], 22: [8], 26: [8], 33: [8], 2: [7], 32: [7], 34: [7], 4: [7], 36: [7], 1: [6], 25: [6] }],
    7: [{ 17: [11], 16: [10], 8: [9], 9: [9], 10: [9], 12: [9], 15: [9], 20: [9], 26: [9], 29: [9], 11: [9], 5: [8], 21: [8], 33: [8], 23: [7], 24: [7], 32: [7], 34: [7], 25: [7], 36: [7], 2: [6], 4: [6], 22: [6], 3: [5] }],
    8: [{ 16: [11], 15: [10], 27: [10], 28: [10], 11: [9], 13: [9], 14: [9], 18: [9], 7: [8], 19: [8], 21: [8], 26: [8], 4: [8], 36: [8], 30: [7], 31: [7], 33: [7], 35: [7], 6: [7], 25: [7], 22: [6], 34: [6] }],
    9: [{ 17: [11], 11: [10], 29: [10], 5: [9], 12: [9], 18: [9], 20: [9], 26: [9], 36: [8], 7: [7], 19: [7], 23: [7], 35: [7], 30: [6], 2: [6], 6: [6], 24: [6], 31: [6], 32: [6] }],
    10: [{ 23: [10], 5: [9], 11: [9], 17: [9], 24: [9], 26: [9], 29: [9], 36: [9], 12: [8], 20: [8], 35: [8], 2: [7], 18: [7], 1: [6], 6: [6], 19: [6], 3: [5], 30: [5], 31: [5], 32: [5] }],
    11: [{ 15: [10], 24: [10], 13: [9], 16: [9], 22: [9], 27: [9], 36: [9], 1: [8], 12: [8], 17: [8], 25: [8], 28: [8], 34: [8], 6: [7], 19: [7], 20: [7], 21: [7], 4: [7], 3: [7], 5: [7], 10: [7], 33: [7], 18: [6], 30: [6], 7: [6], 9: [6], 31: [6], 32: [5] }],
    12: [{ 15: [9], 18: [9], 23: [9], 16: [8], 17: [8], 22: [8], 26: [8], 32: [8], 2: [7], 3: [7], 19: [7], 20: [7], 27: [7], 35: [7], 11: [7], 4: [7], 21: [6], 28: [6], 29: [6], 30: [6], 5: [6], 31: [6], 34: [6], 33: [5] }],
    13: [{ 3: [9], 14: [9], 15: [9], 16: [9], 17: [9], 18: [9], 22: [9], 26: [9], 2: [8], 19: [8], 21: [8], 27: [8], 34: [8], 30: [7], 31: [7], 33: [7], 4: [7], 23: [7], 32: [6], 35: [6], 11: [6], 28: [6], 29: [6], 20: [5] }],
    14: [{ 15: [10], 16: [10], 17: [10], 27: [10], 1: [9], 25: [9], 13: [8], 20: [8], 24: [8], 28: [8], 29: [8], 36: [8], 8: [7], 18: [7], 34: [7], 4: [7], 22: [7], 32: [6], 33: [6], 19: [6], 21: [6], 31: [5] }],
    15: [{ 26: [10], 36: [10], 2: [9], 14: [9], 17: [9], 24: [9], 5: [8], 7: [8], 12: [8], 20: [8], 23: [8], 29: [8], 13: [7], 35: [7], 6: [7], 11: [7], 25: [7], 1: [6], 8: [6], 30: [6], 31: [6], 32: [5] }],
    16: [{ 29: [10], 36: [10], 5: [9], 7: [9], 17: [9], 20: [9], 24: [9], 26: [9], 6: [8], 12: [8], 14: [8], 23: [8], 30: [8], 2: [7], 8: [7], 31: [7], 35: [7], 11: [7], 32: [7], 13: [6], 25: [6] }],
    17: [{ 28: [10], 7: [9], 23: [9], 27: [9], 4: [8], 6: [8], 11: [8], 13: [8], 16: [8], 18: [8], 19: [8], 21: [8], 22: [8], 25: [8], 26: [8], 30: [8], 35: [8], 36: [8], 3: [7], 9: [7], 31: [7], 33: [7], 34: [7], 14: [7], 15: [7], 24: [7], 10: [6], 2: [6], 12: [6] }],
    18: [{ 24: [10], 21: [9], 22: [9], 29: [9], 32: [9], 5: [8], 8: [8], 9: [8], 12: [8], 17: [8], 20: [8], 25: [8], 26: [8], 28: [8], 4: [8], 36: [8], 10: [7], 13: [7], 14: [7], 23: [7], 33: [7], 34: [7], 27: [7], 2: [6] }],
    19: [{ 24: [10], 3: [9], 4: [9], 5: [9], 21: [9], 22: [9], 29: [9], 2: [8], 20: [8], 25: [8], 26: [8], 28: [8], 32: [8], 33: [8], 1: [7], 8: [7], 9: [7], 12: [7], 23: [7], 17: [7], 27: [7], 36: [7], 13: [6], 14: [6], 34: [6], 10: [6] }],
    20: [{ 28: [10], 3: [9], 4: [9], 6: [9], 16: [9], 23: [9], 24: [9], 27: [9], 33: [9], 2: [8], 7: [8], 9: [8], 11: [8], 15: [8], 25: [8], 26: [8], 30: [8], 31: [8], 10: [7], 19: [7], 21: [7], 22: [7], 35: [7], 34: [7], 36: [7], 14: [6], 18: [6] }],
    21: [{ 24: [10], 5: [9], 25: [9], 29: [9], 1: [8], 2: [8], 23: [8], 26: [8], 30: [8], 31: [8], 32: [8], 6: [7], 8: [7], 11: [7], 19: [7], 20: [7], 7: [6], 12: [6], 13: [6], 14: [6], 28: [6], 18: [6], 36: [6] }],
    22: [{ 24: [10], 25: [10], 2: [9], 5: [9], 11: [9], 12: [9], 26: [9], 1: [8], 8: [8], 17: [8], 29: [8], 32: [8], 36: [8], 6: [7], 13: [7], 14: [7], 23: [7], 30: [7], 31: [7], 7: [6], 20: [6], 18: [6], 19: [6] }],
    23: [{ 3: [10], 27: [10], 36: [10], 1: [9], 5: [9], 9: [9], 10: [9], 12: [9], 24: [9], 34: [9], 4: [9], 6: [8], 7: [8], 13: [8], 15: [8], 16: [8], 25: [8], 29: [8], 33: [8], 20: [7], 30: [7], 31: [7], 22: [7], 17: [7], 32: [6], 18: [6], 19: [6], 21: [6] }],
    24: [{ 3: [11], 4: [10], 2: [9], 5: [9], 11: [9], 26: [9], 27: [9], 14: [8], 20: [8], 28: [8], 29: [8], 32: [8], 35: [8], 6: [7], 23: [7], 22: [7], 30: [7], 31: [7], 7: [6], 9: [6], 10: [6], 15: [6], 16: [6], 17: [6], 18: [6], 19: [6], 21: [6], 34: [6] }],
    25: [{ 3: [10], 4: [9], 26: [9], 27: [9], 28: [9], 29: [9], 30: [9], 31: [9], 2: [8], 5: [8], 7: [8], 11: [8], 14: [8], 21: [8], 22: [8], 33: [8], 34: [8], 6: [7], 15: [7], 16: [7], 19: [7], 32: [7], 35: [7], 18: [7], 8: [6], 17: [6], 20: [6] }],
    26: [{ 27: [10], 28: [10], 29: [10], 1: [9], 5: [9], 8: [9], 15: [9], 16: [9], 17: [9], 36: [9], 7: [8], 9: [8], 10: [8], 12: [8], 13: [8], 20: [8], 25: [8], 32: [8], 33: [8], 34: [8], 6: [7], 31: [7], 30: [6], 18: [5], 19: [5] }],
    27: [{ 36: [10], 8: [9], 11: [9], 12: [9], 13: [9], 17: [9], 26: [9], 29: [9], 2: [9], 7: [8], 24: [8], 30: [8], 31: [8], 32: [8], 35: [8], 1: [7], 6: [7], 18: [7], 19: [7], 25: [7], 20: [6], 23: [6] }],
    28: [{ 36: [11], 14: [9], 17: [9], 29: [9], 35: [9], 2: [9], 6: [8], 8: [8], 11: [8], 12: [8], 19: [8], 24: [8], 26: [8], 30: [8], 31: [8], 32: [8], 1: [7], 7: [7], 20: [7], 18: [7], 23: [6], 25: [6] }],
    29: [{ 1: [10], 33: [10], 34: [10], 35: [10], 6: [9], 14: [9], 15: [9], 16: [9], 36: [9], 2: [8], 7: [8], 9: [8], 10: [8], 13: [8], 19: [8], 25: [8], 28: [8], 30: [8], 31: [8], 18: [7], 23: [7], 26: [7], 27: [7], 12: [6], 21: [5], 22: [5], }],
    30: [{ 21: [10], 3: [9], 4: [9], 12: [9], 22: [9], 32: [9], 33: [9], 34: [9], 5: [9], 14: [8], 17: [8], 20: [8], 24: [8], 29: [8], 35: [8], 13: [8], 1: [7], 8: [7], 9: [7], 10: [7], 26: [7], 11: [7], 16: [7], 25: [7], 28: [7] }],
    31: [{ 3: [9], 4: [9], 12: [9], 21: [9], 32: [9], 5: [9], 8: [8], 9: [8], 14: [8], 17: [8], 22: [8], 24: [8], 29: [8], 33: [8], 34: [8], 13: [8], 1: [7], 10: [7], 20: [7], 26: [7], 35: [7], 11: [7], 16: [7], 25: [7], 28: [7] }],
    32: [{ 3: [11], 4: [11], 12: [9], 13: [9], 21: [9], 28: [9], 36: [9], 6: [8], 7: [8], 11: [8], 15: [8], 16: [8], 18: [8], 19: [8], 22: [8], 27: [8], 30: [8], 31: [8], 35: [8], 1: [7], 2: [7], 9: [7], 14: [7], 23: [7], 33: [7], 34: [7], 24: [7], 25: [7], 10: [6], 26: [6] }],
    33: [{ 5: [11], 14: [10], 29: [10], 11: [9], 17: [9], 35: [9], 1: [8], 6: [8], 7: [8], 8: [8], 12: [8], 13: [8], 20: [8], 23: [8], 26: [8], 24: [7], 18: [6], 19: [6], 30: [6], 31: [6] }],
    34: [{ 5: [11], 17: [11], 11: [10], 14: [10], 12: [9], 26: [9], 29: [9], 35: [9], 1: [8], 6: [8], 7: [8], 8: [8], 13: [8], 18: [8], 20: [8], 23: [8], 25: [8], 24: [7], 32: [7], 19: [6], 31: [6] }],
    35: [{ 16: [11], 17: [10], 36: [10], 1: [9], 3: [9], 4: [9], 5: [9], 6: [9], 7: [9], 9: [9], 10: [9], 12: [9], 15: [9], 25: [9], 8: [8], 13: [8], 24: [8], 27: [8], 28: [8], 34: [8], 20: [7], 29: [7], 33: [7], 19: [6] }],
    36: [{ 16: [11], 17: [11], 8: [10], 11: [10], 26: [10], 2: [9], 5: [9], 14: [9], 15: [9], 32: [9], 6: [8], 7: [8], 9: [8], 10: [8], 18: [8], 20: [8], 23: [8], 27: [8], 35: [8], 3: [7], 4: [7], 29: [7], 28: [7], 19: [6] }],
}

const Weight = [
    { label: '', value: 0 },
    { label: '41 Kg', value: 1 },
    { label: '42 Kg', value: 2 },
    { label: '43 Kg', value: 3 },
    { label: '44 Kg', value: 4 },
    { label: '45 Kg', value: 5 },
    { label: '46 Kg', value: 6 },
    { label: '47 Kg', value: 7 },
    { label: '48 Kg', value: 8 },
    { label: '49 Kg', value: 9 },
    { label: '50 Kg', value: 10 },
    { label: '51 Kg', value: 11 },
    { label: '52 Kg', value: 12 },
    { label: '53 Kg', value: 13 },
    { label: '54 Kg', value: 14 },
    { label: '55 Kg', value: 15 },
    { label: '56 Kg', value: 16 },
    { label: '57 Kg', value: 17 },
    { label: '58 Kg', value: 18 },
    { label: '59 Kg', value: 19 },
    { label: '60 Kg', value: 20 },
    { label: '61 Kg', value: 21 },
    { label: '62 Kg', value: 22 },
    { label: '63 Kg', value: 23 },
    { label: '64 Kg', value: 24 },
    { label: '65 Kg', value: 25 },
    { label: '66 Kg', value: 26 },
    { label: '67 Kg', value: 27 },
    { label: '68 Kg', value: 28 },
    { label: '69 Kg', value: 29 },
    { label: '70 Kg', value: 30 },
    { label: '71 Kg', value: 31 },
    { label: '72 Kg', value: 32 },
    { label: '73 Kg', value: 33 },
    { label: '74 Kg', value: 34 },
    { label: '75 Kg', value: 35 },
    { label: '76 Kg', value: 36 },
    { label: '77 Kg', value: 37 },
    { label: '78 Kg', value: 38 },
    { label: '79 Kg', value: 39 },
    { label: '80 Kg', value: 40 },
    { label: '81 Kg', value: 41 },
    { label: '82 Kg', value: 42 },
    { label: '83 Kg', value: 43 },
    { label: '84 Kg', value: 44 },
    { label: '85 Kg', value: 45 },
    { label: '86 Kg', value: 46 },
    { label: '87 Kg', value: 47 },
    { label: '88 Kg', value: 48 },
    { label: '89 Kg', value: 49 },
    { label: '90 Kg', value: 50 },
    { label: '91 Kg', value: 51 },
    { label: '92 Kg', value: 52 },
    { label: '93 Kg', value: 53 },
    { label: '94 Kg', value: 54 },
    { label: '95 Kg', value: 55 },
    { label: '96 Kg', value: 56 },
    { label: '97 Kg', value: 57 },
    { label: '98 Kg', value: 58 },
    { label: '99 Kg', value: 59 },
    { label: '100 Kg', value: 60 },
    { label: '101 Kg', value: 61 },
    { label: '102 Kg', value: 62 },
    { label: '103 Kg', value: 63 },
    { label: '104 Kg', value: 64 },
    { label: '105 Kg', value: 65 },
    { label: '106 Kg', value: 66 },
    { label: '107 Kg', value: 67 },
    { label: '108 Kg', value: 68 },
    { label: '109 Kg', value: 69 },
    { label: '110 Kg', value: 70 },
    { label: '111 Kg', value: 71 },
    { label: '112 Kg', value: 72 },
    { label: '113 Kg', value: 73 },
    { label: '114 Kg', value: 74 },
    { label: '115 Kg', value: 75 },
    { label: '116 Kg', value: 76 },
    { label: '117 Kg', value: 77 },
    { label: '118 Kg', value: 78 },
    { label: '119 Kg', value: 79 },
    { label: '120 Kg', value: 80 },
    { label: '121 Kg', value: 81 },
    { label: '122 Kg', value: 82 },
    { label: '123 Kg', value: 83 },
    { label: '124 Kg', value: 84 },
    { label: '125 Kg', value: 85 },
    { label: '126 Kg', value: 86 },
    { label: '127 Kg', value: 87 },
    { label: '128 Kg', value: 88 },
    { label: '129 Kg', value: 89 },
    { label: '130 Kg', value: 90 },
    { label: '131 Kg', value: 91 },
    { label: '132 Kg', value: 92 },
    { label: '133 Kg', value: 93 },
    { label: '134 Kg', value: 94 },
    { label: '135 Kg', value: 95 },
    { label: '136 Kg', value: 96 },
    { label: '137 Kg', value: 97 },
    { label: '138 Kg', value: 98 },
    { label: '139 Kg', value: 99 },
    { label: '140 Kg', value: 100 },
]

const Height = [
    { label: '', value: 0 },
    { label: '4ft 6in (137-138)cm', value: 1 },
    { label: '4ft 7in (139-141)cm', value: 2 },
    { label: '4ft 8in (142-143)cm', value: 3 },
    { label: '4ft 9in (144-146)cm', value: 4 },
    { label: '4ft 10in (147-148)cm', value: 5 },
    { label: '4ft 11in (149-151)cm', value: 6 },
    { label: '5ft (152-153)cm', value: 7 },
    { label: '5ft 1in (154-156)cm', value: 8 },
    { label: '5ft 2in (157-159)cm', value: 9 },
    { label: '5ft 3in (160-161)cm', value: 10 },
    { label: '5ft 4in (162-164)cm', value: 11 },
    { label: '5ft 5in (165-166)cm', value: 12 },
    { label: '5ft 6in (167-169)cm', value: 13 },
    { label: '5ft 7in (170-171)cm', value: 14 },
    { label: '5ft 8in (172-174)cm', value: 15 },
    { label: '5ft 9in (175-176)cm', value: 16 },
    { label: '5ft 10in (177-179)cm', value: 17 },
    { label: '5ft 11in (180-181)cm', value: 18 },
    { label: '6ft (182-184)cm', value: 19 },
    { label: '6ft 1in (185-186)cm', value: 20 },
    { label: '6ft 2in (187-189)cm', value: 21 },
    { label: '6ft 3in (190-192)cm', value: 22 },
    { label: '6ft 4in (193-194)cm', value: 23 },
    { label: '6ft 5in (195-197)cm', value: 24 },
    { label: '6ft 6in (199-199)cm', value: 25 },
    { label: '6ft 7in (200-202)cm', value: 26 },
    { label: '6ft 8in (203-205)cm', value: 27 },
    { label: '6ft 9in (206-207)cm', value: 28 },
    { label: '6ft 10in (208-210)cm', value: 29 },
    { label: '6ft 11in (211-212)cm', value: 30 },
    { label: '7ft (213-215)', value: 31 },
]

const Country = [
    { value: 0, label: "" },
    { value: 1, label: "India" },
    { value: 2, label: "United States" },
    { value: 3, label: "Afghanistan" },
    { value: 4, label: "Albania" },
    { value: 5, label: "Algeria" },
    { value: 6, label: "Andorra" },
    { value: 7, label: "Angola" },
    { value: 8, label: "Anguilla" },
    { value: 9, label: "Antigua &amp; Barbuda" },
    { value: 10, label: "Argentina" },
    { value: 11, label: "Armenia" },
    { value: 12, label: "Aruba" },
    { value: 13, label: "Australia" },
    { value: 14, label: "Austria" },
    { value: 15, label: "Azerbaijan" },
    { value: 16, label: "Bahamas" },
    { value: 17, label: "Bahrain" },
    { value: 18, label: "Bangladesh" },
    { value: 19, label: "Barbados" },
    { value: 20, label: "Belarus" },
    { value: 21, label: "Belgium" },
    { value: 22, label: "Belize" },
    { value: 23, label: "Benin" },
    { value: 24, label: "Bermuda" },
    { value: 25, label: "Bhutan" },
    { value: 26, label: "Bolivia" },
    { value: 27, label: "Bosnia &amp; Herzegovina" },
    { value: 28, label: "Botswana" },
    { value: 29, label: "Brazil" },
    { value: 30, label: "British Virgin Islands" },
    { value: 31, label: "Brunei" },
    { value: 32, label: "Bulgaria" },
    { value: 33, label: "Burkina Faso" },
    { value: 34, label: "Burundi" },
    { value: 35, label: "Cambodia" },
    { value: 36, label: "Cameroon" },
    { value: 37, label: "Canada" },
    { value: 38, label: "Cape Verde" },
    { value: 39, label: "Cayman Islands" },
    { value: 40, label: "Chad" },
    { value: 41, label: "Chile" },
    { value: 42, label: "China" },
    { value: 43, label: "Colombia" },
    { value: 44, label: "Congo" },
    { value: 45, label: "Cook Islands" },
    { value: 46, label: "Costa Rica" },
    { value: 47, label: "Cote D Ivoire" },
    { value: 48, label: "Croatia" },
    { value: 49, label: "Cruise Ship" },
    { value: 50, label: "Cuba" },
    { value: 51, label: "Cyprus" },
    { value: 52, label: "Czech Republic" },
    { value: 53, label: "Denmark" },
    { value: 54, label: "Djibouti" },
    { value: 55, label: "Dominica" },
    { value: 56, label: "Dominican Republic" },
    { value: 57, label: "Ecuador" },
    { value: 58, label: "Egypt" },
    { value: 59, label: "El Salvador" },
    { value: 60, label: "Equatorial Guinea" },
    { value: 61, label: "Estonia" },
    { value: 62, label: "Ethiopia" },
    { value: 63, label: "Falkland Islands" },
    { value: 64, label: "Faroe Islands" },
    { value: 65, label: "Fiji" },
    { value: 66, label: "Finland" },
    { value: 67, label: "France" },
    { value: 68, label: "French Polynesia" },
    { value: 69, label: "French West Indies" },
    { value: 70, label: "Gabon" },
    { value: 71, label: "Gambia" },
    { value: 72, label: "Georgia" },
    { value: 73, label: "Germany" },
    { value: 74, label: "Ghana" },
    { value: 75, label: "Gibraltar" },
    { value: 76, label: "Greece" },
    { value: 77, label: "Greenland" },
    { value: 78, label: "Grenada" },
    { value: 79, label: "Guam" },
    { value: 80, label: "Guatemala" },
    { value: 81, label: "Guernsey" },
    { value: 82, label: "Guinea" },
    { value: 83, label: "Guinea Bissau" },
    { value: 84, label: "Guyana" },
    { value: 85, label: "Haiti" },
    { value: 86, label: "Honduras" },
    { value: 87, label: "Hong Kong" },
    { value: 88, label: "Hungary" },
    { value: 89, label: "Iceland" },
    { value: 90, label: "Indonesia" },
    { value: 91, label: "Iran" },
    { value: 92, label: "Iraq" },
    { value: 93, label: "Ireland" },
    { value: 94, label: "Isle of Man" },
    { value: 95, label: "Israel" },
    { value: 96, label: "Italy" },
    { value: 97, label: "Jamaica" },
    { value: 98, label: "Japan" },
    { value: 99, label: "Jersey" },
    { value: 100, label: "Jordan" },
    { value: 101, label: "Kazakhstan" },
    { value: 102, label: "Kenya" },
    { value: 103, label: "Kuwait" },
    { value: 104, label: "Kyrgyz Republic" },
    { value: 105, label: "Laos" },
    { value: 106, label: "Latvia" },
    { value: 107, label: "Lebanon" },
    { value: 108, label: "Lesotho" },
    { value: 109, label: "Liberia" },
    { value: 110, label: "Libya" },
    { value: 111, label: "Liechtenstein" },
    { value: 112, label: "Lithuania" },
    { value: 113, label: "Luxembourg" },
    { value: 114, label: "Macau" },
    { value: 115, label: "Macedonia" },
    { value: 116, label: "Madagascar" },
    { value: 117, label: "Malawi" },
    { value: 118, label: "Malaysia" },
    { value: 119, label: "Maldives" },
    { value: 120, label: "Mali" },
    { value: 121, label: "Malta" },
    { value: 122, label: "Mauritania" },
    { value: 123, label: "Mauritius" },
    { value: 124, label: "Mexico" },
    { value: 125, label: "Moldova" },
    { value: 126, label: "Monaco" },
    { value: 127, label: "Mongolia" },
    { value: 128, label: "Montenegro" },
    { value: 129, label: "Montserrat" },
    { value: 130, label: "Morocco" },
    { value: 131, label: "Mozambique" },
    { value: 132, label: "Namibia" },
    { value: 133, label: "Nepal" },
    { value: 134, label: "Netherlands" },
    { value: 135, label: "Netherlands Antilles" },
    { value: 136, label: "New Caledonia" },
    { value: 137, label: "New Zealand" },
    { value: 138, label: "Nicaragua" },
    { value: 139, label: "Niger" },
    { value: 140, label: "Nigeria" },
    { value: 141, label: "Norway" },
    { value: 142, label: "Oman" },
    { value: 143, label: "Pakistan" },
    { value: 144, label: "Palestine" },
    { value: 145, label: "Panama" },
    { value: 146, label: "Papua New Guinea" },
    { value: 147, label: "Paraguay" },
    { value: 148, label: "Peru" },
    { value: 149, label: "Philippines" },
    { value: 150, label: "Poland" },
    { value: 151, label: "Portugal" },
    { value: 152, label: "Puerto Rico" },
    { value: 153, label: "Qatar" },
    { value: 154, label: "Reunion" },
    { value: 155, label: "Romania" },
    { value: 156, label: "Russia" },
    { value: 157, label: "Rwanda" },
    { value: 158, label: "Saint Pierre &amp; Miquelon" },
    { value: 159, label: "Samoa" },
    { value: 160, label: "San Marino" },
    { value: 161, label: "Satellite" },
    { value: 162, label: "Saudi Arabia" },
    { value: 163, label: "Senegal" },
    { value: 164, label: "Serbia" },
    { value: 165, label: "Seychelles" },
    { value: 166, label: "Sierra Leone" },
    { value: 167, label: "Singapore" },
    { value: 168, label: "Slovakia" },
    { value: 169, label: "Slovenia" },
    { value: 170, label: "South Africa" },
    { value: 171, label: "South Korea" },
    { value: 172, label: "Spain" },
    { value: 173, label: "Sri Lanka" },
    { value: 174, label: "St Kitts &amp; Nevis" },
    { value: 175, label: "St Lucia" },
    { value: 176, label: "St Vincent" },
    { value: 177, label: "St. Lucia" },
    { value: 178, label: "Sudan" },
    { value: 179, label: "Suriname" },
    { value: 180, label: "Swaziland" },
    { value: 181, label: "Sweden" },
    { value: 182, label: "Switzerland" },
    { value: 183, label: "Syria" },
    { value: 184, label: "Taiwan" },
    { value: 185, label: "Tajikistan" },
    { value: 186, label: "Tanzania" },
    { value: 187, label: "Thailand" },
    { value: 188, label: "Timor LEste" },
    { value: 189, label: "Togo" },
    { value: 190, label: "Tonga" },
    { value: 191, label: "Trinidad &amp; Tobago" },
    { value: 192, label: "Tunisia" },
    { value: 193, label: "Turkey" },
    { value: 194, label: "Turkmenistan" },
    { value: 195, label: "Turks &amp; Caicos" },
    { value: 196, label: "Uganda" },
    { value: 197, label: "Ukraine" },
    { value: 198, label: "United Arab Emirates" },
    { value: 199, label: "United Kingdom" },
    { value: 200, label: "United States Minor Outlying Islands" },
    { value: 201, label: "Uruguay" },
    { value: 202, label: "Uzbekistan" },
    { value: 203, label: "Venezuela" },
    { value: 204, label: "Vietnam" },
    { value: 205, label: "Virgin Islands (US)" },
    { value: 206, label: "Yemen" },
    { value: 207, label: "Zambia" },
    { value: 208, label: "Zimbabwe" }
]

const States = [
    { value: 0, label: "" },
    { value: 1, label: "Tamil Nadu" },
    { value: 2, label: "Andhra Pradesh" },
    { value: 3, label: "Arunachal Pradesh" },
    { value: 4, label: "Assam" },
    { value: 5, label: "Bihar" },
    { value: 6, label: "Chhattisgarh" },
    { value: 7, label: "Goa" },
    { value: 8, label: "Gujarat" },
    { value: 9, label: "Haryana" },
    { value: 10, label: "Himachal Pradesh" },
    { value: 11, label: "Jammu and Kashmir" },
    { value: 12, label: "Jharkhand" },
    { value: 13, label: "Karnataka" },
    { value: 14, label: "Kerala" },
    { value: 15, label: "Madhya Pradesh" },
    { value: 16, label: "Maharashtra" },
    { value: 17, label: "Manipur" },
    { value: 18, label: "Meghalaya" },
    { value: 19, label: "Mizoram" },
    { value: 20, label: "Nagaland" },
    { value: 21, label: "Odisha" },
    { value: 22, label: "Punjab" },
    { value: 23, label: "Rajasthan" },
    { value: 24, label: "Sikkim" },
    { value: 25, label: "Telangana" },
    { value: 26, label: "Tripura" },
    { value: 27, label: "Uttarakhand" },
    { value: 28, label: "Uttar Pradesh" },
    { value: 29, label: "West Bengal" },
    { value: 30, label: "Andaman and Nicobar Islands" },
    { value: 31, label: "Chandigarh" },
    { value: 32, label: "Dadra and Nagar Haveli" },
    { value: 33, label: "Daman and Diu" },
    { value: 34, label: "Delhi" },
    { value: 35, label: "Lakshadweep" },
    { value: 36, label: "Puducherry" }
]

const Job = [
    { value: 0, label: '' },
    { value: 1, label: 'Agriculture & Farming Professional' },
    { value: 2, label: 'Manager' },
    { value: 3, label: 'Supervisor' },
    { value: 4, label: 'Officer' },
    { value: 5, label: 'Administrative Professional' },
    { value: 6, label: 'Executive' },
    { value: 7, label: 'Clerk' },
    { value: 8, label: 'Human Resources Professional' },
    { value: 9, label: 'Secretary / Front Office' },
    { value: 10, label: 'Horticulturist' },
    { value: 11, label: 'Pilot' },
    { value: 12, label: 'Air Hostess / Flight Attendant' },
    { value: 13, label: 'Airline Professional' },
    { value: 14, label: 'Architect' },
    { value: 15, label: 'Interior Designer' },
    { value: 16, label: 'Chartered Accountant' },
    { value: 17, label: 'Company Secretary' },
    { value: 18, label: 'Accounts / Finance Professional' },
    { value: 19, label: 'Banking Professional' },
    { value: 20, label: 'Auditor' },
    { value: 21, label: 'Financial Accountant' },
    { value: 22, label: 'Financial Analyst / Planning / Working' },
    { value: 23, label: 'Investment Professional' },
    { value: 24, label: 'Fashion Designer' },
    { value: 25, label: 'Beautician' },
    { value: 26, label: 'Hair Stylist' },
    { value: 27, label: 'Jewellery Designer' },
    { value: 28, label: 'Design Engineer' },
    { value: 29, label: 'Makeup Artist' },
    { value: 30, label: 'BPO / KPO / ITES Professional' },
    { value: 31, label: 'Customer Service Professional' },
    { value: 32, label: 'Civil Services (IAS / IPS / IRS / IES / IFS)' },
    { value: 33, label: 'Analyst' },
    { value: 34, label: 'Consultant' },
    { value: 35, label: 'Corporate Communication' },
    { value: 36, label: 'Corporate Planning' },
    { value: 37, label: 'Marketing Professional' },
    { value: 38, label: 'Operations Management' },
    { value: 39, label: 'Sales Professional' },
    { value: 40, label: 'Senior Manager / Manager' },
    { value: 41, label: 'Subject Matter Expert' },
    { value: 42, label: 'Business Development Professional' },
    { value: 43, label: 'Content Writer' },
    { value: 44, label: 'Army' },
    { value: 45, label: 'Navy' },
    { value: 46, label: 'Defence Services (Others)' },
    { value: 47, label: 'Air Force' },
    { value: 48, label: 'Paramilitary' },
    { value: 49, label: 'Professor / Lecturer' },
    { value: 50, label: 'Teaching / Academician' },
    { value: 51, label: 'Education Professional' },
    { value: 52, label: 'Training Professional' },
    { value: 53, label: 'Research Assistant' },
    { value: 54, label: 'Research Scholar' },
    { value: 55, label: 'Civil Engineer' },
    { value: 56, label: 'Electronics / Telecom Engineer' },
    { value: 57, label: 'Mechanical / Production Engineer' },
    { value: 58, label: 'Quality Assurance Engineer - Non IT' },
    { value: 59, label: 'Engineer - Non IT' },
    { value: 60, label: 'Computer Engineer' },
    { value: 61, label: 'Product Manager - Non IT' },
    { value: 62, label: 'Project Manager - Non IT' },
    { value: 63, label: 'Hotel / Hospitality Professional' },
    { value: 64, label: 'Restaurant / Catering Professional' },
    { value: 65, label: 'Chef / Cook' },
    { value: 66, label: 'Software Professional' },
    { value: 67, label: 'Hardware Professional' },
    { value: 68, label: 'Product Manager' },
    { value: 69, label: 'Project Manager' },
    { value: 70, label: 'Program Manager' },
    { value: 71, label: 'Animator' },
    { value: 72, label: 'Cyber / Network Security' },
    { value: 73, label: 'UI / UX Designer' },
    { value: 74, label: 'Web / Graphic Designer' },
    { value: 75, label: 'Software Consultant' },
    { value: 76, label: 'Data Analyst' },
    { value: 77, label: 'Data Scientist' },
    { value: 78, label: 'Network Engineer' },
    { value: 79, label: 'Quality Assurance Engineer' },
    { value: 80, label: 'Lawyer & Legal Professional' },
    { value: 81, label: 'Legal Assistant' },
    { value: 82, label: 'Law Enforcement Officer' },
    { value: 83, label: 'Police' },
    { value: 84, label: 'Doctor' },
    { value: 85, label: 'Healthcare Professional' },
    { value: 86, label: 'Paramedical Professional' },
    { value: 87, label: 'Nurse' },
    { value: 88, label: 'Pharmacist' },
    { value: 89, label: 'Physiotherapist' },
    { value: 90, label: 'Psychologist' },
    { value: 91, label: 'Veterinary Doctor' },
    { value: 92, label: 'Dentist' },
    { value: 93, label: 'Surgeon' },
    { value: 94, label: 'Therapist' },
    { value: 95, label: 'Medical Professional' },
    { value: 96, label: 'Dietician / Nutritionist' },
    { value: 97, label: 'Lab Technician' },
    { value: 98, label: 'Journalist' },
    { value: 99, label: 'Media Professional' },
    { value: 100, label: 'Entertainment Professional' },
    { value: 101, label: 'Event Management Professional' },
    { value: 102, label: 'Advertising / PR Professional' },
    { value: 103, label: 'Designer' },
    { value: 104, label: 'Actor / Model' },
    { value: 105, label: 'Artist' },
    { value: 106, label: 'Mariner / Merchant Navy' },
    { value: 107, label: 'Sailor' },
    { value: 108, label: 'Scientist / Researcher' },
    { value: 109, label: 'CXO / President, Director, Chairman' },
    { value: 110, label: 'VP / AVP / GM / DGM / AGM' },
    { value: 111, label: 'Technician' },
    { value: 112, label: 'Arts & Craftsman' },
    { value: 113, label: 'Librarian' },
    { value: 114, label: 'Business Owner / Entrepreneur' },
    { value: 115, label: 'Retired' },
    { value: 116, label: 'Transportation / Logistics Professional' },
    { value: 117, label: 'Agent / Broker / Trader' },
    { value: 118, label: 'Contractor' },
    { value: 119, label: 'Fitness Professional' },
    { value: 120, label: 'Security Professional' },
    { value: 121, label: 'Social Worker /  Volunteer / NGO' },
    { value: 122, label: 'Sportsperson' },
    { value: 123, label: 'Travel Professional' },
    { value: 124, label: 'Singer' },
    { value: 125, label: 'Writer' },
    { value: 126, label: 'Politician' },
    { value: 127, label: 'Associate' },
    { value: 128, label: 'Builder' },
    { value: 129, label: 'Chemical Engineer' },
    { value: 130, label: 'CNC Operator' },
    { value: 131, label: 'Distributor' },
    { value: 132, label: 'Driver' },
    { value: 133, label: 'Freelancer' },
    { value: 134, label: 'Mechanic' },
    { value: 135, label: 'Medical Representative' },
    { value: 136, label: 'Musician' },
    { value: 137, label: 'Photo / Videographer' },
    { value: 138, label: 'Surveyor' },
    { value: 139, label: 'Tailor' },
    { value: 140, label: 'GOVERNMENT' },
    { value: 141, label: 'Assistant Manager' },
    { value: 142, label: 'Dealer' },
    { value: 143, label: 'Self Employed' },
    { value: 144, label: 'Textile Industry' },
    { value: 145, label: 'Automobile' },
    { value: 146, label: 'Wind Farm' },
    { value: 147, label: 'Others' }
]

// const FatherStatus= [
//   { value : 1, label:'Employed' },
//   { value : 2, label:'Business Man' },
//   { value : 3, label:'Professional' },
//   { value : 4, label:'Retired' },
//   { value : 5, label:'Not Employed' },
//   { value : 6, label:'Passed Away' }
// ]

// const MotherStatus= [
//   { value : 1, label:'Homemaker' },
//   { value : 2, label:'Employed' },
//   { value : 3, label:'Business Women' },
//   { value : 4, label:'Professional' },
//   { value : 5, label:'Retired' },
//   { value : 6, label:'Passed Away' }
// ]

const House = [
    { value: 0, label: '' },
    { value: 1, label: 'வாடகை வீடு' },
    { value: 2, label: 'சொந்த வீடு' },
]

// const God = [
//   { value : 1  , label:'அகத்தீஸ்வரன்' },
//   { value : 2  , label:'அகிலான்டீஸ்வரி' },
//   { value : 3  , label:'அங்காள பரமேஸ்வரி' },
//   { value : 4  , label:'அங்காளம்மன்' },
//   { value : 5  , label:'அங்காளீஸ்வரி' },
//   { value : 6  , label:'அண்ணமார்' },
//   { value : 7  , label:'அத்தனூர் அம்மன்' },
//   { value : 8  , label:'அனை கருப்பண்ண சாமி' },
//   { value : 9  , label:'அன்னமார் சாமி' },
//   { value : 10 , label:'அப்பாத்தாள்' },
//   { value : 11 , label:'அப்பிச்சிமார் மடம்' },
//   { value : 12 , label:'அம்மன்' },
//   { value : 13 , label:'அய்யனார்' },
//   { value : 14 , label:'அரசமர அங்காத்தாள்' },
//   { value : 15 , label:'அருங்கரை அம்மன்' },
//   { value : 16 , label:'அர்த்தநாதீஸ்வரர்' },
//   { value : 17 , label:'அழகு நாச்சியம்மன்' },
//   { value : 18 , label:'ஆணுர் அம்மன்' },
//   { value : 19 , label:'ஆயி அம்மன்' },
//   { value : 20 , label:'இராச சுவாமிகள்' },
//   { value : 21 , label:'இராமலிங்கமூர்த்தி' },
//   { value : 22 , label:'ஈஸ்வரன்' },
//   { value : 23 , label:'உக்கிரகாளியம்மன்' },
//   { value : 24 , label:'உன்னாமலை அம்மன்' },
//   { value : 25 , label:'உலகேஸ்வரன்' },
//   { value : 26 , label:'ஊமை கருமியண்ணன்' },
//   { value : 27 , label:'ஊமை கருமையண்ணசுவாமி' },
//   { value : 28 , label:'எட்டுக்கையம்மன்' },
//   { value : 29 , label:'கண்ணியம்மாள் கருப்பராயன்' },
//   { value : 30 , label:'கன்னிமார்' },
//   { value : 31 , label:'கரிச்சிகுமாரசாமி' },
//   { value : 32 , label:'கரிய காளியம்மன்' },
//   { value : 33 , label:'கரிய பெருமாள்' },
//   { value : 34 , label:'கருங்காளியம்மன்' },
//   { value : 35 , label:'கருச்சி அம்மன்' },
//   { value : 36 , label:'கருப்பனசாமி' },
//   { value : 37 , label:'கருப்பராயன்' },
//   { value : 38 , label:'கவுண்டச்சியம்மன்' },
//   { value : 39 , label:'காடையீஸ்வரர்' },
//   { value : 40 , label:'காடையீஸ்வாி' },
//   { value : 41 , label:'காண்டியன்' },
//   { value : 42 , label:'காண்டியம்மன்' },
//   { value : 43 , label:'காமாட்சி அம்மன்' },
//   { value : 44 , label:'காம்பிளி அம்மன்' },
//   { value : 45 , label:'காளிகுமார்' },
//   { value : 46 , label:'காளியம்மன்' },
//   { value : 47 , label:'குடுமந்தி மகரிஷி' },
//   { value : 48 , label:'குப்பன்னசாமி' },
//   { value : 49 , label:'குமாரமங்களம்' },
//   { value : 50 , label:'குலவிளக்கம்மன்' },
//   { value : 51 , label:'கூத்த நாயகியம்மன்' },
//   { value : 52 , label:'கொங்காளியம்மன்' },
//   { value : 53 , label:'கொத்தனுார் அம்மன்' },
//   { value : 54 , label:'சக்தி அங்காளம்மன்' },
//   { value : 55 , label:'சங்கர நாராயணன்' },
//   { value : 56 , label:'சடையப்பசாமி' },
//   { value : 57 , label:'சவுண்டம்மன்' },
//   { value : 58 , label:'சின்னசாமி' },
//   { value : 59 , label:'சின்னன்னன்' },
//   { value : 60 , label:'சின்னம்மன் பெரியம்மன்' },
//   { value : 61 , label:'சிவ செல்வநாயகி' },
//   { value : 62 , label:'சிவசெல்வி அம்மன்' },
//   { value : 63 , label:'சிவன்' },
//   { value : 64 , label:'சூரியம்மன் வஞ்சியம்மன்' },
//   { value : 65 , label:'செல்லாண்டி அம்மன்' },
//   { value : 66 , label:'செல்வ நாயகியம்மன்' },
//   { value : 67 , label:'செல்வகுமாரசாமி' },
//   { value : 68 , label:'சோளியம்மன்' },
//   { value : 69 , label:'சோளீஸ்வரர்' },
//   { value : 70 , label:'தங்கம்மன்' },
//   { value : 71 , label:'தம்பிரான் அம்மன்' },
//   { value : 72 , label:'தம்ராட்டி அம்மன்' },
//   { value : 73 , label:'தாய்நல்லியம்மன்' },
//   { value : 74 , label:'திருநீலகண்டம்மன்' },
//   { value : 75 , label:'திருப்பராட்டியம்மன்' },
//   { value : 76 , label:'தில்லவதியம்மன்' },
//   { value : 77 , label:'துர்க்கை அம்மன்' },
//   { value : 78 , label:'தேசநாயகியம்மன்' },
//   { value : 79 , label:'தேன்மொழியம்மன்' },
//   { value : 80 , label:'தேவநாயகி அம்மன்' },
//   { value : 81 , label:'தொட்டிச்சியம்மன்' },
//   { value : 82 , label:'நகுலடியான்' },
//   { value : 83 , label:'நல்லம்மாள்' },
//   { value : 84 , label:'நல்லாத்தாலம்மன்' },
//   { value : 85 , label:'நவலடியார்' },
//   { value : 86 , label:'நாட்ராயன்' },
//   { value : 87 , label:'நீலகாண்டியம்மன்' },
//   { value : 88 , label:'நீலியம்மன்.வேலப்பசாமி' },
//   { value : 89 , label:'பகவதி' },
//   { value : 90 , label:'படைவெட்டி அம்மன்' },
//   { value : 91 , label:'பத்ரகாளியம்மன்' },
//   { value : 92 , label:'பனையாத்தாள்' },
//   { value : 93 , label:'பரஞ்சோளியப்பன்' },
//   { value : 94 , label:'பரமசிவம்' },
//   { value : 95 , label:'பள்ளிக்கொண்ட பெருமாள்' },
//   { value : 96 , label:'பாச்சியம்மன்' },
//   { value : 97 , label:'பாண்டீஸ்வரர்' },
//   { value : 98 , label:'பாப்பினி அம்மன்' },
//   { value : 99 , label:'பிடாரி சத்தாயி அம்மன்' },
//   { value : 100, label:'பிராட்டிஅம்மன்' },
//   { value : 101, label:'புடராச்சியம்மன்' },
//   { value : 102, label:'புடராயன் கோவில்' },
//   { value : 103, label:'புது காளியம்மன்' },
//   { value : 104, label:'புது வெண்கரையம்மன்' },
//   { value : 105, label:'பெரிய நாச்சியம்மன்' },
//   { value : 106, label:'பெரிய நாயகியம்மன்' },
//   { value : 107, label:'பெரியம்மன்.சின்னம்மன்' },
//   { value : 108, label:'பெருமாள்' },
//   { value : 109, label:'பொங்கியம்மன்' },
//   { value : 110, label:'பொடராயர்' },
//   { value : 111, label:'பொடரிராயன்' },
//   { value : 112, label:'பொடளம்மன்' },
//   { value : 113, label:'பொடாரியம்மன்' },
//   { value : 114, label:'பொன் ஆச்சியம்மன்' },
//   { value : 115, label:'பொன் நாச்சியம்மன்' },
//   { value : 116, label:'பொன்அழகுநாச்சியம்மன்' },
//   { value : 117, label:'பொன்கவுண்டச்சியம்மன்' },
//   { value : 118, label:'பொன்காளியம்மன்' },
//   { value : 119, label:'பொன்னன் கானி' },
//   { value : 120, label:'பொன்னம் பாலசாமி' },
//   { value : 121, label:'பொன்னர்.சஙகர்' },
//   { value : 122, label:'மகாலட்சுமி' },
//   { value : 123, label:'மதுரகாளியம்மன்' },
//   { value : 124, label:'மதுரேஸ்வரர்' },
//   { value : 125, label:'மந்தீஸ்வரர் மரகதவள்ளி' },
//   { value : 126, label:'மயிலரங்கம்' },
//   { value : 127, label:'மாயன்' },
//   { value : 128, label:'மீனாட்சி அம்மன்' },
//   { value : 129, label:'முச்சிலியம்மன்' },
//   { value : 130, label:'முத்தன்' },
//   { value : 131, label:'முத்து குமாரசாமி' },
//   { value : 132, label:'முத்துசாமி' },
//   { value : 133, label:'முத்தையன் கோவில்' },
//   { value : 134, label:'முருங்கையம்மன்' },
//   { value : 135, label:'ரத்தினமூர்த்தி' },
//   { value : 136, label:'ரத்தினமூர்த்தி' },
//   { value : 137, label:'ராஜகாளியம்மன்' },
//   { value : 138, label:'ராஜராஜேஸ்வரி' },
//   { value : 139, label:'ராஜலிங்க மூர்த்தி' },
//   { value : 140, label:'ராஜா' },
//   { value : 141, label:'ராஜாத்தாள்' },
//   { value : 142, label:'வஞ்சியம்மன்' },
//   { value : 143, label:'வடுகநாத சாமி' },
//   { value : 144, label:'வரதராஜ பெருமாள்' },
//   { value : 145, label:'வள்ளியரசல் அம்மன்' },
//   { value : 146, label:'வாங்கல் அம்மன்' },
//   { value : 147, label:'விமுடீஸ்வரன்' },
//   { value : 148, label:'வீர காமாட்சி அம்மன்' },
//   { value : 149, label:'வீரக்குமார சாமி' },
//   { value : 150, label:'வீரங்கியன் சின்னய்யன்' },
//   { value : 151, label:'வீரமாச்சியம்மன்' },
//   { value : 152, label:'வீரமாத்தி அம்மன்' },
//   { value : 153, label:'வெங்கரை அம்மன்' },
//   { value : 154, label:'வெள்ளரசி அம்மன்' },
//   { value : 155, label:'வெள்ளாத்தாள்' },
//   { value : 156, label:'வேலாத்தாள்' },
//   { value : 157, label:'வேலு நாச்சியம்மன்' },
//   { value : 158, label:'ஸ்ரீ சாத்தந்தை அம்மன்' },
//   { value : 159, label:'ஸ்ரீ திருபருத்தி அம்மன்' },
//   { value : 160, label:'ஸ்ரீ தேவிஅம்மன்' },
//   { value : 161, label:'ஸ்ரீ நாலடியான் கருப்பன்னசாமி' },
//   { value : 162, label:'ஸ்ரீ பகவதி அம்மன்' },
//   { value : 163, label:'ஸ்ரீ பருத்தி அம்மன்' },
//   { value : 164, label:'ஸ்ரீ பார்வதி சடையப்பசாமி' },
//   { value : 165, label:'ஸ்ரீ மகாலட்சுமி அம்மன்' },
//   { value : 166, label:'ஸ்ரீனிவாச பெருமாள்' }
// ]

const Thosam = [
    { value: 1, label: 'சுத்தம்' },
    { value: 2, label: 'செவ்வாய்' },
    { value: 3, label: 'ராகு கேது' },
    { value: 4, label: 'ராகு கேது செவ்வாய்' },
]

// const Kirakam = [
//   { value : 1 , label:'5 சுத்தம்' },
//   { value : 2 , label:'காலசர்ப தோஷம்' },
//   { value : 3 , label:'காலகன்யா தோஷம்' },
//   { value : 4 , label:'7 8 சுத்தம்' },
//   { value : 5 , label:'2 7 8 சுத்தம்' },
//   { value : 6 , label:'7 8 சனி சூரி சுக்' },
// ]

const Raasi = [
    { value: 1, label: 'மேஷம்' },
    { value: 2, label: 'ரிஷபம்' },
    { value: 3, label: 'மிதுனம்' },
    { value: 4, label: 'கடகம்' },
    { value: 5, label: 'சிம்மம்' },
    { value: 6, label: 'கன்னி' },
    { value: 7, label: 'துலாம்' },
    { value: 8, label: 'விருச்சிகம்' },
    { value: 9, label: 'தனுசு' },
    { value: 10, label: 'மகரம்' },
    { value: 11, label: 'கும்பம்' },
    { value: 12, label: 'மீனம்' },
]

const RaasiNatchatram = {
    1: [{ label: 'அஸ்வினி', value: 1 },
    { label: 'பரணி', value: 2 },
    { label: 'கார்த்திகை 1', value: 3 },
    ],
    2: [{ label: 'கார்த்திகை 2,3,4', value: 4 },
    { label: 'ரோகினி', value: 5 },
    { label: 'மிருகசிரிஷம் 1, 2', value: 6 },
    ],
    3: [{ label: 'மிருகசிரிஷம் 3, 4', value: 7 },
    { label: 'திருவாதிரை', value: 8 },
    { label: 'புனர்பூசம் 1,2,3', value: 9 },
    ],
    4: [{ label: 'புனர்பூசம் 4', value: 10 },
    { label: 'பூசம்', value: 11 },
    { label: 'ஆயில்யம்', value: 12 },
    ],
    5: [{ label: 'மகம்', value: 13 },
    { label: 'பூரம்', value: 14 },
    { label: 'உத்திரம் 1', value: 15 },
    ],
    6: [{ label: 'உத்திரம் 2,3,4', value: 16 },
    { label: 'ஹஸ்தம்', value: 17 },
    { label: 'சித்திரை 1, 2', value: 18 },
    ],
    7: [{ label: 'சித்திரை 3, 4', value: 19 },
    { label: 'சுவாதி', value: 20 },
    { label: 'விசாகம் 1,2,3', value: 21 },
    ],
    8: [{ label: 'விசாகம் 4', value: 22 },
    { label: 'அனுசம்', value: 23 },
    { label: 'கேட்டை', value: 24 },
    ],
    9: [{ label: 'மூலம்', value: 25 },
    { label: 'பூராடம்', value: 26 },
    { label: 'உத்திராடம் 1', value: 27 },
    ],
    10: [{ label: 'உத்திராடம் 2,3,4', value: 28 },
    { label: 'திருவோணம்', value: 29 },
    { label: 'அவிட்டம் 1, 2', value: 30 },
    ],
    11: [{ label: 'அவிட்டம் 3, 4', value: 31 },
    { label: 'சதயம்', value: 32 },
    { label: 'பூரட்டாதி 1,2,3', value: 33 },
    ],
    12: [{ label: 'பூரட்டாதி 4', value: 34 },
    { label: 'உத்திரட்டாதி', value: 35 },
    { label: 'ரேவதி', value: 36 },
    ]
}

const Planets = [
    { value: 0, label: '' },
    { value: 1, label: 'சூரியன்' },
    { value: 2, label: 'சந்திரன்' },
    { value: 3, label: 'சுக்கிரன்' },
    { value: 4, label: 'செவ்வாய்' },
    { value: 5, label: 'புதன்' },
    { value: 6, label: 'குரு' },
    { value: 7, label: 'ராகு' },
    { value: 8, label: 'கேது' },
    { value: 9, label: 'சனி' },
]

const Bloodgroup = [
    { value: 0, label: '' },
    { value: 1, label: 'A+' },
    { value: 9, label: 'A1+' },
    { value: 2, label: 'A-' },
    { value: 10, label: 'A1-' },
    { value: 3, label: 'B+' },
    { value: 11, label: 'B1+' },
    { value: 4, label: 'B-' },
    { value: 12, label: 'B1-' },
    { value: 5, label: 'O+' },
    { value: 6, label: 'O-' },
    { value: 7, label: 'AB+' },
    { value: 13, label: 'A1B+' },
    { value: 8, label: 'AB-' },
    { value: 14, label: 'A1B-' },
]

const Districts = {
    1: [
        { value: 0, label: '' },
        { value: 1, label: 'Ariyalur' },
        { value: 2, label: 'Chennai' },
        { value: 3, label: 'Coimbatore' },
        { value: 4, label: 'Cuddalore' },
        { value: 5, label: 'Dharmapuri' },
        { value: 6, label: 'Dindigul' },
        { value: 7, label: 'Erode' },
        { value: 8, label: 'Kanchipuram' },
        { value: 9, label: 'Kanyakumari' },
        { value: 10, label: 'Karur' },
        { value: 11, label: 'Krishnagiri' },
        { value: 12, label: 'Madurai' },
        { value: 13, label: 'Nagapattinam' },
        { value: 14, label: 'Namakkal' },
        { value: 15, label: 'Nilgiris' },
        { value: 16, label: 'Perambalur' },
        { value: 17, label: 'Pudukkottai' },
        { value: 18, label: 'Ramanathapuram' },
        { value: 19, label: 'Salem' },
        { value: 20, label: 'Sivaganga' },
        { value: 21, label: 'Thanjavur' },
        { value: 22, label: 'Theni' },
        { value: 23, label: 'Thoothukudi (Tuticorin)' },
        { value: 24, label: 'Tiruchirappalli' },
        { value: 25, label: 'Tirunelveli' },
        { value: 26, label: 'Tiruppur' },
        { value: 27, label: 'Tiruvallur' },
        { value: 28, label: 'Tiruvannamalai' },
        { value: 29, label: 'Tiruvarur' },
        { value: 30, label: 'Vellore' },
        { value: 31, label: 'Viluppuram' },
        { value: 32, label: 'Virudhunagar' },
    ],
    2: [
        { value: 1, label: 'Anantapur' },
        { value: 2, label: 'Chittoor' },
        { value: 3, label: 'East Godavari' },
        { value: 4, label: 'Guntur' },
        { value: 5, label: 'Krishna' },
        { value: 6, label: 'Kurnool' },
        { value: 7, label: 'Prakasam' },
        { value: 8, label: 'Srikakulam' },
        { value: 9, label: 'Sri Potti Sriramulu Nellore' },
        { value: 10, label: 'Visakhapatnam' },
        { value: 11, label: 'Vizianagaram' },
        { value: 12, label: 'West Godavari' },
        { value: 13, label: 'YSR District, Kadapa (Cuddapah)' },
    ],
    3: [
        { value: 1, label: 'Anjaw' },
        { value: 2, label: 'Changlang' },
        { value: 3, label: 'DibangValley' },
        { value: 4, label: 'EastKameng' },
        { value: 5, label: 'EastSiang' },
        { value: 6, label: 'KraDaadi' },
        { value: 7, label: 'KurungKumey' },
        { value: 8, label: 'Lohit' },
        { value: 9, label: 'Longding' },
        { value: 10, label: 'LowerDibangValley' },
        { value: 11, label: 'LowerSiang' },
        { value: 12, label: 'LowerSubansiri' },
        { value: 13, label: 'Namsai' },
        { value: 14, label: 'PapumPare' },
        { value: 15, label: 'Siang' },
        { value: 16, label: 'Tawang' },
        { value: 17, label: 'Tirap' },
        { value: 18, label: 'UpperSiang' },
        { value: 19, label: 'UpperSubansiri' },
        { value: 20, label: 'WestKameng' },
        { value: 21, label: 'WestSiang', }
    ],
    4: [
        { value: 1, label: 'Baksa' },
        { value: 2, label: 'Barpeta' },
        { value: 3, label: 'Biswanath' },
        { value: 4, label: 'Bongaigaon' },
        { value: 5, label: 'Cachar' },
        { value: 6, label: 'Charaideo' },
        { value: 7, label: 'Chirang' },
        { value: 8, label: 'Darrang' },
        { value: 9, label: 'Dhemaji' },
        { value: 10, label: 'Dhubri' },
        { value: 11, label: 'Dibrugarh' },
        { value: 12, label: 'Dima Hasao (North Cachar Hills)' },
        { value: 13, label: 'Goalpara' },
        { value: 14, label: 'Golaghat' },
        { value: 15, label: 'Hailakandi' },
        { value: 16, label: 'Hojai' },
        { value: 17, label: 'Jorhat' },
        { value: 18, label: 'Kamrup' },
        { value: 19, label: 'Kamrup Metropolitan' },
        { value: 20, label: 'Karbi Anglong' },
        { value: 21, label: 'Karimganj' },
        { value: 22, label: 'Kokrajhar' },
        { value: 23, label: 'Lakhimpur' },
        { value: 24, label: 'Majuli' },
        { value: 25, label: 'Morigaon' },
        { value: 26, label: 'Nagaon' },
        { value: 27, label: 'Nalbari' },
        { value: 28, label: 'Sivasagar' },
        { value: 29, label: 'Sonitpur' },
        { value: 30, label: 'South Salamara-Mankachar' },
        { value: 31, label: 'Tinsukia' },
        { value: 32, label: 'Udalguri' },
        { value: 33, label: 'West Karbi Anglong' },
    ],
    5: [
        { value: 1, label: 'Araria' },
        { value: 2, label: 'Arwal' },
        { value: 3, label: 'Aurangabad' },
        { value: 4, label: 'Banka' },
        { value: 5, label: 'Begusarai' },
        { value: 6, label: 'Bhagalpur' },
        { value: 7, label: 'Bhojpur' },
        { value: 8, label: 'Buxar' },
        { value: 9, label: 'Darbhanga' },
        { value: 10, label: 'East Champaran (Motihari)' },
        { value: 11, label: 'Gaya' },
        { value: 12, label: 'Gopalganj' },
        { value: 13, label: 'Jamui' },
        { value: 14, label: 'Jehanabad' },
        { value: 15, label: 'Kaimur (Bhabua)' },
        { value: 16, label: 'Katihar' },
        { value: 17, label: 'Khagaria' },
        { value: 18, label: 'Kishanganj' },
        { value: 19, label: 'Lakhisarai' },
        { value: 20, label: 'Madhepura' },
        { value: 21, label: 'Madhubani' },
        { value: 22, label: 'Munger (Monghyr)' },
        { value: 23, label: 'Muzaffarpur' },
        { value: 24, label: 'Nalanda' },
        { value: 25, label: 'Nawada' },
        { value: 26, label: 'Patna' },
        { value: 27, label: 'Purnia (Purnea)' },
        { value: 28, label: 'Rohtas' },
        { value: 29, label: 'Saharsa' },
        { value: 30, label: 'Samastipur' },
        { value: 31, label: 'Saran' },
        { value: 32, label: 'Sheikhpura' },
        { value: 33, label: 'Sheohar' },
        { value: 34, label: 'Sitamarhi' },
        { value: 35, label: 'Siwan' },
        { value: 36, label: 'Supaul' },
        { value: 37, label: 'Vaishali' },
        { value: 38, label: 'West Champaran' },
    ],
    6: [
        { value: 1, label: 'Balod' },
        { value: 2, label: 'Baloda Bazar' },
        { value: 3, label: 'Balrampur' },
        { value: 4, label: 'Bastar' },
        { value: 5, label: 'Bemetara' },
        { value: 6, label: 'Bijapur' },
        { value: 7, label: 'Bilaspur' },
        { value: 8, label: 'Dantewada (South Bastar)' },
        { value: 9, label: 'Dhamtari' },
        { value: 10, label: 'Durg' },
        { value: 11, label: 'Gariyaband' },
        { value: 12, label: 'Janjgir-Champa' },
        { value: 13, label: 'Jashpur' },
        { value: 14, label: 'Kabirdham (Kawardha)' },
        { value: 15, label: 'Kanker (North Bastar)' },
        { value: 16, label: 'Kondagaon' },
        { value: 17, label: 'Korba' },
        { value: 18, label: 'Korea (Koriya)' },
        { value: 19, label: 'Mahasamund' },
        { value: 20, label: 'Mungeli' },
        { value: 21, label: 'Narayanpur' },
        { value: 22, label: 'Raigarh' },
        { value: 23, label: 'Raipur' },
        { value: 24, label: 'Rajnandgaon' },
        { value: 25, label: 'Sukma' },
        { value: 26, label: 'Surajpur  ' },
        { value: 27, label: 'Surguja' },
    ],
    7: [
        { value: 1, label: 'North Goa' },
        { value: 2, label: 'South Goa' },
    ],
    8: [
        { value: 1, label: 'Ahmedabad' },
        { value: 2, label: 'Amreli' },
        { value: 3, label: 'Anand' },
        { value: 4, label: 'Aravalli' },
        { value: 5, label: 'Banaskantha (Palanpur)' },
        { value: 6, label: 'Bharuch' },
        { value: 7, label: 'Bhavnagar' },
        { value: 8, label: 'Botad' },
        { value: 9, label: 'Chhota Udepur' },
        { value: 10, label: 'Dahod' },
        { value: 11, label: 'Dangs (Ahwa)' },
        { value: 12, label: 'Devbhoomi Dwarka' },
        { value: 13, label: 'Gandhinagar' },
        { value: 14, label: 'Gir Somnath' },
        { value: 15, label: 'Jamnagar' },
        { value: 16, label: 'Junagadh' },
        { value: 17, label: 'Kachchh' },
        { value: 18, label: 'Kheda (Nadiad)' },
        { value: 19, label: 'Mahisagar' },
        { value: 20, label: 'Mehsana' },
        { value: 21, label: 'Morbi' },
        { value: 22, label: 'Narmada (Rajpipla)' },
        { value: 23, label: 'Navsari' },
        { value: 24, label: 'Panchmahal (Godhra)' },
        { value: 25, label: 'Patan' },
        { value: 26, label: 'Porbandar' },
        { value: 27, label: 'Rajkot' },
        { value: 28, label: 'Sabarkantha (Himmatnagar)' },
        { value: 29, label: 'Surat' },
        { value: 30, label: 'Surendranagar' },
        { value: 31, label: 'Tapi (Vyara)' },
        { value: 32, label: 'Vadodara' },
        { value: 33, label: 'Valsad' },
    ],
    9: [
        { value: 1, label: 'Ambala' },
        { value: 2, label: 'Bhiwani' },
        { value: 3, label: 'Charkhi Dadri' },
        { value: 4, label: 'Faridabad' },
        { value: 5, label: 'Fatehabad' },
        { value: 6, label: 'Gurgaon' },
        { value: 7, label: 'Hisar' },
        { value: 8, label: 'Jhajjar' },
        { value: 9, label: 'Jind' },
        { value: 10, label: 'Kaithal' },
        { value: 11, label: 'Karnal' },
        { value: 12, label: 'Kurukshetra' },
        { value: 13, label: 'Mahendragarh' },
        { value: 14, label: 'Mewat' },
        { value: 15, label: 'Palwal' },
        { value: 16, label: 'Panchkula' },
        { value: 17, label: 'Panipat' },
        { value: 18, label: 'Rewari' },
        { value: 19, label: 'Rohtak' },
        { value: 20, label: 'Sirsa' },
        { value: 21, label: 'Sonipat' },
        { value: 22, label: 'Yamunanagar' },
    ],
    10: [
        { value: 1, label: 'Bilaspur' },
        { value: 2, label: 'Chamba' },
        { value: 3, label: 'Hamirpur' },
        { value: 4, label: 'Kangra' },
        { value: 5, label: 'Kinnaur' },
        { value: 6, label: 'Kullu' },
        { value: 7, label: 'Lahaul & Spiti' },
        { value: 8, label: 'Mandi' },
        { value: 9, label: 'Shimla' },
        { value: 10, label: 'Sirmaur (Sirmour)' },
        { value: 11, label: 'Solan' },
        { value: 12, label: 'Una' },
    ],
    11: [
        { value: 1, label: 'Anantnag' },
        { value: 2, label: 'Bandipore' },
        { value: 3, label: 'Baramulla' },
        { value: 4, label: 'Budgam' },
        { value: 5, label: 'Doda' },
        { value: 6, label: 'Ganderbal' },
        { value: 7, label: 'Jammu' },
        { value: 8, label: 'Kargil' },
        { value: 9, label: 'Kathua' },
        { value: 10, label: 'Kishtwar' },
        { value: 11, label: 'Kulgam' },
        { value: 12, label: 'Kupwara' },
        { value: 13, label: 'Leh' },
        { value: 14, label: 'Poonch' },
        { value: 15, label: 'Pulwama' },
        { value: 16, label: 'Rajouri' },
        { value: 17, label: 'Ramban' },
        { value: 18, label: 'Reasi' },
        { value: 19, label: 'Samba' },
        { value: 20, label: 'Shopian' },
        { value: 21, label: 'Srinagar' },
        { value: 22, label: 'Udhampur' },
    ],
    12: [
        { value: 1, label: 'Bokaro' },
        { value: 2, label: 'Chatra' },
        { value: 3, label: 'Deoghar' },
        { value: 4, label: 'Dhanbad' },
        { value: 5, label: 'Dumka' },
        { value: 6, label: 'East Singhbhum' },
        { value: 7, label: 'Garhwa' },
        { value: 8, label: 'Giridih' },
        { value: 9, label: 'Godda' },
        { value: 10, label: 'Gumla' },
        { value: 11, label: 'Hazaribag' },
        { value: 12, label: 'Jamtara' },
        { value: 13, label: 'Khunti' },
        { value: 14, label: 'Koderma' },
        { value: 15, label: 'Latehar' },
        { value: 16, label: 'Lohardaga' },
        { value: 17, label: 'Pakur' },
        { value: 18, label: 'Palamu' },
        { value: 19, label: 'Ramgarh' },
        { value: 20, label: 'Ranchi' },
        { value: 21, label: 'Sahibganj' },
        { value: 22, label: 'Seraikela-Kharsawan' },
        { value: 23, label: 'Simdega' },
        { value: 24, label: 'West Singhbhum' },
    ],
    13: [
        { value: 1, label: 'Bagalkot' },
        { value: 2, label: 'Ballari (Bellary)' },
        { value: 3, label: 'Belagavi (Belgaum)' },
        { value: 4, label: 'Bengaluru (Bangalore) Rural' },
        { value: 5, label: 'Bengaluru (Bangalore) Urban' },
        { value: 6, label: 'Bidar' },
        { value: 7, label: 'Chamarajanagar' },
        { value: 8, label: 'Chikballapur' },
        { value: 9, label: 'Chikkamagaluru (Chikmagalur)' },
        { value: 10, label: 'Chitradurga' },
        { value: 11, label: 'Dakshina Kannada' },
        { value: 12, label: 'Davangere' },
        { value: 13, label: 'Dharwad' },
        { value: 14, label: 'Gadag' },
        { value: 15, label: 'Hassan' },
        { value: 16, label: 'Haveri' },
        { value: 17, label: 'Kalaburagi (Gulbarga)' },
        { value: 18, label: 'Kodagu' },
        { value: 19, label: 'Kolar' },
        { value: 20, label: 'Koppal' },
        { value: 21, label: 'Mandya' },
        { value: 22, label: 'Mysuru (Mysore)' },
        { value: 23, label: 'Raichur' },
        { value: 24, label: 'Ramanagara' },
        { value: 25, label: 'Shivamogga (Shimoga)' },
        { value: 26, label: 'Tumakuru (Tumkur)' },
        { value: 27, label: 'Udupi' },
        { value: 28, label: 'Uttara Kannada (Karwar)' },
        { value: 29, label: 'Vijayapura (Bijapur)' },
        { value: 30, label: 'Yadgir' },
    ],
    14: [
        { value: 1, label: 'Alappuzha' },
        { value: 2, label: 'Ernakulam' },
        { value: 3, label: 'Idukki' },
        { value: 4, label: 'Kannur' },
        { value: 5, label: 'Kasaragod' },
        { value: 6, label: 'Kollam' },
        { value: 7, label: 'Kottayam' },
        { value: 8, label: 'Kozhikode' },
        { value: 9, label: 'Malappuram' },
        { value: 10, label: 'Palakkad' },
        { value: 11, label: 'Pathanamthitta' },
        { value: 12, label: 'Thiruvananthapuram' },
        { value: 13, label: 'Thrissur' },
        { value: 14, label: 'Wayanad' },
    ],
    15: [
        { value: 1, label: 'Agar Malwa' },
        { value: 2, label: 'Alirajpur' },
        { value: 3, label: 'Anuppur' },
        { value: 4, label: 'Ashoknagar' },
        { value: 5, label: 'Balaghat' },
        { value: 6, label: 'Barwani' },
        { value: 7, label: 'Betul' },
        { value: 8, label: 'Bhind' },
        { value: 9, label: 'Bhopal' },
        { value: 10, label: 'Burhanpur' },
        { value: 11, label: 'Chhatarpur' },
        { value: 12, label: 'Chhindwara' },
        { value: 13, label: 'Damoh' },
        { value: 14, label: 'Datia' },
        { value: 15, label: 'Dewas' },
        { value: 16, label: 'Dhar' },
        { value: 17, label: 'Dindori' },
        { value: 18, label: 'Guna' },
        { value: 19, label: 'Gwalior' },
        { value: 20, label: 'Harda' },
        { value: 21, label: 'Hoshangabad' },
        { value: 22, label: 'Indore' },
        { value: 23, label: 'Jabalpur' },
        { value: 24, label: 'Jhabua' },
        { value: 25, label: 'Katni' },
        { value: 26, label: 'Khandwa' },
        { value: 27, label: 'Khargone' },
        { value: 28, label: 'Mandla' },
        { value: 29, label: 'Mandsaur' },
        { value: 30, label: 'Morena' },
        { value: 31, label: 'Narsinghpur' },
        { value: 32, label: 'Neemuch' },
        { value: 33, label: 'Panna' },
        { value: 34, label: 'Raisen' },
        { value: 35, label: 'Rajgarh' },
        { value: 36, label: 'Ratlam' },
        { value: 37, label: 'Rewa' },
        { value: 38, label: 'Sagar' },
        { value: 39, label: 'Satna' },
        { value: 40, label: 'Sehore' },
        { value: 41, label: 'Seoni' },
        { value: 42, label: 'Shahdol' },
        { value: 43, label: 'Shajapur' },
        { value: 44, label: 'Sheopur' },
        { value: 45, label: 'Shivpuri' },
        { value: 46, label: 'Sidhi' },
        { value: 47, label: 'Singrauli' },
        { value: 48, label: 'Tikamgarh' },
        { value: 49, label: 'Ujjain' },
        { value: 50, label: 'Umaria' },
        { value: 51, label: 'Vidisha' },
    ],
    16: [
        { value: 1, label: 'Ahmednagar' },
        { value: 2, label: 'Akola' },
        { value: 3, label: 'Amravati' },
        { value: 4, label: 'Aurangabad' },
        { value: 5, label: 'Beed' },
        { value: 6, label: 'Bhandara' },
        { value: 7, label: 'Buldhana' },
        { value: 8, label: 'Chandrapur' },
        { value: 9, label: 'Dhule' },
        { value: 10, label: 'Gadchiroli' },
        { value: 11, label: 'Gondia' },
        { value: 12, label: 'Hingoli' },
        { value: 13, label: 'Jalgaon' },
        { value: 14, label: 'Jalna' },
        { value: 15, label: 'Kolhapur' },
        { value: 16, label: 'Latur' },
        { value: 17, label: 'Mumbai City' },
        { value: 18, label: 'Mumbai Suburban' },
        { value: 19, label: 'Nagpur' },
        { value: 20, label: 'Nanded' },
        { value: 21, label: 'Nandurbar' },
        { value: 22, label: 'Nashik' },
        { value: 23, label: 'Osmanabad' },
        { value: 24, label: 'Palghar' },
        { value: 25, label: 'Parbhani' },
        { value: 26, label: 'Pune' },
        { value: 27, label: 'Raigad' },
        { value: 28, label: 'Ratnagiri' },
        { value: 29, label: 'Sangli' },
        { value: 30, label: 'Satara' },
        { value: 31, label: 'Sindhudurg' },
        { value: 32, label: 'Solapur' },
        { value: 33, label: 'Thane' },
        { value: 34, label: 'Wardha' },
        { value: 35, label: 'Washim' },
        { value: 36, label: 'Yavatmal' },
    ],
    17: [
        { value: 1, label: 'Bishnupur' },
        { value: 2, label: 'Chandel' },
        { value: 3, label: 'Churachandpur' },
        { value: 4, label: 'Imphal East' },
        { value: 5, label: 'Imphal West' },
        { value: 6, label: 'Jiribam' },
        { value: 7, label: 'Kakching' },
        { value: 8, label: 'Kamjong' },
        { value: 9, label: 'Kangpokpi' },
        { value: 10, label: 'Noney' },
        { value: 11, label: 'Pherzawl' },
        { value: 12, label: 'Senapati' },
        { value: 13, label: 'Tamenglong' },
        { value: 14, label: 'Tengnoupal' },
        { value: 15, label: 'Thoubal' },
        { value: 16, label: 'Ukhrul' },
    ],
    18: [
        { value: 1, label: 'East Garo Hills' },
        { value: 2, label: 'East Jaintia Hills' },
        { value: 3, label: 'East Khasi Hills' },
        { value: 4, label: 'North Garo Hills' },
        { value: 5, label: 'Ri Bhoi' },
        { value: 6, label: 'South Garo Hills' },
        { value: 7, label: 'South West Garo Hills ' },
        { value: 8, label: 'South West Khasi Hills' },
        { value: 9, label: 'West Garo Hills' },
        { value: 10, label: 'West Jaintia Hills' },
        { value: 11, label: 'West Khasi Hills' },
    ],
    19: [
        { value: 1, label: 'Aizawl' },
        { value: 2, label: 'Champhai' },
        { value: 3, label: 'Kolasib' },
        { value: 4, label: 'Lawngtlai' },
        { value: 5, label: 'Lunglei' },
        { value: 6, label: 'Mamit' },
        { value: 7, label: 'Saiha' },
        { value: 8, label: 'Serchhip' },
    ],
    20: [
        { value: 1, label: 'Dimapur' },
        { value: 2, label: 'Kiphire' },
        { value: 3, label: 'Kohima' },
        { value: 4, label: 'Longleng' },
        { value: 5, label: 'Mokokchung' },
        { value: 6, label: 'Mon' },
        { value: 7, label: 'Peren' },
        { value: 8, label: 'Phek' },
        { value: 9, label: 'Tuensang' },
        { value: 10, label: 'Wokha' },
        { value: 11, label: 'Zunheboto' },
    ],
    21: [
        { value: 1, label: 'Angul' },
        { value: 2, label: 'Balangir' },
        { value: 3, label: 'Balasore' },
        { value: 4, label: 'Bargarh' },
        { value: 5, label: 'Bhadrak' },
        { value: 6, label: 'Boudh' },
        { value: 7, label: 'Cuttack' },
        { value: 8, label: 'Deogarh' },
        { value: 9, label: 'Dhenkanal' },
        { value: 10, label: 'Gajapati' },
        { value: 11, label: 'Ganjam' },
        { value: 12, label: 'Jagatsinghapur' },
        { value: 13, label: 'Jajpur' },
        { value: 14, label: 'Jharsuguda' },
        { value: 15, label: 'Kalahandi' },
        { value: 16, label: 'Kandhamal' },
        { value: 17, label: 'Kendrapara' },
        { value: 18, label: 'Kendujhar (Keonjhar)' },
        { value: 19, label: 'Khordha' },
        { value: 20, label: 'Koraput' },
        { value: 21, label: 'Malkangiri' },
        { value: 22, label: 'Mayurbhanj' },
        { value: 23, label: 'Nabarangpur' },
        { value: 24, label: 'Nayagarh' },
        { value: 25, label: 'Nuapada' },
        { value: 26, label: 'Puri' },
        { value: 27, label: 'Rayagada' },
        { value: 28, label: 'Sambalpur' },
        { value: 29, label: 'Sonepur' },
        { value: 30, label: 'Sundargarh' },
    ],
    22: [
        { value: 1, label: 'Amritsar' },
        { value: 2, label: 'Barnala' },
        { value: 3, label: 'Bathinda' },
        { value: 4, label: 'Faridkot' },
        { value: 5, label: 'Fatehgarh Sahib' },
        { value: 6, label: 'Fazilka' },
        { value: 7, label: 'Ferozepur' },
        { value: 8, label: 'Gurdaspur' },
        { value: 9, label: 'Hoshiarpur' },
        { value: 10, label: 'Jalandhar' },
        { value: 11, label: 'Kapurthala' },
        { value: 12, label: 'Ludhiana' },
        { value: 13, label: 'Mansa' },
        { value: 14, label: 'Moga' },
        { value: 15, label: 'Muktsar' },
        { value: 16, label: 'Nawanshahr (Shahid Bhagat Singh Nagar)' },
        { value: 17, label: 'Pathankot' },
        { value: 18, label: 'Patiala' },
        { value: 19, label: 'Rupnagar' },
        { value: 20, label: 'Sahibzada Ajit Singh Nagar (Mohali)' },
        { value: 21, label: 'Sangrur' },
        { value: 22, label: 'Tarn Taran' },
    ],
    23: [
        { value: 1, label: 'Ajmer' },
        { value: 2, label: 'Alwar' },
        { value: 3, label: 'Banswara' },
        { value: 4, label: 'Baran' },
        { value: 5, label: 'Barmer' },
        { value: 6, label: 'Bharatpur' },
        { value: 7, label: 'Bhilwara' },
        { value: 8, label: 'Bikaner' },
        { value: 9, label: 'Bundi' },
        { value: 10, label: 'Chittorgarh' },
        { value: 11, label: 'Churu' },
        { value: 12, label: 'Dausa' },
        { value: 13, label: 'Dholpur' },
        { value: 14, label: 'Dungarpur' },
        { value: 15, label: 'Hanumangarh' },
        { value: 16, label: 'Jaipur' },
        { value: 17, label: 'Jaisalmer' },
        { value: 18, label: 'Jalore' },
        { value: 19, label: 'Jhalawar' },
        { value: 20, label: 'Jhunjhunu' },
        { value: 21, label: 'Jodhpur' },
        { value: 22, label: 'Karauli' },
        { value: 23, label: 'Kota' },
        { value: 24, label: 'Nagaur' },
        { value: 25, label: 'Pali' },
        { value: 26, label: 'Pratapgarh' },
        { value: 27, label: 'Rajsamand' },
        { value: 28, label: 'Sawai Madhopur' },
        { value: 29, label: 'Sikar' },
        { value: 30, label: 'Sirohi' },
        { value: 31, label: 'Sri Ganganagar' },
        { value: 32, label: 'Tonk' },
        { value: 33, label: 'Udaipur' },
    ],
    24: [
        { value: 1, label: 'East Sikkim' },
        { value: 2, label: 'North Sikkim' },
        { value: 3, label: 'South Sikkim' },
        { value: 4, label: 'West Sikkim' },
    ],
    25: [
        { value: 1, label: 'Adilabad' },
        { value: 2, label: 'Bhadradri Kothagudem' },
        { value: 3, label: 'Hyderabad' },
        { value: 4, label: 'Jagtial' },
        { value: 5, label: 'Jangaon' },
        { value: 6, label: 'Jayashankar Bhoopalpally' },
        { value: 7, label: 'Jogulamba Gadwal' },
        { value: 8, label: 'Kamareddy' },
        { value: 9, label: 'Karimnagar' },
        { value: 10, label: 'Khammam' },
        { value: 11, label: 'Komaram Bheem Asifabad' },
        { value: 12, label: 'Mahabubabad' },
        { value: 13, label: 'Mahabubnagar' },
        { value: 14, label: 'Mancherial' },
        { value: 15, label: 'Medak' },
        { value: 16, label: 'Medchal' },
        { value: 17, label: 'Nagarkurnool' },
        { value: 18, label: 'Nalgonda' },
        { value: 19, label: 'Nirmal' },
        { value: 20, label: 'Nizamabad' },
        { value: 21, label: 'Peddapalli' },
        { value: 22, label: 'Rajanna Sircilla' },
        { value: 23, label: 'Rangareddy' },
        { value: 24, label: 'Sangareddy' },
        { value: 25, label: 'Siddipet' },
        { value: 26, label: 'Suryapet' },
        { value: 27, label: 'Vikarabad' },
        { value: 28, label: 'Wanaparthy' },
        { value: 29, label: 'Warangal (Rural)' },
        { value: 30, label: 'Warangal (Urban)' },
        { value: 31, label: 'Yadadri Bhuvanagiri' },
    ],
    26: [
        { value: 1, label: 'Dhalai' },
        { value: 2, label: 'Gomati' },
        { value: 3, label: 'Khowai' },
        { value: 4, label: 'North Tripura' },
        { value: 5, label: 'Sepahijala' },
        { value: 6, label: 'South Tripura' },
        { value: 7, label: 'Unakoti' },
        { value: 8, label: 'West Tripura' },
    ],
    27: [
        { value: 1, label: 'Almora' },
        { value: 2, label: 'Bageshwar' },
        { value: 3, label: 'Chamoli' },
        { value: 4, label: 'Champawat' },
        { value: 5, label: 'Dehradun' },
        { value: 6, label: 'Haridwar' },
        { value: 7, label: 'Nainital' },
        { value: 8, label: 'Pauri Garhwal' },
        { value: 9, label: 'Pithoragarh' },
        { value: 10, label: 'Rudraprayag' },
        { value: 11, label: 'Tehri Garhwal' },
        { value: 12, label: 'Udham Singh Nagar' },
        { value: 13, label: 'Uttarkashi' },
    ],
    28: [
        { value: 1, label: 'Agra' },
        { value: 2, label: 'Aligarh' },
        { value: 3, label: 'Allahabad' },
        { value: 4, label: 'Ambedkar Nagar' },
        { value: 5, label: 'Amethi (Chatrapati Sahuji Mahraj Nagar)' },
        { value: 6, label: 'Amroha (J.P. Nagar)' },
        { value: 7, label: 'Auraiya' },
        { value: 8, label: 'Azamgarh' },
        { value: 9, label: 'Baghpat' },
        { value: 10, label: 'Bahraich' },
        { value: 11, label: 'Ballia' },
        { value: 12, label: 'Balrampur' },
        { value: 13, label: 'Banda' },
        { value: 14, label: 'Barabanki' },
        { value: 15, label: 'Bareilly' },
        { value: 16, label: 'Basti' },
        { value: 17, label: 'Bhadohi' },
        { value: 18, label: 'Bijnor' },
        { value: 19, label: 'Budaun' },
        { value: 20, label: 'Bulandshahr' },
        { value: 21, label: 'Chandauli' },
        { value: 22, label: 'Chitrakoot' },
        { value: 23, label: 'Deoria' },
        { value: 24, label: 'Etah' },
        { value: 25, label: 'Etawah' },
        { value: 26, label: 'Faizabad' },
        { value: 27, label: 'Farrukhabad' },
        { value: 28, label: 'Fatehpur' },
        { value: 29, label: 'Firozabad' },
        { value: 30, label: 'Gautam Buddha Nagar' },
        { value: 31, label: 'Ghaziabad' },
        { value: 32, label: 'Ghazipur' },
        { value: 33, label: 'Gonda' },
        { value: 34, label: 'Gorakhpur' },
        { value: 35, label: 'Hamirpur' },
        { value: 36, label: 'Hapur (Panchsheel Nagar)' },
        { value: 37, label: 'Hardoi' },
        { value: 38, label: 'Hathras' },
        { value: 39, label: 'Jalaun' },
        { value: 40, label: 'Jaunpur' },
        { value: 41, label: 'Jhansi' },
        { value: 42, label: 'Kannauj' },
        { value: 43, label: 'Kanpur Dehat' },
        { value: 44, label: 'Kanpur Nagar' },
        { value: 45, label: 'Kanshiram Nagar (Kasganj)' },
        { value: 46, label: 'Kaushambi' },
        { value: 47, label: 'Kushinagar (Padrauna)' },
        { value: 48, label: 'Lakhimpur - Kheri' },
        { value: 49, label: 'Lalitpur' },
        { value: 50, label: 'Lucknow' },
        { value: 51, label: 'Maharajganj' },
        { value: 52, label: 'Mahoba' },
        { value: 53, label: 'Mainpuri' },
        { value: 54, label: 'Mathura' },
        { value: 55, label: 'Mau' },
        { value: 56, label: 'Meerut' },
        { value: 57, label: 'Mirzapur' },
        { value: 58, label: 'Moradabad' },
        { value: 59, label: 'Muzaffarnagar' },
        { value: 60, label: 'Pilibhit' },
        { value: 61, label: 'Pratapgarh' },
        { value: 62, label: 'RaeBareli' },
        { value: 63, label: 'Rampur' },
        { value: 64, label: 'Saharanpur' },
        { value: 65, label: 'Sambhal (Bhim Nagar)' },
        { value: 66, label: 'Sant Kabir Nagar' },
        { value: 67, label: 'Shahjahanpur' },
        { value: 68, label: 'Shamali (Prabuddh Nagar)' },
        { value: 69, label: 'Shravasti' },
        { value: 70, label: 'Siddharth Nagar' },
        { value: 71, label: 'Sitapur' },
        { value: 72, label: 'Sonbhadra' },
        { value: 73, label: 'Sultanpur' },
        { value: 74, label: 'Unnao' },
        { value: 75, label: 'Varanasi' },
    ],
    29: [
        { value: 1, label: 'Alipurduar' },
        { value: 2, label: 'Bankura' },
        { value: 3, label: 'Birbhum' },
        { value: 4, label: 'Cooch Behar' },
        { value: 5, label: 'Dakshin Dinajpur (South Dinajpur)' },
        { value: 6, label: 'Darjeeling' },
        { value: 7, label: 'Hooghly' },
        { value: 8, label: 'Howrah' },
        { value: 9, label: 'Jalpaiguri' },
        { value: 10, label: 'Jhargram' },
        { value: 11, label: 'Kalimpong' },
        { value: 12, label: 'Kolkata' },
        { value: 13, label: 'Malda' },
        { value: 14, label: 'Murshidabad' },
        { value: 15, label: 'Nadia' },
        { value: 16, label: 'North 24 Parganas' },
        { value: 17, label: 'Paschim Medinipur (West Medinipur)' },
        { value: 18, label: 'Paschim (West) Burdwan (Bardhaman)' },
        { value: 19, label: 'Purba Burdwan (Bardhaman)' },
        { value: 20, label: 'Purba Medinipur (East Medinipur)' },
        { value: 21, label: 'Purulia' },
        { value: 22, label: 'South 24 Parganas' },
        { value: 23, label: 'Uttar Dinajpur (North Dinajpur)' },
    ],
    30: [
        { value: 1, label: 'Nicobar' },
        { value: 2, label: 'North and Middle Andaman' },
        { value: 3, label: 'South Andaman' },
    ],
    31: [
        { value: 1, label: 'Chandigarh' },
    ],
    32: [
        { value: 1, label: 'Dadra & Nagar Haveli' },
    ],
    33: [
        { value: 1, label: 'Daman' },
        { value: 2, label: 'Diu' },
    ],
    34: [
        { value: 1, label: 'Central Delhi' },
        { value: 2, label: 'East Delhi' },
        { value: 3, label: 'New Delhi' },
        { value: 4, label: 'North Delhi' },
        { value: 5, label: 'North East  Delhi' },
        { value: 6, label: 'North West  Delhi' },
        { value: 7, label: 'Shahdara' },
        { value: 8, label: 'South Delhi' },
        { value: 9, label: 'South East Delhi' },
        { value: 10, label: 'South West  Delhi' },
        { value: 11, label: 'West Delhi' },
    ],
    35: [
        { value: 1, label: 'Lakshadweep' },
    ],
    36: [
        { value: 1, label: 'Karaikal' },
        { value: 2, label: 'Mahe' },
        { value: 3, label: 'Pondicherry' },
        { value: 4, label: 'Yanam' },
    ],
}

const Color = [
    { value: 1, label: 'வெள்ளை' },
    { value: 2, label: 'மாநிறம்' },
    { value: 3, label: 'கருப்பு' },
]

const dobDays = [
    { label: '01', value: '01' },
    { label: '02', value: '02' },
    { label: '03', value: '03' },
    { label: '04', value: '04' },
    { label: '05', value: '05' },
    { label: '06', value: '06' },
    { label: '07', value: '07' },
    { label: '08', value: '08' },
    { label: '09', value: '09' },
    { label: '10', value: 10 },
    { label: '11', value: 11 },
    { label: '12', value: 12 },
    { label: '13', value: 13 },
    { label: '14', value: 14 },
    { label: '15', value: 15 },
    { label: '16', value: 16 },
    { label: '17', value: 17 },
    { label: '18', value: 18 },
    { label: '19', value: 19 },
    { label: '20', value: 20 },
    { label: '21', value: 21 },
    { label: '22', value: 22 },
    { label: '23', value: 23 },
    { label: '24', value: 24 },
    { label: '25', value: 25 },
    { label: '26', value: 26 },
    { label: '27', value: 27 },
    { label: '28', value: 28 },
    { label: '29', value: 29 },
    { label: '30', value: 30 },
    { label: '31', value: 31 },
]

const dobMonths = [
    { label: 'Jan', value: 1 },
    { label: 'Feb', value: 2 },
    { label: 'Mar', value: 3 },
    { label: 'Apr', value: 4 },
    { label: 'May', value: 5 },
    { label: 'Jun', value: 6 },
    { label: 'Jul', value: 7 },
    { label: 'Aug', value: 8 },
    { label: 'Sep', value: 9 },
    { label: 'Oct', value: 10 },
    { label: 'Nov', value: 11 },
    { label: 'Dec', value: 12 },
]

const dobYear = [
    { label: '1970', value: '1970' },
    { label: '1971', value: '1971' },
    { label: '1972', value: '1972' },
    { label: '1973', value: '1973' },
    { label: '1974', value: '1974' },
    { label: '1975', value: '1975' },
    { label: '1976', value: '1976' },
    { label: '1977', value: '1977' },
    { label: '1978', value: '1978' },
    { label: '1979', value: '1979' },
    { label: '1980', value: '1980' },
    { label: '1981', value: '1981' },
    { label: '1982', value: '1982' },
    { label: '1983', value: '1983' },
    { label: '1984', value: '1984' },
    { label: '1985', value: '1985' },
    { label: '1986', value: '1986' },
    { label: '1987', value: '1987' },
    { label: '1988', value: '1988' },
    { label: '1989', value: '1989' },
    { label: '1990', value: '1990' },
    { label: '1991', value: '1991' },
    { label: '1992', value: '1992' },
    { label: '1993', value: '1993' },
    { label: '1994', value: '1994' },
    { label: '1995', value: '1995' },
    { label: '1996', value: '1996' },
    { label: '1997', value: '1997' },
    { label: '1998', value: '1998' },
    { label: '1999', value: '1999' },
    { label: '2000', value: '2000' },
    { label: '2001', value: '2001' },
    { label: '2002', value: '2002' },
    { label: '2003', value: '2003' },
]
function getListofYearsFemale() {

    const d = new Date();
    let year = d.getFullYear();
    var years = [];
    for (let i = 0; i < 50; i++) {
        let age = year - (17 + i);
        let value = age;
        let val = value - 1;
        years.push({ label: val.toString(), value: val.toString() });
    }
    return years;
}
function getListofYearsMale() {

    const d = new Date();
    let year = d.getFullYear();
    var years = [];
    for (let i = 0; i < 50; i++) {
        let age = year - (20 + i);
        let value = age;
        let val = value - 1;
        years.push({ label: val.toString(), value: val.toString() });
    }
    return years;
}

function getDetails(arrayName, value) {
    var returnValue = ''
    arrayName?.map((data) => {
        if (data.value == value) {
            returnValue = data.label === '' ? returnValue : data.label
        }
    })
    return returnValue
}

function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;

    }
    return age;
}
const getStarValue = (gender, self, other) => {
    let starValue = []
    let arrayValue = gender === 1 ? MaleMatch[self] : FemaleMatch[self]
    arrayValue?.map((i) => {
        starValue = i[other] === undefined ? [0] : i[other]
        starValue = starValue[0]
    })

    return starValue
}
function isValidDate(dateString) {
    // First check for the pattern
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
        return false;

    // Parse the date parts to integers
    var parts = dateString.split("/");
    var day = parseInt(parts[1], 10);
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month === 0 || month > 12)
        return false;

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
        monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
};

export { getStarValue, getAge, getDetails, Gender, Register, Jaathgam, Kulam, Natchatram, FemaleMatch, dobYear, MaleMatch, Age, Education, Weight, Height, Country, States, Job, Numbers, House, Thosam, Raasi, Planets, Month, Days, Bloodgroup, Districts, Color, RaasiNatchatram, Hour, Minute, Time, dobDays, dobMonths, getListofYearsFemale, isValidDate, getListofYearsMale };