import React from "react";
import { useNavigate } from "react-router-dom";
import { variables, version } from "../../form/var";
import "./bar.css";

export default function BottomBar() {
  const navigate = useNavigate();
  const d = new Date();
  let year = d.getFullYear();

  return (
    <div className="bottom-bar row h-25">
      <div className="col-12 col-md-2"><img
        src="/img/kmsBottomLogo.png"
        className="b-b-img"
        alt="Kongu Matrimonial Serivice"
      /></div>

      <div className="col-12 col-md-2">

        <div className="adr-txt">{variables.companyName}</div>
        <div className="adr-txt">{variables.addL1}</div>
        <div className="adr-txt">{variables.addL2}</div>
        <div className="adr-txt">{variables.addL3}</div>
        <div className="adr-txt">{variables.addL4}</div>
        <div className="adr-txt">{variables.addL5}</div>
        <div className="adr-txt">{variables.addL6}</div>
        <div className="adr-txt">{variables.addL7}</div>
      </div>
      <div className="col-12 col-md-2">
        {" "}
        <h4 className="Q-link">Quick Links</h4>
        <div className="Q-link-wrap">
          <div className="Q-link-ins" onClick={() => navigate("/contect")}>
            Contact Us
          </div>
          <div className="Q-link-ins" onClick={() => navigate("/aboutUs")}>
            About Us
          </div>
          <div className="Q-link-ins" onClick={() => navigate("/services")}>
            Services
          </div>
          <div className="Q-link-ins" onClick={() => navigate("/photos")}>
            Photo Gallery
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <>
          <h5 className="Q-link">About KM</h5>
          <p className="abt-company-txt">
            நூறுக்கும் மேற்பட்ட நமது குல மக்களை ஒரு கூரையின் கீழ் கொண்டு வந்து
            மண விழா பந்தலில்லே அமரவைத்து ஆசிகள் வழங்கி அழகு பார்ப்பது தான் இந்த
            திருமண தகவல் மையத்தின் தலையாய பணி. கொங்கு இன மக்களாகிய நமது சமுதாயம்
            காக்கப்பட வேண்டும், நமது வாரிசுகள் நல்வழிபடுத்தப்பட வேண்டும். நாளைய
            சமுதாயம் கொங்கு சமுதாயமாக மலர வேண்டும் என்கின்ற ஆசையில் கொங்கு
            திரு.V.தனபாலன் அவர்களது சீறிய முயற்சியினால் தொடங்கப்பட்ட நிறுவனம்
            தான் கொங்கு திருமண தகவல் மையம்.
          </p>
        </>
      </div>
      <div className="b-b-line"></div>
      <div className="cr-txrt">
        {`Copyright © ${year} All rights reserved  |  PIXELIZE TECHNOLOGIES PVT, LTD. |  (${version})`}
      </div>
    </div>
  );
}
