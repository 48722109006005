
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import { AiOutlineUser, AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { BiGitPullRequest } from "react-icons/bi";
import {
  getDetails,
  getStarValue,
  Height,
  Kulam,
  Natchatram,
  Weight, getAge
} from "../../../components/utilities/Constants";
import StarRating from "../../../components/layout/starRating";
import { PageNavication } from "../../../form/pageNavication";
import { SendIntresetToServer } from "../../../store/sendIntrest/sendIntrestService";
import { useLocation, useNavigate } from "react-router-dom";
import { SkeletonPage } from "../../../components/utilities/skeletonPage";
import styles from './matchedProfile.module.css'
import { imgUrl } from "../../../store/API";
import { imageUrlSort } from "../../../components/utilities/functions";

export function MatchedAllProfile() {
  let { hasMatchedProfileAll, matchedProfileAll, userProfile, myInterstProfileAll, hasMyInterstProfileAll } = useSelector((state) => state.profile);
  let { interestList, hasLoading } = useSelector((state) => state.requestProfile);
  let { pageNumber } = useSelector((state) => state.pageNumber);
  let [pageNo, getPageNumber] = useState()
  let dispatch = useDispatch()
  const navigate = useNavigate();
  const { pathname } = useLocation()
  let lastIndex, firstIndex;
  const [interstProfile, getInterstSent] = useState({ clickedBtn: "", resentRequestId: [] });
  const [userAll, setUserAllData] = useState({ data: [], loading: false, title: "" })

  useEffect(() => {
    getPageNumber(Number(pageNumber) * 20)
  }, [pageNumber])

  useEffect(() => {
    let lastPath = pathname.split("/").pop()
    if (lastPath === "interstAllProfile") {
      setUserAllData({ data: [...myInterstProfileAll], loading: hasMyInterstProfileAll, title: "MyInterstProfile" })
    } else {
      setUserAllData({ data: [...matchedProfileAll], loading: hasMatchedProfileAll, title: "MatchedProfile" })
    }
  }, [pathname, hasMatchedProfileAll, matchedProfileAll, userProfile, myInterstProfileAll, hasMyInterstProfileAll])
  useEffect(() => {
    let id = []
    if (interestList) {
      interestList.forEach((val) => {
        id.push(val.auth_id)
      })
      getInterstSent({ ...interstProfile, resentRequestId: id });
    }
  }, [interestList])

  const actionViewProfile = (e) => {
    if (e.target.id) {
      navigate(`/viewProfile/${e.target.id}`);
    } else {
      console.log("id missing")
    }
  }
  const actionSentInterst = (e, id) => {
    if (id && e.target.id === "Sent Interest") {
      getInterstSent({ ...interstProfile, clickedBtn: id });
      dispatch(SendIntresetToServer(id))
    } else {
      console.log("id mising")
    }

  }
  const getProfileList = () => {
    var data = []
    firstIndex = pageNo - 20
    lastIndex = pageNo
    userAll?.data?.forEach((i, index) => {
      if (index >= firstIndex && index < lastIndex && i.accepted !== 2) {
        data.push(
          <Fragment>
            <div className={`${styles.mb_5}`}>
              <div className={`${styles.border} ${styles.row}`}>
                <div className={`${styles.col_md_auto} ${styles.col_4}`}>
                  <div className={`${styles.text_center} ${styles.text_md_left} ${styles.pt_3} ${styles.pt_md_0}`}>
                    <img src={!i.images[0].targetName ? i.gender === 1 ? "/img/boy.png" : "/img/girl.png" : imgUrl + imageUrlSort(i?.images[0]?.path)} className={`${styles.img_fit} ${styles.mw_100} ${styles.size_150px} ${styles.size_md_250px} ${styles.rounded_circle} ${styles.md_rounded_0}`} alt=""></img>
                  </div>
                </div>
                <div className={` ${styles.col_8} ${styles.position_static} ${styles.d_flex} ${styles.align_items_center}`}>

                  <div className={`${styles.px_md_4} ${styles.p_3} ${styles.flex_grow_1}`}>
                    <h2 className={`${styles.h6} ${styles.fw_600} ${styles.fs_18} ${styles.text_truncate} ${styles.mb_1}`}>{i.name}</h2>
                    <div className={`${styles.mb_2} ${styles.fs_12}`}>
                      <span className={styles.opacity_60}>Member ID: </span>
                      <span className={`${styles.ml_4} ${styles.text_primary}`}>{"KMS " + i.unique_id}</span>
                    </div>
                    <table className={`${styles.w_100} ${styles.opacity_70} ${styles.mb_2} ${styles.fs_12}`}>
                      <tbody>
                        <tr>
                          {i.kulam === "0" || i.kulam === "" || i.kulam === "kulam" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                            <span>குலம்:</span></td>
                            <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>  {getDetails(Kulam, i.kulam)}</td></>}
                          {i.birthyear === "" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                            <span>பிறந்த நாள்:</span></td>
                            <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}> <i className="fa fa-birthday-cake fa-1x"></i>{" "}
                              {getAge(i.birthyear + "-" + i.birthmonth + "-" + i.birthdate)} {"வருடம்"}</td></>}
                        </tr>
                        <tr>
                          {i.height === "0" || i.height === "" || i.height === "height" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                            <span>உயரம்:</span></td>
                            <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>   <i className="fa fa-ruler-vertical fa-1x"></i>   {getDetails(Height, i.height)}</td></>}
                          {i.weight === "0" || i.weight === "" || i.weight === "weight" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                            <span>எடை:</span></td>
                            <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>  <i className="fa fa-weight fa-1x"></i> {getDetails(Weight, i.weight)}</td></>}
                        </tr>
                        <tr>
                          {i.natchatram === "0" || i.natchatram === "" || i.natchatram === "natchatram" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                            <span>நட்சத்திரம்:</span></td>
                            <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>  <i className="fa fa-star fa-1x"></i>{" "}
                              {getDetails(Natchatram, i.natchatram)}</td></>}
                          {i.education === "education" || i.weight === "" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                            <span>படிப்பு:</span></td>
                            <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>   <i class="fa fa-book fa-1x"></i> {i.education}</td></>}
                        </tr>
                        <tr>
                          <td className={`${styles.py_1} ${styles.w_25}`}>
                            <span>தோஷ பொருத்தம்:</span></td>
                          <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>
                            {userProfile.dosam === i.dosam ? "உண்டு" : "இல்லை"}</td>
                        </tr>
                        <tr>
                          <td className={`${styles.py_1} ${styles.w_25}`}>
                            <span>நட்சத்திர பொருத்தம்:</span></td>
                          <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>
                            <StarRating className={styles.star_rating} value={getStarValue(userProfile.gender, userProfile.natchatram, i.natchatram)} />({getStarValue(userProfile.gender, userProfile.natchatram, i.natchatram)}/12)
                          </td>
                          <td className={`${styles.py_1} ${styles.w_25}`}>
                            <span></span></td>
                        </tr>
                      </tbody>
                    </table>
                    <div className={`${styles.row} ${styles.gutters_5}`}>
                      <div className={styles.col} id={i.auth_id} onClick={actionViewProfile} >
                        <div style={{ textAlign: 'center' }} id={i.auth_id} name="View Profile" className={`${styles.text_reset} ${styles.c_pointer}`}>
                          <AiOutlineUser name="View Profile" id={i.auth_id} class={`fa-thin fa-user ${styles.fs_20} ${styles.text_primary}`} />
                          <span id={i.auth_id} className={`${styles.d_block} ${styles.fs_10} ${styles.opacity_60}`}>Full Profile</span>
                        </div>
                      </div>
                      <div className={styles.col}>
                        <div style={{ textAlign: 'center' }} name={i.accepted === 0 ? "Requested" : i.accepted === 1 ? "Accepted" : "Sent Intrest"} id={i.auth_id} className={`${styles.text_reset} ${styles.c_pointer}`}>
                          {interstProfile.clickedBtn == i.auth_id && hasLoading ?
                            <div class="bg-transparent" type="button" disabled>
                              <span class="spinner-grow spinner-grow-sm bg-danger border-0" role="status" aria-hidden="true"></span>
                            </div> :
                            i.accepted === 0 || interstProfile.resentRequestId?.includes(i.auth_id) ?
                              <BiGitPullRequest id="Request" className={`fa-thin fa-user ${styles.fs_20} ${styles.text_primary}`} />
                              :
                              i.accepted === 1 ?
                                <AiFillHeart id="Accepted" className={`fa-thin fa-user ${styles.fs_20} ${styles.text_primary}`} />
                                : i.accepted === 2 ?
                                  <AiFillHeart id="View Profile" className={`fa-thin fa-user ${styles.fs_20} ${styles.text_primary}`} />
                                  :
                                  <AiOutlineHeart id="Sent Interest" onClick={(e) => actionSentInterst(e, i.auth_id)} className={`fa-thin fa-user ${styles.fs_20} ${styles.text_primary}`} />}
                          <span className={`${styles.d_block} ${styles.fs_10} ${styles.opacity_60}`}>{i.accepted === 0 || interstProfile.resentRequestId?.includes(i.auth_id) ? "Requested" : i.accepted === 1 ? "Accepted" : "Sent Intrest"}</span>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }
    })
    return data
  }
  return (
    <div className="container">
      {userAll.loading ?
        <div>
          <div>
            <h2 className=" text-center mt-2 mb-2 fw-bold text-primary ">{userAll.title}</h2>
          </div>
          <div>
            {getProfileList()}
          </div>
          <div>
            <PageNavication length={userAll?.data?.length} />
          </div>
        </div> : <SkeletonPage />}
    </div>
  )
}