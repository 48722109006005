import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import BottomBar from "./components/layout/bottomBar";
import AdminDetails from "./Admin/adminData";
import Topbar from "./components/layout/Topbar";
import ViewProfile from "./pages/commonPage/viewProfile/viewprofile";
import Contect from "./pages/commonPage/contect";
import Home from "./pages/commonPage/home";
import Profile from "./pages/commonPage/profile";
import DataRegister from "./pages/register/registerStep1";
import RegisterStep2 from "./pages/register/registerStep2";
import RegisterStep3 from "./pages/register/registerStep3";
import Services from "./pages/commonPage/services";
import Dashboard from "./pages/dashboard";
import Login from "./pages/login/Login";
import PrivacyPolicy from "./pages/commonPage/PrivacyPolicy";
import AboutUs from "./pages/commonPage/aboutUs";
import Photos from "./pages/commonPage/photos";
import WideSearch from "./pages/commonPage/wideSearch";
import { MatchedAllProfile } from "./pages/commonPage/matchedProfile/matchedProfile";
import { UploadCertificate } from "./pages/commonPage/profile/uploadCertificate";
import { Print } from "./components/print/print";
import DeletingRequestComp from "./Admin/deletingRequestComp";
import { ResetPassword } from "./pages/login/resetPassword";
import ResetPasswordPage from "./Admin/resetpasswordPage";
import PaymentForm from "./pages/paymentGateway/paymentPage";


const Routers = () => {
  const loginData = useSelector((state) => state.login);
  const { admin } = useSelector((state) => state.admin);
  const isLoggedIn = localStorage.getItem('mat.token');
  // const [isToggle, setIsToggle] = useState(true);
  // console.log("token ", isLoggedIn);
  // console.log("admin", admin);
  if (isLoggedIn) {
    return (
      <BrowserRouter>
        <div className="wrapper dash-board-wrap">
          <Topbar />
          {admin === "ADMIN" ? (
            <div className="d-flex flex-column col-md-12">
              <div>
                <Routes>
                  <Route path="/admin" element={<AdminDetails />} />
                  <Route path="/profile/:id" element={<Profile />} />
                  <Route path="/certificate" element={<UploadCertificate />} />
                  <Route path="/register/step1" element={<DataRegister />} />
                  <Route path="/register/step2" element={<RegisterStep2 />} />
                  <Route path="/register/step3" element={<RegisterStep3 />} />
                  <Route path="/deletingRequest" element={<DeletingRequestComp />} />
                  <Route path="/passwordReset" element={<ResetPasswordPage />} />
                  <Route path="/print/:id" element={<Print />} />
                  <Route path="*" element={<Navigate to="/admin" />} />

                </Routes>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-column col-md-12">
              <div>
                <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/register" element={<DataRegister />} />
                  <Route path="/contect" element={<Contect />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                  <Route path="/aboutUs" element={<AboutUs />} />
                  <Route path="/photos" element={<Photos />} />
                  <Route path="/wideSearch" element={<WideSearch />} />
                  <Route path="/viewProfile/:id" element={<ViewProfile />} />
                  <Route path="/inrestedProfile/:id" element={<ViewProfile />} />
                  <Route path="/matchedAllProfile" element={<MatchedAllProfile />} />
                  <Route path="/interstAllProfile" element={<MatchedAllProfile />} />
                  <Route path="/payment" element={<PaymentForm />} />
                  <Route path="/print/:id" element={<Print />} />
                  <Route path="*" element={<Navigate to="/dashboard" />} />
                </Routes>
              </div>
              <BottomBar />
            </div>
          )}

        </div>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <div>
          <Topbar />
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/register/step1" element={<DataRegister />} />
            <Route path="/register/step2" element={<RegisterStep2 />} />
            <Route path="/register/step3" element={<RegisterStep3 />} />
            <Route path="/contect" element={<Contect />} />
            <Route path="/services" element={<Services />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/photos" element={<Photos />} />
            <Route path="/wideSearch" element={<WideSearch />} />
            <Route path="/login" element={<Login />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
          <BottomBar />
        </div>
      </BrowserRouter>
    );
  }
};

export default Routers;
