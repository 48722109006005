import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BasicProfile } from "../../components/HomePageComponents/BasicSelfProfileComponent";
import { ComeingRequest } from "../../components/HomePageComponents/comeingRequestProfileComponent";
import { MachedProfile } from "../../components/HomePageComponents/MachedProfileComponents";
import ResentAddedProfile from "../../components/HomePageComponents/resentAddedProfilesComponent";
import ViewedProfile from "../../components/HomePageComponents/viewedProfieComponent";
import { MyInterstProfile } from "../../components/HomePageComponents/MyInterstProfileComponent";

export default function Dashboard() {
  return (
    <div>
      <div className="row">
        <div className="col-md-9 mt-2" >
          <BasicProfile />
          <MachedProfile />
          <ResentAddedProfile />
        </div>
        <div className="col-md-15 mt-2"></div>
        <div className="col-md-3 mt-2" >
          <ComeingRequest />
          <ViewedProfile />
          <MyInterstProfile />
        </div> </div>
    </div>
  );
}