import axios from "axios";
import { Types } from "../types";
import { accessPremium } from "../API";
import { GetUserProfileForMobileNumber } from "../viewProfile/viewProfileService";

export const viewMobileNumber = (id) => {
    return (dispatch) => {
        dispatch({ type: Types.MOBILE_NUMBER_REQUEST });
        axios.post(`${accessPremium}/${id}`)
            .then((response) => {
                dispatch(GetUserProfileForMobileNumber(id))
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: response?.data?.message } })

            })
            .catch((err) => {
                dispatch({ type: Types.MOBILE_NUMBER__ERROR });
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.message } })
            });
    }
}