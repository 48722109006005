import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { baseProfileUrl, registerUrl } from '../API';
import { Types } from '../types';

export const UserRegister = (data, page = null, isDone = false) => {
  return (dispatch) => {
    dispatch({ type: page === 1 ? Types.REGISTER_ONE_REQUEST : Types.REGISTER_TWO_THREE_REQUEST });
    axios
      .post(registerUrl, data)
      .then((resp) => {
        if (resp.status === 200) {
          if (page === 1) {
            const addID = {
              auth_id: resp.data.user_id
            }
            let finalData = { ...data, ...addID }
            dispatch({ type: page === 1 ? Types.REGISTER_ONE_SUCCESS : Types.REGISTER_TWO_THREE_SUCCESS, payload: finalData });
          } else {
            dispatch({ type: Types.REGISTER_DONE_SUCCESS });
          }
        }
        return resp;
      })
      .catch((error) => {
        dispatch({ type: Types.REGISTER_ONE_ERROR });
        dispatch({ type: Types.ALERT_ERROR, payload: { message: error.response?.data?.message } })
        return error;
      });
  };

}
export const RegisterTwo = (Data) => {
  return (dispatch) => {
    dispatch({ type: Types.REGISTER_TWO_DONE, payload: Data });
  }
}
export const UserSkipStep = (data, page = null, isDone = false) => {
  return (dispatch) => {
    dispatch({ type: Types.REGISTER_DONE_SUCCESS });
    // return {};
    // axios
    //   .post(loginUrl, data)
    //   .then((resp) => {
    //     console.log(resp, "resp");
    //     if (resp.status === 200) {
    //       dispatch({ type: Types.REGISTER_ONE_SUCCESS, payload: resp.data });
    //     } else {
    //       dispatch({
    //         type: Types.ALERT_ERROR,
    //         payload: { message: resp.data.message },
    //       });
    //       dispatch({ type: Types.REGISTER_ONE_ERROR });
    //     }
    //   })
    //   .catch((error) => {
    //     dispatch({
    //       type: Types.ALERT_ERROR,
    //       payload: { message: error.response?.data?.message },
    //     });
    //     dispatch({ type: Types.REGISTER_ONE_ERROR });
    //   });
  };

} 