import axios from 'axios';
import { updateUser, updateUserAdmin } from '../API';
import { Types } from '../types';

export const UserUpdate = (data, isAdmin = false, id) => {
  return (dispatch) => {
    dispatch({ type: Types.USER_UPDATE_REQUEST });
    return axios
      .post(isAdmin ? `${updateUserAdmin}/${id}` : updateUser, data)
      .then((resp) => {
        console.log(resp.status === 200, "resp");
        if (resp.status === 200) {
          dispatch({
            type: Types.ALERT_SUCCESS,
            payload: { message: resp.data.message },
          });
          dispatch({ type: Types.USER_UPDATE_SUCCESS });
          dispatch(isAdmin ? { type: Types.VIEW_PROFILE_SUCCESS, payload: data } : { type: Types.DASHBOARD_USERDATA, payload: data });
        } else {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: resp.data.message },
          });
          dispatch({
            type: Types.USER_UPDATE_ERROR,
            payload: { message: resp.data.message }
          });
        }
        return resp;
      })
      .catch((error) => {

        dispatch({
          type: Types.ALERT_ERROR,
          payload: { message: error.response?.data?.message },
        });
        dispatch({ type: Types.USER_UPDATE_ERROR });
        return error.response;
      });
  };

}