import { Types } from "../types";

let initialState = {
    hasLoading: false,
    requestStatus: false
}

export const RequestAccountReducer = (state = initialState, action) => {

    switch (action.type) {
        case Types.ACTION_REQUEST_PROFILE_REQUEST:
            return {
                hasLoading: true
            }
        case Types.ACTION_REQUEST_PROFILE_SUCCESS:
            return {
                ...state,
                requestStatus: action.payload,
                hasLoading: false
            }
        case Types.ACTION_REQUEST_PROFILE_ERROR:
            return {
                hasLoading: false
            }
        default:
            return state;
    }

}