import { Types } from "../types";

let initialState = {
    hasUpdateLoading: false,
}
export const userUpdateReducer = (state = initialState, action) => {
    console.log(action)
    switch (action.type) {
        case Types.USER_UPDATE_REQUEST:
            return {
                ...state,
                hasUpdateLoading: true
            }
        case Types.USER_UPDATE_SUCCESS:
            return {
                ...state,
                hasUpdateLoading: false,
            }
        case Types.USER_UPDATE_ERROR:
            return {
                ...state,
                hasUpdateLoading: false
            }
        default:
            return state;
    }
}