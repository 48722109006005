import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAge, Kulam, getDetails, Natchatram, Height, Weight } from "../utilities/Constants";
import { imgUrl, imgUrlFirst } from "../../store/API";
import { useDispatch } from "react-redux";
import { GetUserData } from "../../store/dashboard/dashboardService";
import { Skeleton } from "../utilities/skeleton";
import styles from './BasicSelfProfileComponent.module.css'

export function BasicProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userImage, setUserImage] = useState([]);
  const [userData, setUserData] = useState("");
  let { userProfile, hasUserProfile } = useSelector((state) => state.profile);
  let { userData: { auth_id, role }, isLoggedIn } = useSelector((state) => state.login);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(GetUserData(auth_id, role))
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setUserData(userProfile);
    const img = [...userProfile?.images ?? []].sort((a, b) => a.image - b.image)
    setUserImage(img)
  }, [userProfile, hasUserProfile]);

  const actionEditProfile = () => {
    navigate("/profile");
  };
  return (
    <div>
      {hasUserProfile ?
        <div className={styles.container}>
          <div className={`${styles.mb_5}`}>
            <div className={`${styles.border} ${styles.row}`}>
              <div className={`${styles.col_md_auto} ${styles.col_4}`}>
                <div className={`${styles.text_center} ${styles.text_md_left} ${styles.pt_3} ${styles.pt_md_0}`}>
                  {userImage?.length ?
                    <img src={userImage[0]?.targetName === null ? userData.gender === 1 ? "img/boy.png" : "img/girl.png" : imgUrlFirst + userImage[0]?.targetName} className={`img_fit ${styles.img_fit} ${styles.mw_100} ${styles.size_150px} ${styles.size_md_250px} ${styles.rounded_circle} ${styles.md_rounded_0}`} alt=""></img>
                    : <></>
                  }
                </div >
              </div >
              <div className={` ${styles.col_8} ${styles.position_static} ${styles.d_flex} ${styles.align_items_center}`}>
                <span style={{ paddingTop: '6px', paddingRight: '10px' }} className={`${styles.absolute_top_right} ${styles.px_4} ${styles.py_3}`}>
                  <button className={`btn-clr ml-2 mb-2 `}
                    onClick={actionEditProfile}>complete your Profile</button>
                </span>
                <div className={`${styles.px_md_4} ${styles.p_3} ${styles.flex_grow_1}`}>
                  <h2 className={`${styles.h6} ${styles.fw_600} ${styles.fs_18} ${styles.text_truncate} ${styles.mb_1}`}>{userData.name}</h2>
                  <div className={`${styles.mb_2} ${styles.fs_12}`}>
                    <span className={styles.opacity_60}>Member ID: </span>
                    <span className={`${styles.ml_4} ${styles.text_primary}`}>{"KMS " + userData.unique_id}</span>
                  </div>
                  <table className={`${styles.w_100} ${styles.opacity_70} ${styles.mb_2} ${styles.fs_12}`}>
                    <tbody>
                      <tr>
                        {userData.kulam === "0" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                          <span>குலம்:</span></td>
                          <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}> {getDetails(Kulam, userData.kulam)}</td></>}
                        {userData.birthyear === "" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                          <span>பிறந்த நாள்:</span></td>
                          <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}> <i className="fa fa-birthday-cake fa-1x"></i>{" "}
                            {getAge(userData.birthyear + "-" + userData.birthmonth + "-" + userData.birthdate)} {"வருடம்"}</td></>}
                      </tr>
                      <tr>
                        {userData.height === "0" || userData.height === "" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                          <span>உயரம்:</span></td>
                          <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>   <i className="fa fa-ruler-vertical fa-1x"></i>  {getDetails(Height, userData.height)}</td></>}
                        {userData.weight === "0" || userData.weight === "" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                          <span>எடை:</span></td>
                          <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>  <i className="fa fa-weight fa-1x"></i> {getDetails(Weight, userData.weight)}</td></>}
                      </tr>
                      <tr>
                        {userData.natchatram === "0" || userData.natchatram === "" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                          <span>நட்சத்திரம்:</span></td>
                          <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>  <i className="fa fa-star fa-1x"></i>{" "}
                            {getDetails(Natchatram, userData.natchatram)}</td></>}
                        {userData.education === "" ? <></> : <><td className={`${styles.py_1} ${styles.w_25}`}>
                          <span>படிப்பு:</span></td>
                          <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>   <i className="fa fa-book fa-1x"></i> {userData.education}</td></>}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div >
          </div >
        </div > : <Skeleton />
      }
    </div >
  )
}
