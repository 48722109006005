import { Types } from "../types";

let initialState = {
    requestLoading: false,

}

export const RequestMobileNumberReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.MOBILE_NUMBER_REQUEST:
            return {
                requestLoading: true
            }
        case Types.MOBILE_NUMBER_SUCCESS:
            return {
                requestLoading: false
            }
        case Types.MOBILE_NUMBER__ERROR:
            return {
                requestLoading: false
            }
        default:
            return state;
    }

}