import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { variables } from "../../form/var";
import { UserLogin } from "../../store/login/loginService";
import "./login.css";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [popUp, setPopup] = useState(false);

  const [loginData, updateloginData] = useState({ password: "", mobile: "" });

  const { loading } = useSelector((state) => state.login);

  const loginHandle = () => {
    // let conditions = [
    //   { condition: 'required', name: 'mobile', value: loginData.mobile, text: 'Username' },
    //   { condition: 'required', name: 'password', value: loginData.password, text: 'Pass' },
    // ]
    // let errors = handleValidation(conditions);
    // if (!errors.isError) {
    // setError(errors.errors)
    dispatch(UserLogin(loginData));
    // } else {
    //   setError(errors.errors)
    // }
  };

  const changeHandle = (e) => {
    updateloginData(data => ({ ...data, [e.target.name]: e.target.value }));
  }
  // const actinInput = (e) => {
  //   updateloginData({ ...loginData, [e.target.name]: e.target.value });
  // };
  // const [num, setNum] = useState(null);
  // const [pass, setPass] = useState(null);

  // const numbe = (e) => {
  //   let a = document.getElementById("passwd").value;
  //   setNum(true);
  //   if (a?.length > 0) {
  //     setPass(true);
  //   } else {
  //     setPass(false);
  //   }
  // };
  // const pase = (e) => {
  //   let a = document.getElementById("mobilno").value;
  //   setPass(true);
  //   if (a?.length > 0) {
  //     setNum(true);
  //   } else {
  //     setNum(false);
  //   }
  // };
  // useEffect(() => {
  //   let a = document.getElementById("passwd")?.value;
  //   let b = document.getElementById("mobilno")?.value;
  //   if (a?.length > 0) {
  //     setPass(true);
  //   }
  //   if (b?.length > 0) {
  //     setNum(true);
  //   }
  // }, []);

  return (
    <Fragment >
      <div className="background" >
        {/* <img class="wave" src="./img/wave.png"></img> */}
        <div className="container top">
          {/* <div className="img">
           
          </div> */}
          <div className="login-content">
            <div className="login-cont">
              {/* <img src="https://firebasestorage.googleapis.com/v0/b/billing-a8bed.appspot.com/o/gallery%2Fw1d52?alt=media&token=1a862efe-8e94-4152-83b1-8e30959f9dcb" alt="" /> */}
              <h2 className="title">Welcome</h2>
              <div className={`input-div one ${loginData.mobile ? "focus" : ""} `}>
                <div className="i">
                  <i className="fas fa-user"></i>
                </div>
                <div className="div">
                  <h5>{variables.mobileno}</h5>
                  <input
                    name="mobile"
                    value={loginData.mobile}
                    type="text"
                    onChange={changeHandle}
                    className="input"
                  />
                </div>
                {error.mobile &&
                  <span className="login-condition-txt">{error.mobile}</span>}

              </div>
              <div className={`input-div pass ${loginData.password ? "focus" : ""}`}>
                <div className="i">
                  <i className="fas fa-lock"></i>
                </div>
                <div className="div">
                  <h5>{variables.password}</h5>
                  <input
                    id="passwd"
                    name="password"
                    onChange={changeHandle}
                    value={loginData.password}
                    type="password"
                    className="input"
                  />

                </div>
                {error.password &&
                  <span className="login-condition-txt">{error.password}</span>}

              </div>
              <button class="loginBtn" value="Login" disabled={loading ? loading : loginData.password === '' ? true : loginData.mobile === '' ? true : false} onClick={loginHandle}>
                {"உள்நுழைய"} {loading ? <span class=" ml-4 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <></>}
              </button>
              <button className="btn btn-link" onClick={() => navigate("/resetPassword")}>reset password</button>
            </div>
          </div>
        </div>
      </div>
    </Fragment >
  );
}
