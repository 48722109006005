import { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAge, getDetails, Kulam, Natchatram, Raasi } from "../utilities/Constants";
import { imgUrl } from "../../store/API";
import { ComingRequestedProfile } from "../../store/dashboard/dashboardService";
import { Skeleton } from "../utilities/skeleton";
import styles from './comeingRequestProfileComponent.module.css'
import { ActionToRequestProfile } from "../../store/acceptRequest/acceptRequestService";
import { GrView } from "react-icons/gr";
import { HiEye } from "react-icons/hi";
import { AiFillDislike, AiFillLike } from "react-icons/ai";
import { imageUrlSort } from "../utilities/functions";

export function ComeingRequest() {
  const [interestList, getRequestList] = useState([]);
  const [clickedBtn, setClickedBtn] = useState("");
  let { hasComingRequestProfile, comingRequestProfile } = useSelector((state) => state.profile);
  let { hasLoading } = useSelector((state) => state.request);
  let { isLoggedIn } = useSelector((state) => state.login);

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoggedIn) {
      setTimeout(() => {
        dispatch(ComingRequestedProfile());
      }, 1000)
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (comingRequestProfile) {
      getRequestList(comingRequestProfile);
    }
  }, [comingRequestProfile, hasComingRequestProfile]);

  const actionViewProfile = (id) => {
    //  dispatch(ViewSelectdProfile(e.target.id))
    if (id) {
      navigate(`/inrestedProfile/${id}`);
    } else {
      console.log("id missing", id)
    }

  }
  // const actionSendInterest = (e) => {
  //   dispatch(AcceptedIntrest(e.target.id))
  // }

  const actionRequestAction = (id, hasAccept) => {
    dispatch(ActionToRequestProfile(id, hasAccept ? 1 : 2))
    setClickedBtn(hasAccept ? "AcceptBtn" : "RejectBtn")
  }
  return (
    <Fragment>
      <h2 className="title-style  " style={{
        padding: '0px 10px 10px 10px', 'text-align': 'center',
        'font-weight': '700',
        'font-size': '30px',
        'color': '#007FFF'
      }}> Request Profiles</h2>
      <div style={{ textAlign: '-webkit-center' }} >

        {hasComingRequestProfile ?
          <div>
            {interestList.length ?
              interestList?.map((i) => {

                return (
                  <div>
                    <div key={i.key} className={`${styles.mb_5}`}>
                      <div className={`${styles.border} ${styles.row}`}>
                        {Number(i.verified) === 0 ? <></> : <div className={styles.my_badge}><div style={{
                          paddingTop: '6px', fontSize: '12px'
                        }}>Verified</div></div>}
                        <div className={`${styles.col_md_auto} ${styles.col_4}`}>
                          <div className={`${styles.text_center} ${styles.text_md_left} ${styles.pt_3} ${styles.pt_md_0}`}>
                            <img src={!i.images[0].targetName ? i.gender === 1 ? "/img/boy.png" : "/img/girl.png" : imgUrl + imageUrlSort(i?.images[0]?.path)} className={`${styles.img_fit} ${styles.mw_100} ${styles.size_150px} ${styles.size_md_250px} ${styles.rounded_circle} ${styles.md_rounded_0}`} alt=""></img>
                          </div>
                        </div>
                        <div className={` ${styles.col_8} ${styles.position_static} ${styles.d_flex} ${styles.align_items_center}`}>
                          <div className={`${styles.px_md_4} ${styles.p_3} ${styles.flex_grow_1}`}>
                            <h2 className={`${styles.h6} ${styles.fw_600} ${styles.fs_18} ${styles.text_truncate} ${styles.mb_1}`}>{i.name}</h2>
                            <table className={`${styles.w_100} ${styles.opacity_70} ${styles.mb_2} ${styles.fs_12}`}>
                              <tbody>
                                <tr>
                                  {i.birthyear === "" ? <></> : <> <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}> <i className="fa fa-birthday-cake fa-1x"></i>{" "}
                                    {getAge(i.birthyear + "-" + i.birthmonth + "-" + i.birthdate)} {"வருடம்"}</td></>}</tr>
                                <tr>
                                  {i.natchatram === "0" || i.natchatram === "" || i.natchatram === "natchatram" ? <></> : <><td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>  <i className="fa fa-star fa-1x"></i>{" "}
                                    {getDetails(Natchatram, i.natchatram)}</td></>}
                                </tr>
                                <tr>
                                  {i.raasi === "0" || i.raasi === "" ? <></> : <> <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}>   <i className="fa fa-ruler-vertical fa-1x"></i>   {getDetails(Raasi, i.raasi)}</td></>}
                                </tr>
                                <tr>
                                  {i.kulam === "0" || i.kulam === "" ? <></> : <> <td className={`${styles.py_1} ${styles.w_25} ${styles.fw_400}`}> {getDetails(Kulam, i.kulam)}</td></>}
                                </tr>
                              </tbody>
                            </table>
                            <div className={styles.col}>
                              <div className={`${styles.text_reset} ${styles.c_pointer} ${styles.val_grid} `}>
                                <div onClick={() => actionViewProfile(i.auth_id)} id={i.auth_id}>
                                  <HiEye size={25} className={styles.View}  ></HiEye>
                                  <span className={`${styles.d_block} ${styles.fs_10} ${styles.opacity_60} ${styles.View}`}>View</span>
                                </div>
                                {clickedBtn === "AcceptBtn" && hasLoading ? <div class="bg-transparent" type="button" disabled>
                                  <span class="spinner-grow spinner-grow-sm bg-success border-0" role="status" aria-hidden="true"></span>
                                </div> : <div>
                                  <AiFillLike size={25} onClick={() => actionRequestAction(i.auth_id, true)} className={styles.Accept} ></AiFillLike>
                                  <span className={`${styles.d_block} ${styles.fs_10} ${styles.opacity_60}${styles.Accept} `}>Accept</span>
                                </div>
                                }
                                {clickedBtn === "RejectBtn" && hasLoading ? <div class="bg-transparent" type="button" disabled>
                                  <span class="spinner-grow spinner-grow-sm bg-danger border-0" role="status" aria-hidden="true"></span>
                                </div> : <div>
                                  <AiFillDislike size={25} onClick={() => actionRequestAction(i.auth_id, false)} className={styles.Reject}></AiFillDislike>
                                  <span className={`${styles.d_block} ${styles.fs_10} ${styles.opacity_60} ${styles.Reject}`}>Reject</span>
                                </div>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
              ) : <div className={`${styles.mb_5}`}>
                <div className={`${styles.border} ${styles.row}`}> <h4 style={{ 'padding': '40px' }}>Request List Empty</h4> </div> </div>}</div> : <Skeleton />}
      </div>

    </Fragment >

  );
}


