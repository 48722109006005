import { useEffect } from "react";
import { dobDays, dobMonths, dobYear, getListofYearsFemale, getListofYearsMale } from "../components/utilities/Constants"
import Select from "./select"

export function DateSelector({ data, action }) {

  return (
    <div className="d-flex date-selector-div ">
      <Select
        options={getListofYearsFemale()}
        value={data?.birthyear ?? "1970"}
        name={"birthyear"}
        onchange={action} />
      <Select
        options={getListofYearsMale()}
        value={data?.birthyear ?? "1970"}
        name={"birthyear"}
        onchange={action} />
      <Select
        options={dobMonths}
        value={data?.birthmonth ?? 1}
        name={"birthmonth"}
        onchange={action}
      />
      <Select
        options={dobDays}
        value={data?.birthdate ?? "01"}
        name={"birthdate"}
        onchange={action}
      />
    </div>
  )

}