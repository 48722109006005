import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.css";
import axios from "axios";
import { getProfileUrlforAdmin } from "../store/API";
import "./adminData.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SkeletonPage } from "../components/utilities/skeletonPage";
import { Types } from "../store/types";
import { useDispatch } from "react-redux";
import { AdminPagenumber } from "../store/common/commonService";
import { BiFemale, BiMale, BiMaleFemale } from "react-icons/bi";

export function AdminDetails() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { userDataAll } = useSelector((state) => state.admin);
  const { adminPageNumber } = useSelector((state) => state.pageNumber);
  const { loading } = useSelector((state) => state.admin);
  const [userDataUpdate, setUserDataUpdate] = useState(userDataAll)
  const [gender, setGender] = useState({ male: '', female: '' })
  const [columnDefs, setColumnDefs] = useState({
    columnDefs: [
      { headerName: "No", field: "No", width: 100, sortable: true, unSortIcon: true },
      { headerName: "ID", field: "auth_id", width: 100, sortable: true, unSortIcon: true },
      { headerName: "Created", field: "created", width: 130, sortable: true, unSortIcon: true },
      { headerName: "Name", field: "name", width: 170, sortable: true, unSortIcon: true },
      { headerName: "Father", field: "father", width: 170, sortable: true, unSortIcon: true },
      { headerName: "Gender", field: "gender", width: 120, sortable: true, unSortIcon: true },
      { headerName: "Mobile", field: "mobile", width: 170, sortable: true, unSortIcon: true },
      { headerName: "Status", field: "status", width: 120, sortable: true, unSortIcon: true },
      { headerName: "Amount", field: "amount", width: 100, },
      {
        headerName: "Count", field: "paid", width: 100, cellStyle: params => {
          if (params.value === 0) {
            return { color: 'red', };
          } else if (params.value >= 0) {
            return { color: 'green', };
          } else {
            return { color: "black" }
          }
        }
      },
      {
        headerName: "View", sortable: false, filter: false, cellStyle: { borderColor: "transparent" },
        cellRendererFramework: (params) => {
          return <button className=" btn btn-success" onClick={() => handleClick(params.data)}>
            <i className="fa fa-eye mb-1"></i></button>
        }, width: 80
      }],
    rowData: [],
    searchValue: "",
    searchRowdata: [],
  })

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     dispatch(getAllUsers());
  //   }
  // }, [isLoggedIn]);
  console.log('userDataAll', userDataAll);

  useEffect(() => {
    if (userDataAll) {
      setUserDataUpdate(userDataAll);
    }
  }, [userDataAll]);

  useEffect(() => {
    let allData = [];
    if (userDataUpdate?.length) {
      userDataUpdate?.map((item, index) => {
        return (allData.push({
          No: index + 1,
          auth_id: item.auth_id,
          created: new Date(parseInt(item.created)).toLocaleDateString(),
          name: item.name,
          father: item.father ?? "-",
          gender: item?.gender === 1 ? "Male" : "Female",
          mobile: item.cmobile ?? "-",
          paid: item.amount ? (Number(item.noacc ?? 0) - Number(item.premiumcount)) : "-",
          status: item.married === 0 ? "Single" : "Married",
          amount: item.amount ?? "-"
        }))
      })
      setColumnDefs(
        {
          ...columnDefs,
          rowData: allData,
          searchRowdata: allData
        });
    }

  }, [userDataUpdate])

  const actionPagenumber = (params) => {
    const pagenumber = params.api.paginationGetCurrentPage()
    dispatch(AdminPagenumber(pagenumber))
  }
  const onGridReady = (params) => {
    params.api.paginationGoToPage(Number(adminPageNumber))
  }

  const handleClick = (item) => {
    navigate(`/profile/${item.auth_id}`, { state: { id: item.auth_id } });
    dispatch({ type: Types.VIEW_PROFILE_REQUEST });
    axios.get(`${getProfileUrlforAdmin}/${item.auth_id}`)
      .then((response) => {
        dispatch({ type: Types.VIEW_PROFILE_SUCCESS, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: Types.VIEW_PROFILE_ERROR });
        dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.message } })
      })
  }
  const searchHandler = (e) => {
    let data = columnDefs.searchRowdata.filter((item) => {
      if (item?.name) {
        return Object.keys(item).some(key =>
          item[key]?.toString().toLowerCase().includes(e.target.value)
        );
      }
    })
    setColumnDefs(
      {
        ...columnDefs,
        searchValue: e.target.value,
        rowData: data,
      });
  }
  useEffect(() => {
    let val = columnDefs.searchRowdata.filter((item) => {
      if (item?.name) {
        return Object.keys(item).some(key =>
          item[key]?.toString().toLowerCase().includes('female')
        );
      }
    })
    setGender({
      female: val.length,
      male: columnDefs.searchRowdata.length - val.length
    })
  }, [columnDefs]);
  return (
    <div
      className="ag-theme-alpine col-lg-12"
      style={{
        height: "530px",
      }}
    >

      <div className="dash">
        <div className="col-md-4s stretch-card grid-margin">
          <div className="card bg-gradient-danger card-img-holder text-white">
            <div className="card-body">
              <img src="./img/circle.png" className="card-img-absolute"></img>
              <h4 className="font-weight-normal mb-3">Total Members
                <BiMaleFemale size={'2em'} className="mdi mdi-chart-line mdi-24px float-right" />
              </h4>
              <h2 className="mb-5">{columnDefs.searchRowdata.length}</h2>
              {/* <h6 className="card-text">male users</h6> */}

            </div>
          </div>
        </div>
        <div className="col-md-4s stretch-card grid-margin">
          <div className="card bg-gradient-info card-img-holder text-white">
            <div className="card-body">
              <img src="./img/circle.png" className="card-img-absolute"></img>
              <h4 className="font-weight-normal mb-3">Male
                <BiMale size={'2em'} className="mdi mdi-chart-line mdi-24px float-right" /></h4>
              <h2 className="mb-5">{gender.male}</h2>
              {/* <h6 className="card-text">male users</h6> */}

            </div>
          </div>
        </div>
        <div className="col-md-4s stretch-card grid-margin">
          <div className="card bg-gradient-success card-img-holder text-white">
            <div className="card-body">
              <img src="./img/circle.png" className="card-img-absolute"></img>
              <h4 className="font-weight-normal mb-3">Female
                <BiFemale size={'2em'} className="mdi mdi-chart-line mdi-24px float-right" /></h4>
              <h2 className="mb-5">{gender.female}</h2>
              {/* <h6 className="card-text">male users</h6> */}

            </div>
          </div>
        </div>
      </div>
      {
        loading ?
          <SkeletonPage /> :
          <div
            className="ag-theme-alpine col-lg-12"
            style={{
              height: "720px",
              borderRadius: '7px',
              backgroundColor: 'white'
            }}
          >
            <div className="search-top">
              <h5 style={{ paddingTop: "30px" }} className="font-weight-bold mt-3">Users List - உறுப்பினர்கள் பட்டியல்</h5>
              <div className="wapsearch">
                <div class="searchbox">
                  <input type="search" className="input" placeholder="Search..." onChange={(e) => searchHandler(e)} value={columnDefs.searchValue} />
                  <div className="searchbtn">
                    <i className="fas fa-search"></i>
                  </div>
                </div>
              </div>
            </div>
            {/* <h5 style={{ paddingTop: "20px" }} className="font-weight-bold mt-3">Users List - உறுப்பினர்கள் பட்டியல்</h5> */}
            {/* <hr /> */}
            {/* <div style={{ paddingTop: "20px", paddingBottom: '20px' }} className="input-group w-50 mb-2">
              <input className="form-control py-2 mr-1 pr-5 rounded" placeholder="Search..." type="search" onChange={(e) => searchHandler(e)} value={columnDefs.searchValue} />
              <span className="input-group-append">
                <button className="btn rounded-pill border-0 ml-n5 pr-5" type="button">
                  <i className="fa fa-search bg-transparent"></i>
                </button>
              </span>
            </div> */}

            <AgGridReact
              columnDefs={columnDefs.columnDefs}
              rowData={columnDefs.rowData}
              onGridReady={onGridReady}
              pagination={true}
              paginationPageSize={20}
              rowHeight={50}
              onPaginationChanged={actionPagenumber}

            ></AgGridReact>

            <hr />
          </div>
      }
    </div >
  )
}
// class AdminDetails extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       columnDefs: [
//         { headerName: "No", field: "No", width: 100, sortable: true, unSortIcon: true },
//         { headerName: "ID", field: "auth_id", width: 100, sortable: true, unSortIcon: true },
//         { headerName: "Created", field: "created", width: 130, sortable: true, unSortIcon: true },
//         { headerName: "Name", field: "name", width: 170, sortable: true, unSortIcon: true },
//         { headerName: "Father", field: "father", width: 170, sortable: true, unSortIcon: true },
//         { headerName: "Gender", field: "gender", width: 120, sortable: true, unSortIcon: true },
//         { headerName: "Mobile", field: "mobile", width: 170, sortable: true, unSortIcon: true },
//         { headerName: "Paid", field: "paid", width: 100 },
//         {
//           headerName: "Action", sortable: false, filter: false,
//           cellRendererFramework: (params) => {
//             return <button className="view-users mt-1" onClick={() => this.handleClick(params.data)}>
//               <i className="fa fa-eye fa-eye- mb-1"></i>View User </button>
//           }, width: 180        
//         }],
//       rowData: [],
//       searchValue: "",
//       searchRowdata: []
//     };
//   }

//   handleClick = (item) => {
//     console.log(item.auth_id);
//     GetUserData(item.auth_id)
//     axios.get(`${baseProfileUrl}getProfile/${item.
//       auth_id}`)
//     .then((res) => {
//       let { data } = res;
//       // this.props.history.push(`/profile/${item.auth_id}`, {state:data});
//     })
//     .catch((error) => {
//       console.log(error);
//     })
//   }


//   searchHandler = (e) => {
//     this.setState({ searchValue: e.target.value }, () => {
//       this.searchFieldHandler();
//     })
//   }

//   searchFieldHandler = () => {
//     let { searchValue, searchRowdata } = this.state;

//     let data = searchRowdata.filter((item) => {
//       if (item?.name) {
//         return Object.keys(item).some(key =>
//           item[key]?.toString().toLowerCase().includes(searchValue)
//         );
//       }
//     })
//     this.setState({ rowData: data });
//   }

//   componentDidMount() {
//     this.getAllData();
//   }

//   getAllData = () => {
//     let allData = [];
//     axios.get(getAllUsers)
//       .then((res) => {
//         let { data } = res;
//         data.map((item, index) => {
//           return (allData.push({
//             No: index + 1,
//             auth_id: item.auth_id,
//             created: new Date(parseInt(item.created)).toLocaleDateString(),
//             name: item.name,
//             father: item.father,
//             gender: item?.gender === 1 ? "Male" : "Female",
//             mobile: item.mobile
//           }))
//         })
//         this.setState({ rowData: allData, searchRowdata: allData });
//       })
//       .catch((error) => {
//         console.log(error);
//       })
//   }

//   rowHeight = 43;

//   render() {
//     let { searchValue } = this.state;
//     return (
//       <div
//         className="ag-theme-alpine col-lg-12"
//         style={{
//           height: "530px",
//         }}
//       >
//         <h5 className="font-weight-bold mt-3">Users List - உறுப்பினர்கள் பட்டியல்</h5>
//         <hr />
//         <div className="input-group w-50 mb-2">
//           <input className="form-control py-2 mr-1 pr-5 rounded" placeholder="Search..." type="search" onChange={(e) => this.searchHandler(e)} value={searchValue} />
//           <span className="input-group-append">
//             <button className="btn rounded-pill border-0 ml-n5 pr-5" type="button">
//               <i className="fa fa-search bg-transparent"></i>
//             </button>
//           </span>
//         </div>
//         <AgGridReact
//           columnDefs={this.state.columnDefs}
//           rowData={this.state.rowData}
//           pagination={true}
//           paginationAutoPageSize={true}
//           rowHeight={this.rowHeight}

//         ></AgGridReact>
//         <hr />
//       </div>
//     );
//   }
// }

export default AdminDetails;
