import axios from 'axios';
import { paymentHistory, updateMarried, updatePayUser, updateStatus, updateVerifiedAdmin } from '../API';
import { Types } from '../types';
import { error } from 'jquery';

export const updateUserStatus = (id, status) => {
    const data = {
        status: status,
        auth_id: id
    }
    return (dispatch) => {
        dispatch({ type: Types.USER_STATUS_REQUEST });
        axios.post(`${updateStatus}/${id}`, data)
            .then((response) => {
                dispatch({ type: Types.USER_STATUS_SUCCESS, payload: data });
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: response?.data?.message } })
            })
            .catch((err) => {
                dispatch({ type: Types.USER_STATUS_ERROR });
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.message } })
            });
    }
}

export const updateMarriedStatus = (id, status) => {
    const data = {
        married: status,
        auth_id: id
    }
    return (dispatch) => {
        dispatch({ type: Types.USER_MARRIED_STATUS_REQUEST });
        axios.post(`${updateMarried}/${id}`, data)
            .then((response) => {
                dispatch({ type: Types.USER_MARRIED_STATUS_SUCCESS, payload: data });
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: response?.data?.message } })
            })
            .catch((err) => {
                dispatch({ type: Types.USER_MARRIED_STATUS_ERROR });
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.message } })
            });
    }
}

export const updatePayDetails = (payment, id, updateData) => {
    const data = {
        noacc: payment.count,
        amount: payment.amount,
        auth_id: id
    }
    return (dispatch) => {
        dispatch({ type: Types.USER_PAY_REQUEST });
        return axios.post(updatePayUser, data)
            .then((response) => {
                dispatch({ type: Types.USER_PAY_SUCCESS, payload: updateData });
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: response?.data?.message } })
                return response
            })
            .catch((err) => {
                dispatch({ type: Types.USER_PAY_ERROR });
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.message } })
                return err
            });
    }
}
export const updateUserVerified = (id, verifiedData) => {
    const data = {
        verified: verifiedData
    }
    return (dispatch) => {
        dispatch({ type: Types.USER_VERIFIED_REQUEST });
        axios.post(`${updateVerifiedAdmin}/${id}`, data)
            .then((response) => {
                dispatch({ type: Types.USER_VERIFIED_SUCCESS, payload: data });
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: response?.data?.message } })
                return response
            })
            .catch((err) => {
                dispatch({ type: Types.USER_VERIFIED_ERROR });
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.message } })
                return err
            });
    }
}

export const viewPaymentHistory = (id) => {

    return (dispatch) => {
        dispatch({ type: Types.VIEW_PAYMENT_HISTORY_REQUEST });
        return axios
            .get(`${paymentHistory}/${id}`)
            .then((response) => {
                console.log(response)
                dispatch({ type: Types.VIEW_PAYMENT_HISTORY_SUCCESS, payload: response.data });
                return response
            })
            .catch((err) => {
                dispatch({ type: Types.VIEW_PAYMENT_HISTORY_ERROR })
                return err
            });
    };
}