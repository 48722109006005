import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { GotoPage } from "../store/common/commonService";
import "./form.css";


export function PageNavication(length, perPage) {


    const dispatch = useDispatch();
    const [hasLast, isLastElement] = useState(true)
    const [hasFirst, isFirstelement] = useState(true)
    const [activeaPage, setActiveElement] = useState()
    const { pageNumber } = useSelector((state) => state.pageNumber)
    let page;
    let totalPage = 0;

    useEffect(() => {
        isFirstelement(1 === Number(pageNumber));
        isLastElement(totalPage === Number(pageNumber));
        setActiveElement(Number(pageNumber));
        window.scrollTo(0, 0)
    }, [pageNumber])

    const actionGetPage = (e) => {
        if (Number(e.target.id) !== pageNumber) {
            dispatch(GotoPage(Number(e.target.id)))
        }
    }
    const pageNation = () => {
        var data = []
        var i;
        page = length.length / 20
        totalPage = Math.round(page)
        if (activeaPage === 1 || activeaPage === 2) {
            for (i = activeaPage; i < activeaPage + 2; i++) {
                data.push(
                    <li className={activeaPage === i ? "page-item page-link active" : "page-item page-link"} id={i} onClick={actionGetPage}>{i}</li>
                )
            }
            data.push(<li className={"page-item page-link disabled"}>{"..."}</li>)

        } else if (activeaPage === totalPage || activeaPage === totalPage - 1) {
            data.push(<li className={"page-item page-link disabled"}>{"..."}</li>)
            for (i = activeaPage - 1; i <= activeaPage; i++) {
                data.push(
                    <li className={activeaPage === i ? "page-item page-link active" : "page-item page-link"} id={i} onClick={actionGetPage}>{i}</li>
                )
            }
        } else {
            if (activeaPage !== 3) {
                data.push(<li className={"page-item page-link disabled"}>{"..."}</li>)
            }
            for (i = activeaPage - 1; i <= activeaPage + 1; i++) {
                data.push(
                    <li className={activeaPage === i ? "page-item page-link active" : "page-item page-link"} id={i} onClick={actionGetPage}>{i}</li>
                )
            }
            if (activeaPage !== totalPage - 2) {
                data.push(<li className={"page-item page-link disabled"}>{"..."}</li>)
            }
        }
        return data
    }
    const actionNext = () => {
        dispatch(GotoPage(Number(activeaPage) + 1))

    }
    const actionPrevious = () => {
        dispatch(GotoPage(Number(activeaPage) - 1))
    }
    return (
        <div className="pagination-css">
            {length.length > 20 ?
                <nav aria-label="...">
                    <ul className="pagination pagination-sm">
                        <li className={hasFirst ? "page-item page-link disabled" : "page-item page-link"} onClick={actionPrevious}>Previous</li>
                        {activeaPage !== 1 ? <li className={"page-item page-link"} id={1} onClick={actionGetPage}>1</li> : <></>}
                        {pageNation()}
                        {activeaPage === totalPage ?
                            <></> : <li className={activeaPage === totalPage ? "page-item page-link active" : "page-item page-link"} id={totalPage} onClick={actionGetPage}>{totalPage}</li>
                        }
                        <li className={hasLast ? "page-item page-link disabled" : "page-item page-link"} onClick={actionNext}>  Next </li>
                    </ul>
                </nav> : <></>}
        </div>
    )
} 
