import React, { useEffect, useState } from "react";
import { variables } from "../../form/var";
import { CustomTable } from "../../form/table";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserRegister, UserSkipStep } from "../../store/register/registerService";
import { Types } from "../../store/types";
export default function RegisterStep3() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  var { register, twoLoading } = useSelector((state) => state.register);
  const [update, registerUpdate] = useState(register)

  useEffect(() => {
    registerUpdate(register)
  }, [register])

  const selectUpdateFiled = (event) => {
    let name;
    let sendValue = [];
    event?.map((data) => {
      name = data.name;
      sendValue.push(data.value)
    });
    registerUpdate({
      ...update,
      [name]: sendValue
    });
  };


  const skipSteps = () => {
    dispatch(UserSkipStep());
    dispatch({
      type: Types.ALERT_SUCCESS,
      payload: { message: `User ${register.name} Created Successfully`, duration: 1000 },
    });
    setTimeout(() => {
      navigate('/login');
    }, 1000);
  }

  const submitHandle = (e) => {
    e.preventDefault();
    dispatch({
      type: Types.ALERT_SUCCESS,
      payload: { message: `User ${register.name} Created Successfully`, duration: 1000 },
    });
    dispatch(UserRegister(update, 3, true))
    setTimeout(() => {
      navigate('/login');
    }, 1000);
  }

  return (
    <div className="step3bg">
      <form noValidate={false}>
        <div className="Card-Div parentstep3warp step3titl">
          <p className="step3titl p-labelStep3">{variables.rashi}</p>
          <CustomTable
            hasedit={false}
            onchange={selectUpdateFiled}
            stateData={update}
            innerHeading={variables.rashi}
          />
          <div>
            <p className="step3titl p-labelStep3">{variables.Navaamsam}</p>
            <CustomTable
              hasedit={false}
              hasRaasai={false}
              onchange={selectUpdateFiled}
              stateData={update}
              innerHeading={variables.Navaamsam}
            />{" "}
            <div className="col-lg-12 col-md-12 d-md-none d-sm-block">
              <p>Fill the above form like this</p>
              <img src="rasiKattam.png" />
            </div>
            <div className="btnGroup step3btns">
              <button type="button" className=" step3SkipBtn" onClick={skipSteps}>
                Skip
              </button>
              <div className=" btnGroup btns-step-1">
                <button onClick={submitHandle} type="submit" class="nextBtn" disabled={twoLoading}>
                  Next
                  {twoLoading ? <span class=" ml-4 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <></>}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
