import { Types } from "../types";

const initState = {
    userProfile: {},
    matchedProfile: [],
    matchedProfileAll: [],
    comingRequestProfileAll: [],
    comingRequestProfile: [],
    myInterstProfile: [],
    myInterstProfileAll: [],
    resentAddedProfile: [],
    viewedProfile: [],
    hasUserProfile: false,
    hasMatchedProfile: false,
    hasMatchedProfileAll: false,
    hasComingRequestProfileAll: false,
    hasComingRequestProfile: false,
    hasMyInterstProfile: false,
    hasMyInterstProfileAll: false,
    hasResentAddedProfile: false,
    hasViewedProfile: false,

}

export const DashboardReducer = (state = initState, action) => {
    switch (action.type) {
        //user data 
        case Types.DASHBOARD_USERDATA_REQUEST:
            return {
                ...state,
                hasUserProfile: false,
            }

        case Types.DASHBOARD_USERDATA:
            return {
                ...state,
                hasUserProfile: true,
                userProfile: action.payload,
            }
        case Types.DASHBOARD_USERDATA_ERROR:
            return {
                ...state,
                hasUserProfile: false,
            }
        //matchedData
        case Types.DASHBOARD_MATCHDATA_REQUESR:
            return {
                ...state,
                hasMatchedProfile: action.payload
            }

        case Types.DASHBOARD_MATCHDATA:
            return {
                ...state,
                matchedProfile: action.payload,
                hasMatchedProfile: true
            }
        case Types.DASHBOARD_MATCHDATA_ERROR:
            return {
                ...state,
                hasMatchedProfile: action.payload
            }
        // MatchedDataAll
        case Types.DASHBOARD_MATCH_ALL_DATA_REQUESR:
            return {
                ...state,
                hasMatchedProfileAll: action.payload
            }

        case Types.DASHBOARD_MATCH_ALL_DATA:
            return {
                ...state,
                matchedProfileAll: action.payload,
                hasMatchedProfileAll: true
            }

        case Types.DASHBOARD_MATCH_ALL_DATA_ERROR:
            return {
                ...state,
                hasMatchedProfileAll: action.payload
            }
        //INTERESTED_DATA

        case Types.DASHBOARD_INTERESTEDDATA_REQUESR:
            return {
                ...state,
                hasMyInterstProfile: false
            }
        case Types.DASHBOARD_INTERESTEDDATA:
            return {
                ...state,
                myInterstProfile: action.payload,
                hasMyInterstProfile: true
            }
        case Types.DASHBOARD_INTERESTEDDATA_ERROR:
            return {
                ...state,
                hasMyInterstProfile: false
            }

        case Types.DASHBOARD_INTERESTEDDATA_ALL_REQUESR:
            return {
                ...state,
                hasMyInterstProfileAll: false
            }
        case Types.DASHBOARD_INTERESTEDDATA_ALL:
            return {
                ...state,
                myInterstProfileAll: action.payload,
                hasMyInterstProfileAll: true
            }
        case Types.DASHBOARD_INTERESTEDDATA_ALL_ERROR:
            return {
                ...state,
                hasMyInterstProfileAll: false
            }

        //REQUESTED_DATA
        case Types.DASHBOARD_REQUESTEDDATA_REQUESR:
            return {
                ...state,
                hasComingRequestProfile: action.payload
            }

        case Types.DASHBOARD_REQUESTEDDATA:
            return {
                ...state,
                comingRequestProfile: action.payload,
                hasComingRequestProfile: true
            }

        case Types.DASHBOARD_REQUESTEDDATA_ERROR:
            return {
                ...state,
                hasComingRequestProfile: action.payload
            }


        //RESENT_ADDED
        case Types.DASHBOARD_RESENTADDED_REQUESR:
            return {
                ...state,
                hasResentAddedProfile: false
            }
        case Types.DASHBOARD_RESENTADDED:
            return {
                ...state,
                resentAddedProfile: action.payload,
                hasResentAddedProfile: true
            }
        case Types.DASHBOARD_RESENTADDED_ERROR:
            return {
                ...state,
                hasResentAddedProfile: false
            }


        //viewed profile
        case Types.DASHBOARD_VIEWED_REQUESR:
            return {
                ...state,
                hasViewedProfile: false
            }

        case Types.DASHBOARD_VIEWED:
            return {
                ...state,
                viewedProfile: action.payload,
                hasViewedProfile: true
            }

        case Types.DASHBOARD_VIEWED_ERROR:
            return {
                ...state,
                hasViewedProfile: false
            }

        default:
            return state;
    }
}
