import { Types } from "../types";

let initialState = {
    loading: false,
    isLoggedIn: false,
    userData: []
}

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case Types.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                isLoggedIn: true,
                userData: action.payload,
            }
        case Types.LOGIN_ERROR:
            return {
                ...state,
                loading: false,
                isLoggedIn: false,
            }
        case Types.USER_LOGOUT:
            return {
                ...state,
                loading: false,
                isLoggedIn: false,
                userData: []
            }

        default:
            return state;
    }
}