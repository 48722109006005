import axios from "axios";
import { Types } from "../types"
import { payMyUser } from "../API";
import { GetUserProfile } from "../viewProfile/viewProfileService";

export const UserPayment = (amount, count, paymentID, userID) => {
    const data = {
        noacc: count ?? 50,
        amount: amount,
        payment_no: paymentID
    }

    return (dispatch) => {
        // dispatch({ type: Types.USER_RESET_PASSWORD_REQUEST });
        return axios
            .post(payMyUser, data)
            .then((resp) => {
                dispatch(GetUserProfile(userID))
                dispatch({
                    type: Types.ALERT_SUCCESS,
                    payload: { message: resp.data?.message },
                });
                // dispatch({ type: Types.USER_RESET_PASSWORD_SUCCESS });
                return resp
            })
            .catch((error) => {
                dispatch({
                    type: Types.ALERT_ERROR,
                    payload: { message: error.response?.data?.message ?? "Try Again Later Some Issue" },
                });
                // dispatch({ type: Types.USER_RESET_PASSWORD_ERROR });
                return error.response
            });
    };
};