import React from "react";
import { variables } from "../../form/var";
import "./commonPage1.css";
export default function PrivacyPolicy() {
  return (
    <div className="privacy-p">
      <h3>Privacy Policy</h3>
      <p className="privacy-p-paragraph">{variables.privacy_policy}</p>
      <h5>Information Collection and Use</h5>
      <p className="privacy-p-paragraph">{variables.Information_collection}</p>
      <div>Google Play Services</div>
      <br />
      <h5>Log Data</h5>
      <p>{variables.Log_Data}</p>
      <h5>Cookies</h5>
      <p>{variables.cookies}</p>
      <h5>Service Providers</h5>
      <div>
        I may employ third-party companies and individuals due to the following
        reasons:
      </div>
      <p className="privacy-p-paragraph">{variables.Service_Providers1}</p>
      <div>{variables.Service_Providers2}</div>
      <h5>Security</h5>
      <p className="privacy-p-paragraph">{variables.Security}</p>
      <h5>Links to Other Sites</h5>
      <p className="privacy-p-paragraph">{variables.Links_to}</p>
      <h5>Children’s Privacy</h5>
      <p>{variables.Children_Privacy}</p>
      <h5>Changes to This Privacy Policy</h5>
      <p>{variables.Changes_to}</p>
      <h5>Contact Us</h5>
      <p>{variables.Contact_Us}</p>
    </div>
  );
}
