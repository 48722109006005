import { Types } from "../types";

export const passwordReducer = (state = {
    hasPasswordRequest: false,
    hasPasswordChanged: false
}, action) => {

    switch (action.type) {
        case Types.PASSWORD_CHANGE_REQUEST:
            return {
                hasPasswordRequest: true
            }
        case Types.PASSWORD_CHANGE_SUCCESS:
            return {
                hasPasswordChanged: true,
                hasPasswordRequest: false
            }
        case Types.PASSWORD_CHANGE_ERROR:
            return {
                hasPasswordRequest: false
            }
        default:
            return state;
    }

}